import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Link } from 'react-router-dom';
import moment from 'moment';

import {
  Button,
  Toolbar,
} from 'react-md';

import {
  MergedModelCollection,
  getCollectionByName,
  BaseModel,
  getModelIcon,
} from '../models';

import MiniCollectionView from '../components/MiniCollectionView';
import ObjectTypesLegend from '../components/ObjectTypesLegend';
import ObjectPickerStandard from '../components/ObjectPickerStandard';



import {BasePage} from './BasePage';

type ComponentProp = any;
type ComponentState = any;

let searchCollection = new MergedModelCollection([
  
  getCollectionByName('contact'),
  
  getCollectionByName('client'),
  
  getCollectionByName('task'),
  
  getCollectionByName('note'),
  
  getCollectionByName('file'),
  
  getCollectionByName('reminder'),
  
  getCollectionByName('group'),
  
  getCollectionByName('user'),
  
]);

@observer
class Dashboard extends BasePage<ComponentProp, ComponentState> {

  constructor(props: ComponentProp, context?: any) {
    super(props, context);
    this.state.isActionMenuOpen = false;
  }

  viewName = 'Dashboard';

  onActionButtonClicked = () => {
    this.setState({isActionMenuOpen: !this.state.isActionMenuOpen});
  }

  onSearchResultClicked = (id: number, model: BaseModel) => {
    console.log('clicked', model, model.viewURL)
    this.redirectTo(model.viewURL);
  }

  renderAddButton() {
    if (this.state.isActionMenuOpen) {
      return (
          <div className="floating-buttons-container">
            
            <Button raised primary component={Link} to={getCollectionByName('contact').getEditURL(0)} iconChildren={getModelIcon('contacts')}>New Contact</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('client').getEditURL(0)} iconChildren={getModelIcon('clients')}>New Client</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('task').getEditURL(0)} iconChildren={getModelIcon('tasks')}>New Task</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('note').getEditURL(0)} iconChildren={getModelIcon('notes')}>New Note</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('file').getEditURL(0)} iconChildren={getModelIcon('files')}>New File</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('reminder').getEditURL(0)} iconChildren={getModelIcon('reminders')}>New Reminder</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('group').getEditURL(0)} iconChildren={getModelIcon('groups')}>New Group</Button>
            
            <Button raised primary component={Link} to={getCollectionByName('user').getEditURL(0)} iconChildren={getModelIcon('users')}>New User</Button>
            
            <Button
                floating
                secondary
                onClick={this.onActionButtonClicked}
            >close</Button>
          </div>
      );
    }
    else {
      return (
        <div className="floating-buttons-container">
          <Button
              floating
              primary
              onClick={this.onActionButtonClicked}
          >add</Button>
        </div>
      );
    }
  }

  render() {
    let redirect = this.renderRedirect();
    return (
      <div className={`${this.className} md-grid`}>
        <Toolbar
          inset
          nav={<Button icon>search</Button>}
          title={
            <ObjectPickerStandard
              id={"search-autocomplete"}
              key={"search-autocomplete"}
              className="base-collection-search"
              placeholder="Search"
              collection={searchCollection}
              onAutocomplete={this.onSearchResultClicked}
              block={true}
            />
          }
          className="md-cell md-cell--12 md-background--card collections-search"
          zDepth={1}
        />

        
        
        <MiniCollectionView
          title="Contacts"
          collection={getCollectionByName('contact')}
          filter={{
            mine: 1
          }}
          emptyMessage="No Contacts."
          limitHeight={true}
        />
        
        
        
        <MiniCollectionView
          title="Clients"
          collection={getCollectionByName('client')}
          filter={{
            mine: 1
          }}
          emptyMessage="No Clients."
          limitHeight={true}
        />
        
        
        
        <MiniCollectionView
          title="Tasks"
          collection={getCollectionByName('task')}
          filter={{
            mine: 1
          }}
          emptyMessage="No Tasks."
          limitHeight={true}
        />
        
        
        
        <MiniCollectionView
          title="Notes"
          collection={getCollectionByName('note')}
          filter={{
            mine: 1
          }}
          emptyMessage="No Notes."
          limitHeight={true}
        />
        
        
        
        
        
        
        
        
        
        
        
        

        <MiniCollectionView
          title="Unread Notifications"
          collection={getCollectionByName('notifications')}
          filter={{
            read: 0
          }}
          limitHeight={true}
          hideStar={true}
        />
        {this.renderAddButton()}
        {redirect}
      </div>
    );
  }
}

export default Dashboard;