import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react";

import {
  Badge,
  Button,
  FontIcon
} from 'react-md';

import { getAppState } from '../models';

let appState = getAppState();

interface NotificationToolbarIconProps {
  
}

@observer
class NotificationToolbarIcon extends Component<NotificationToolbarIconProps> {
  render() {
    let icon = (
      <FontIcon>notifications</FontIcon>
    );

    
    return (
      <Badge
        id="toolbar-notification-badge-icon"
        className="toolbar-notification-badge-icon"
        secondary
        invisibleOnZero
        badgeId="toolbar-notification-badge-icon"
        badgeContent={appState.unreadNotifications}
      >
        {icon}
      </Badge>
    );
  }
}

export default NotificationToolbarIcon;