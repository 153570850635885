
import { observer } from "mobx-react";

import {
  User,
  Users,
  AppState,
  getUsers,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface UserEditProps {
  history?: any;
  match?: any;
  location?: any;
  user?: User;
}

type ComponentState = any;


@observer
class UserEdit extends BaseEditPage<UserEditProps, ComponentState> {
  viewName = 'User Edit';
  objectName = 'user';

  
  get readOnly() {
    
    
    return true;
  }
  

  get objectLabel() {
    return "User";
  }

  newObject() {
    const obj = new User();

  

    return obj;
  }

  getCollection(): Users {
    return getUsers();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default UserEdit;