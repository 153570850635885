import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import {
  Snackbar,
} from 'react-md';

import { getLastUrl } from '../utils/NavigationStack';
import { getAppState } from '../models';

type ComponentState = any;


class BasePage<P, T> extends Component<P, ComponentState> {
  state: ComponentState = {
    redirectTo: '',
    toasts: [],
  }

  viewName = 'base-page';
  get pageTitle() {
    return this.viewName;
  }

  get toolbarButton(): any {
    return null;
  }

  componentWillMount() {
    let appState = getAppState();
    appState.pageTitle = this.pageTitle;
    appState.toolbarButton = this.toolbarButton;
  }

  addToast = (text: string) => {
    this.setState((state: ComponentState) => {
      const toasts = state.toasts.slice();
      toasts.push({text});
      return { toasts };
    });
  };

  dismissToast = () => {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
    console.log('dismiss toast', this.state.toasts)
  };

  renderToasts() {
    return (<Snackbar
              id="object-snackbar"
              toasts={this.state.toasts}
              autohide={true}
              onDismiss={this.dismissToast}
            />);
  }

  getBackUrl() {
    return getLastUrl();
  }

  redirectTo(redirectTo: string) {
    this.setState({redirectTo});
    setTimeout(() => this.setState({redirectTo: ''}), 0);
  }

  renderRedirect() {
    if (this.state.redirectTo !== '') {
      return <Redirect to={this.state.redirectTo} />;
    }
  }

  get className() {
    return 'AppPage';
  }

  renderHelp(): any {

  }

  render() {
    return (
      <div className={this.className}>
        
      </div>
    );
  }
}

export {
  BasePage,
  // iBasePageProps,
  // iBasePageState,
}