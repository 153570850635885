
import { observer } from "mobx-react";

import {
  Contact,
  Contacts,
  AppState,
  getContacts,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface ContactDetailProps {
  history?: any;
  match?: any;
  location?: any;
  contact?: Contact;
}

type ComponentState = any;



@observer
class ContactDetail extends BaseDetailPage<ContactDetailProps, ComponentState> {

  viewName = 'Contact Detail';
  objectName = 'contact';

  

  


  

  
  get defaultViewMode() {
    return 'edit';
  }
  

  newObject() {
    return new Contact();
  }

  getCollection(): Contacts {
    return getContacts();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
        {
          name: 'Tasks - Contact',
          related_field: 'attached_to_contact',
          for_model: 'Task',
          
          collection: getCollectionByName('task')
        },
      
      
        {
          name: 'Notes - Contacts',
          related_field: 'contacts',
          for_model: 'Note',
          
          collection: getCollectionByName('note')
        },
      
      
        {
          name: 'Files - Contact',
          related_field: 'attached_to_contact',
          for_model: 'File',
          
          collection: getCollectionByName('file')
        },
      
      
        {
          name: 'Reminders - Contact',
          related_field: 'attached_to_contact',
          for_model: 'Reminder',
          
          collection: getCollectionByName('reminder')
        },
      
      
      
    ];
  }

}

export default ContactDetail;