
let lastAccess = new Date().getTime();
let temporaryDisabled = false;
let navigationStack: string[] = [];

function getNavigationStack() {
    let now = new Date().getTime();
    if (temporaryDisabled && ((now -lastAccess) > 500)) {
        temporaryDisabled = false;
    }

    lastAccess = now;

    if (temporaryDisabled) {
        return [];
    }
    return navigationStack;
}

function getLastUrl() {
    lastAccess = new Date().getTime();
    temporaryDisabled = true;
    return navigationStack.pop();
}

export {
    getNavigationStack,
    getLastUrl,
};