
import { observer } from "mobx-react";

import {
  Note,
  Notes,
  AppState,
  getNotes,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface NoteDetailProps {
  history?: any;
  match?: any;
  location?: any;
  note?: Note;
}

type ComponentState = any;



@observer
class NoteDetail extends BaseDetailPage<NoteDetailProps, ComponentState> {

  viewName = 'Note Detail';
  objectName = 'note';

  

  


  

  
  get defaultViewMode() {
    return 'edit';
  }
  

  newObject() {
    return new Note();
  }

  getCollection(): Notes {
    return getNotes();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
        {
          name: 'Tasks - Note',
          related_field: 'attached_to_note',
          for_model: 'Task',
          
          collection: getCollectionByName('task')
        },
      
      
      
        {
          name: 'Files - Note',
          related_field: 'attached_to_note',
          for_model: 'File',
          
          collection: getCollectionByName('file')
        },
      
      
        {
          name: 'Reminders - Note',
          related_field: 'attached_to_note',
          for_model: 'Reminder',
          
          collection: getCollectionByName('reminder')
        },
      
      
      
    ];
  }

}

export default NoteDetail;