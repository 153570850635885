
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Contacts extends BaseApi {
  objectName = 'contacts'

  

  
}


class MockContacts extends BaseMockApi {
    objectName = 'contacts'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Contacts,
  MockContacts,
};
