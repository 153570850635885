import React from 'react';
import { observer } from "mobx-react";

import {
  BaseModelCollection,
  AppState,
  Contact,
  getContacts,
  getAppState,
} from '../models';

import {
  BaseCollectionPage,
} from './BaseCollectionPage';
import { TextField, List, Subheader, ListItem, Button } from "react-md";
import { getApi } from "../api";
import HelpView from "../components/HelpView";


interface ContactsProps {
  history?: any;
  match?: any;
  location?: any;
}

type ComponentState = any;



@observer
class Contacts extends BaseCollectionPage<ContactsProps, ComponentState> {
  viewName = 'Contacts';
  objectName = 'contact';
  store = 'my_models';

  get objectLabel() {
    return "Contact";
  }

  

  
  

  
  get defaultViewMode() {
    return 'table';
  }
  get viewModeOptions(): string[] {
    return [
      'table',
    ];
  }
  

  getCollection(): BaseModelCollection {
    return getContacts();
  }

  getAppState(): AppState {
    return getAppState();
  }

  get customFilters(): any[] {
    let filters: any = []
    
      filters = ["created_by","attached_to_client"]
    

    let customFilters: any[] = [];

    let obj = new Contact();
    filters.forEach((key: string) => {
      let field = obj.getFieldInfo(key);
      if (!field) return;

      let filter: any = {
        key: key,
        label: field.label,
        type: field.type,
      };

      if ((field.type == 'model') || (field.type == 'models')) {
        filter['collectionName'] = field.objectName;
      }

      if (field.type == 'select') {
        filter['options'] = field.options;
      }

      customFilters.push(filter);
    })

    return customFilters;
  }

  

  

  

  
  


}

export default Contacts;