
import { observer } from "mobx-react";

import {
  Contact,
  Contacts,
  AppState,
  getContacts,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface ContactEditProps {
  history?: any;
  match?: any;
  location?: any;
  contact?: Contact;
}

type ComponentState = any;


@observer
class ContactEdit extends BaseEditPage<ContactEditProps, ComponentState> {
  viewName = 'Contact Edit';
  objectName = 'contact';

  

  get objectLabel() {
    return "Contact";
  }

  newObject() {
    const obj = new Contact();

  

    return obj;
  }

  getCollection(): Contacts {
    return getContacts();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default ContactEdit;