import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Note extends BaseModel {
  object_name: string = 'notes';
  object_type: string = 'note';
  object_meta: any = {};
  @observable title: string = '';
  @observable description: string = '';
  @observable contacts: number[] = [];
  @observable clients: number[] = [];
  @observable users: number[] = [];
  @observable attached_to_task?: number;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    title: {"type":"text","required":true},
    description: {"type":"text","multiline":true},
    contacts: {"label":"Contacts","type":"models","objectName":"contacts","objectFilterTool":true,"inlineCreate":true},
    clients: {"label":"Clients","type":"models","objectName":"clients","objectFilterTool":true,"inlineCreate":true},
    users: {"label":"Users","type":"models","objectName":"users","objectFilterTool":true,"inlineCreate":true},
    attached_to_task: {"type":"model","objectName":"tasks","label":"Task","inlineCreate":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return this.title;
    
  }

  getSubtitle() {
    
    let created = this.created_at;
let formatted = moment(created).format('MM/DD/YYYY');
let formattedFromNow = moment(created).fromNow();
let dateFormatted = `${formatted} (${formattedFromNow})`;
return `${dateFormatted}
${this.description}`;
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).title) {
      
      
      keywords += (this as any).title;
    }
    
    
    return keywords;
  }
}


class Notes extends BaseModelCollection {
  object_name: string = 'notes';
  object_type: string = 'note';
  baseClass: any = Note;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Note>([])

  
  

  async get(id: number): Promise<Note> {
    let model: any = this.baseGet(id);
    return (model as Note);
  }

  
  

}


let notes: Notes;
let getNotes = () => {
    if (!notes) {
        notes = new Notes();
    }

    return notes;
}

export {
  Note,
  Notes,
  getNotes,
}