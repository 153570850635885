import { observable } from "mobx";
import moment from 'moment';

import { getApi, Notifications as NotificationsApi } from '../api';
import { BaseModel, BaseModelCollection } from './Models';


class Notification extends BaseModel {
  object_name: string = 'notifications';
  object_type: string = 'notification';
  @observable subject: string = '';
  @observable action: string = '';
  @observable message: string = '';
  @observable send_email: boolean = false;
  @observable read: boolean = false;
  @observable user?: number;
  @observable content_type: number = 0;
  @observable target_object_name: string = '';
  @observable target_object_type: string = '';
  @observable target_id: number = 0;
  @observable target_url: string = '';
  created_by?: number;
  created_at: string = '';
  modified_by?: number;
  updated_at: string = '';

  getTitle() {
    return this.subject;
  }

  getSubtitle() {
    if (this.created_at) {
      let formatted = moment(this.created_at).format('MM/DD/YYYY hh:mm A');
      let formattedFromNow = moment(this.created_at).fromNow();
      return `${this.action} - ${formatted} (${formattedFromNow})`;
    }

    return this.created_at;
  }

  get viewURL() {
    if (this.target_url) return this.target_url;
    if (this.target_id) {
      return `/${this.target_object_type}/${this.target_id}/`;
    }
    return `/${this.target_object_type}/`;
  }

}


class Notifications extends BaseModelCollection {
  object_name: string = 'notifications';
  baseClass: any = Notification;
  sorted: boolean = true;
  sort_by: string = 'created_at';
  sort_dir: string = 'DESC';
  // @observable models: IObservableArray<Notification> = ([] as IObservableArray<Notification>);
  readonly models = observable<Notification>([])

  async get(id: number): Promise<Notification> {
    let model: any = this.baseGet(id);
    return (model as Notification);
  }

  async markAllRead() {
    let objectApi = (this.getApi() as NotificationsApi);
    await objectApi.markAllRead();
  }

}


let notifications: Notifications;
let getNotifications = () => {
    if (!notifications) {
        notifications = new Notifications();
    }

    return notifications;
}

export {
  Notification,
  Notifications,
  getNotifications,
}