import React from 'react';
import { observer } from "mobx-react";

import {
  BaseModelCollection,
  AppState,
  User,
  getUsers,
  getAppState,
} from '../models';

import {
  BaseCollectionPage,
} from './BaseCollectionPage';
import { TextField, List, Subheader, ListItem, Button } from "react-md";
import { getApi } from "../api";
import HelpView from "../components/HelpView";


interface UsersProps {
  history?: any;
  match?: any;
  location?: any;
}

type ComponentState = any;



@observer
class Users extends BaseCollectionPage<UsersProps, ComponentState> {
  viewName = 'Users';
  objectName = 'user';
  store = 'my_models';

  get objectLabel() {
    return "User";
  }

  
  get readOnly() {
    return true;
  }
  

  
  

  

  getCollection(): BaseModelCollection {
    return getUsers();
  }

  getAppState(): AppState {
    return getAppState();
  }

  get customFilters(): any[] {
    let filters: any = []
    

    let customFilters: any[] = [];

    let obj = new User();
    filters.forEach((key: string) => {
      let field = obj.getFieldInfo(key);
      if (!field) return;

      let filter: any = {
        key: key,
        label: field.label,
        type: field.type,
      };

      if ((field.type == 'model') || (field.type == 'models')) {
        filter['collectionName'] = field.objectName;
      }

      if (field.type == 'select') {
        filter['options'] = field.options;
      }

      customFilters.push(filter);
    })

    return customFilters;
  }

  

  

  

  
  


}

export default Users;