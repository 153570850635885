import React, { Component } from 'react';
import moment from 'moment';

import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';

import {
  BaseModel,
  BaseModelCollection,
  getAppState,
} from '../models';

import ObjectListItem from './ObjectListItem';
import NavItemLink from './NavItemLink';
import ObjectMiniItem from './ObjectMiniItem';

interface iActionProp {
  icon?: string;
  label: string;
  onClick: any;
}

interface ActivityHeatmapProps {
  models?: BaseModel[];
  collection?: BaseModelCollection;
  filter?: any;
  startDate?: any;
  endDate?: any;
}

class ActivityHeatmap extends Component<ActivityHeatmapProps> {
  state = {
    models: ([] as BaseModel[]),
    error: false,
    errorMessage: '',
    loading: false,
  }

  getAppState() {
    return getAppState();
  }

  async loadData() {
    if (!this.props.collection) return;
    this.setState({loading: true});
    let models: any = [];
    try {
      if (this.props.filter) {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, this.props.filter);
      }
      else {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, undefined);
      }

      this.setState({models: models, error: false, errorMessage: '', loading: false});
    }
    catch (e) {
      this.setState({error: true, errorMessage: 'Error fetching data! Please try again.', loading: false});
      console.error(e);
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  getDates() {
    let dCache: any = {}
    let dates: string[] = [];
    let models = this.state.models;
    if (this.props.models)models = this.props.models;

    models.forEach((model) => {
      let d = moment((model as any).created_at);
      let dFormatted = d.format('YYYY-MM-DD');
      let value = dCache[dFormatted];
      if (!value) {
        value = {date: dFormatted, count: 1};
        dCache[dFormatted] = value;
        dates.push(value)
      }
      else {
        value.count += 1;
      }
    })

    return dates;
  }

  render() {
    let endDate = moment().toDate();
    if (this.props.endDate) {
      endDate = moment(this.props.endDate).toDate();
    }

    let startDate = moment(`${endDate.getFullYear()-1}-${endDate.getMonth()+1}-${endDate.getDate()}`).toDate();
    if (this.props.startDate) {
      startDate = moment(this.props.startDate).toDate();
    }

    let values = this.getDates();
    let maxCount = values.reduce((max:number, v:any) => {
      return v.count > max ? v.count : max
    }, 10);

    return (
      <CalendarHeatmap
        startDate={startDate}
        endDate={endDate}
        values={values}
        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          let scale = value.count / maxCount;
          scale = scale*4;

          if (scale > 4) scale = 4;
          if (scale < 1) scale = 1;
          return `color-scale-${Math.trunc(scale)}`;
        }}
      />
    );
  }
}

export default ActivityHeatmap;