import { observable } from "mobx";

import { apiEndpoint } from '../api';

import {User} from './Users';
import { BaseModel } from ".";


interface iModal {
  title?: string,
  content: any,
  actions?: any[],
  width?: number,
}

interface iButton {
  icon: any,
  label: string,
  onClick?: () => void,
}


class AppState {
  @observable pageTitle: string = '';
  @observable loadingCounter: number = 0;
  @observable isLoggedIn: boolean = false;
  @observable wasLoggedIn: boolean = false;
  @observable userProfile?: User;
  @observable showErrorMessage: boolean = false;
  @observable errorMessage?: string;
  @observable currentModal?: iModal;
  @observable modalQueue: iModal[] = [];
  @observable toolbarButton?: iButton;
  @observable apiRev: number = 0;
  @observable appUpdated: boolean = false;
  @observable ignoreAppUpdated: boolean = false;
  @observable unreadNotifications: number = 0;
  cachedViewModels: any = {}
  cachedViewStates: any = {}

  isLoading() {
    return this.loadingCounter > 0;
  }

  showLoading() {
    this.loadingCounter += 1;
  }

  hideLoading() {
    this.loadingCounter -= 1;
    if (this.loadingCounter < 0) this.loadingCounter = 0;
  }

  queueModal(modal: iModal) {
    this.modalQueue.push(modal);
  }

  showModal(modal: iModal) {
    this.currentModal = modal;
  }
  
  popQueuedModal(): iModal | undefined {
    this.currentModal = this.modalQueue.pop();
    return this.currentModal;
  }

  dismissQueuedModal = () => {
    this.currentModal = undefined;
  }

  async checkLogin() {
    if (!appState.isLoggedIn) return;

    let loggedIn = false;
    let apiRev = 0;
    let unreadNotifications = 0;
    try {
      let data = await this.fetchLoginStatus();
      loggedIn = data.loggedin;
      apiRev = data.rev;
      unreadNotifications = data.unread_notifications;
    }
    catch (e) {
      console.error(e)
      return;
    }

    if (appState.unreadNotifications !== unreadNotifications) {
      appState.unreadNotifications = unreadNotifications;
    }

    if (appState.isLoggedIn && (!loggedIn)) {
      appState.isLoggedIn = false;
      appState.wasLoggedIn = true;
    }

    if (appState.apiRev == 0) {
      appState.apiRev = apiRev;
    }

    if (apiRev && (appState.apiRev !== apiRev)) {
      appState.appUpdated = true;
    }
  }

  async fetchLoginStatus() {
    let url = `${apiEndpoint}login-status/`
    let response = await fetch(url, {
      credentials: 'include',
    });

    let raw = await response.json();
    // console.log('fetchLoginStatus', raw);
    return raw;
  }

  isMobileApp() {
    if (window.navigator.userAgent.indexOf('CustomCRM Mobile App') !== -1) {
      return true;
    }
    return false;
  }

  setCachedViewStates(key: string, value: any) {
    let clonedValue = value;
    if (clonedValue.redirectTo) {
      clonedValue.redirectTo = '';
    }

    this.cachedViewStates[key] = clonedValue;
    // console.log(key, clonedValue);
  }

  getCachedViewStates(key: string) {
    return this.cachedViewStates[key];
  }
}

let appState: AppState;

let getAppState = () => {
    if (!appState) {
        appState = new AppState();
    }

    return appState;
}

export {
    AppState,
    getAppState
}