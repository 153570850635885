import React, { Component } from 'react';
import { observable, computed, action, autorun, toJS } from "mobx";
import { Link, Route } from 'react-router-dom';

import {
  Button,
  List,
  ListItem,
  Subheader,
  CircularProgress,
  FontIcon,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getAppState,
} from '../models';

import ObjectListItem from './ObjectListItem';
import NavItemLink from './NavItemLink';

interface iActionProp {
  icon?: string;
  label: string;
  onClick: any;
}

interface MiniCollectionViewProps {
  collection: BaseModelCollection;
  filter?: any;
  title: string;
  cellSize?: number;
  limit?: number;
  hideSeeMore?: boolean;
  hideIfEmpty?: boolean;
  emptyMessage?: string;
  flat?: boolean;
  flatBorder?: boolean;
  backUrl?: string;
  limitHeight?: boolean;
  hideStar?: boolean;
  actions?: iActionProp[];
  onItemOpened?: any;
}

class MiniCollectionView extends Component<MiniCollectionViewProps> {
  state = {
    models: [],
    error: false,
    errorMessage: '',
    loading: false,
  }

  getAppState() {
    return getAppState();
  }

  async loadData() {
    this.setState({loading: true});
    let models: any = [];
    try {
      if (this.props.filter) {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, this.props.filter);
      }
      else {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, undefined);
      }

      if (this.props.limit) {
        models = models.slice(0, this.props.limit);
      }

      this.setState({models: models, error: false, errorMessage: '', loading: false});
    }
    catch (e) {
      this.setState({error: true, errorMessage: 'Error fetching data! Please try again.', loading: false});
      console.error(e);
    }
  }

  async componentDidMount() {
    await this.loadData();
  }

  render() {
    let appState = this.getAppState();
    
    if (this.props.hideIfEmpty && (this.state.models.length === 0)) return <div />;

    let emptyMessage = 'Empty';
    if (this.props.emptyMessage) {
      emptyMessage = this.props.emptyMessage;
    }

    let className = 'MiniCollectionView md-cell md-paper';
    if (!this.props.flat) {
      className = `${className} md-paper--1`;
    }

    if (this.props.flatBorder) {
      className = `${className} flatBorder`;
    }

    if (this.props.cellSize) {
      className = `${className} md-cell--${this.props.cellSize}`;
    }

    let actions: iActionProp[];
    if (this.props.actions) actions = this.props.actions;
    else actions = [];

    return (
      <List className={className}>
        <Subheader primaryText={this.props.title} primary />
        {actions.map((action: iActionProp, i) =>
          <ListItem
            key={`${this.props.collection.object_name}-action-${i}`}
            primaryText={action.label} onClick={action.onClick}
            leftIcon={<FontIcon>done_all</FontIcon>}
          />)}
        <div className={this.props.limitHeight ? "listItemContainer LimitHeightDesktop" : "listItemContainer"}>
        {this.state.models.map((obj: BaseModel) =>
          <ObjectListItem
            key={`${this.props.collection.object_name}-${obj.object_type}-${obj.id}`}
            title={obj.getTitle()}
            subTitle={obj.getSubtitle()}
            to={obj.viewURL}
            type={obj.object_type}
            starred={this.props.hideStar ? false : obj.starred}
            mine={this.props.hideStar ? false : obj.mine}
            object={obj}
            onOpened={this.props.onItemOpened}
          />
        )}

        {!this.state.loading && this.state.models.length === 0 &&
          <ListItem primaryText={emptyMessage } />
        }

        {this.state.loading && 
          <div className="progress-container"><CircularProgress id={`collection-loading-${this.props.collection.object_name}`} /></div>
        }

        {this.state.error &&
          <ListItem primaryText={this.state.errorMessage } />
        }
        </div>
        {!this.props.hideSeeMore && <NavItemLink label="See more..." to={this.props.collection.getViewURL()} icon="arrow_forward" /> }
      </List>
    );
  }
}

export default MiniCollectionView;