import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import moment from 'moment';

import {
  Card,
  CardTitle,
  CardText,
  FontIcon,
  List,
  ListItem
} from 'react-md';

import {BasePage} from './BasePage';

import packageJson from '../../package.json';

type ComponentProp = any;
type ComponentState = any;

class About extends BasePage<ComponentProp, ComponentState> {
  viewName = 'About';

  onAboutButton = () => {
    window.open('https://groverwebdesign.com/', '_blank')
  }

  onReportIssueButton = () => {
    let Raven = (window as any).Raven;
    if (!Raven) {
      window.open('https://groverwebdesign.com/contact/', '_blank');
      return;
    }

    let reported = false;
    try {
      Raven.showReportDialog();
      reported = true;
    } catch (error) {
      console.log(error);
    }

    if (!reported) {
      let now = moment().format('YYYY-MM-DD h:mm:ss a');
      let err = new Error(`New Error Report - ${now}`);
      Raven.captureException(err);
      Raven.showReportDialog();
    }
  }

  onLogoutButton = () => {
      
  }

  render() {
    return (
      <div className={this.className}>
        <Card className="md-cell--6 md-block-centered">
          <CardTitle title={packageJson.appName} subtitle={`v${packageJson.version}`} />
          <List ordered>
            <ListItem primaryText={`Developed by Grover Web Design`} rightIcon={<FontIcon>open_in_new</FontIcon>} onClick={this.onAboutButton} />
            <ListItem primaryText="Report Issue" rightIcon={<FontIcon>open_in_new</FontIcon>} onClick={this.onReportIssueButton} />
          </List>
        </Card>
      </div>
    );
  }
}

export default About;