import React, { Component } from 'react';
import moment from 'moment';

import {
  DataTable,
  Paper,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Button,
  LinearProgress,
  FontIcon,
  Avatar,
  Chip,
  TablePagination,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getAppState,
} from '../models';

import ObjectListItem from './ObjectListItem';
import NavItemLink from './NavItemLink';
import ObjectMiniItem from './ObjectMiniItem';

interface iActionProp {
  icon?: string;
  label: string;
  onClick: any;
}

interface iFieldProp {
  label: string;
  key?: string;
  render: any;
  onClick?: any;
}

interface TableCollectionViewProps {
  id?: string;
  collection?: BaseModelCollection;
  filter?: any;
  models?: BaseModel[];
  title: string;
  limit?: number;
  emptyMessage?: string;
  actions?: iActionProp[];
  fields: iFieldProp[];
  sort_by?: string;
  sort_dir?: 'ASC'|'DESC';
  onSort?: (sort_by: string, sort_dir: 'ASC'|'DESC') => void;
  style?: React.CSSProperties | undefined;
  sticky?: boolean;
}

class TableCollectionView extends Component<TableCollectionViewProps> {
  state = {
    page: 1,
    start: 0,
    rowsPerPage: 20,
    models: ([] as BaseModel[]),
    error: false,
    errorMessage: '',
    loading: false,
  }

  getAppState() {
    return getAppState();
  }

  async loadData() {
    if (!this.props.collection) return;
  
    this.setState({loading: true});
    let models: any = [];
    try {
      if (this.props.filter) {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, this.props.filter);
      }
      else {
        models = await this.props.collection.query({
          sort: {
            sortFn: this.props.collection.sortFn
          }
        }, undefined);
      }

      if (this.props.limit) {
        models = models.slice(0, this.props.limit);
      }

      this.setState({models: models, error: false, errorMessage: '', loading: false});
    }
    catch (e) {
      this.setState({error: true, errorMessage: 'Error fetching data! Please try again.', loading: false});
      console.error(e);
    }
  }

  reset() {
    this.setState({page: 1, start: 0});
  }

  restored = false;
  async componentDidMount() {
    this.restored = this.restoreState();
    await this.loadData();
  }

  restoreState() {
    if (this.props.id) {
      let appState = getAppState();
      let state = appState.getCachedViewStates(`TableCollectionView-${this.props.id}`);
      if (state) {
        this.setState(state);
        return true;
      }
    }
    return false;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    let appState = getAppState();

    if (this.props.id) {
      appState.setCachedViewStates(`TableCollectionView-${this.props.id}`, this.state);
    }
    if (this.props.models && (this.state.start > this.props.models.length)) {
      this.reset();
    }
  }

  handlePagination = (start: number, rowsPerPage: number, page: number) => {
    let models = this.state.models;
    if (!this.props.collection && this.props.models) models = this.props.models;
    this.setState({ start: start, rowsPerPage: rowsPerPage, page: page });
  };

  render() {
    let models = this.state.models;
    if (!this.props.collection && this.props.models) models = this.props.models;

    let loadingEl;
    if (this.state.loading) {
      loadingEl = (
        <LinearProgress id="TableCollectionViewLoading" />
      );
    }

    let slicedModels = models.slice(this.state.start, this.state.start + this.state.rowsPerPage);

    let showPagination = true;
    if (this.state.loading) {
      showPagination = false;
    }
    
    if (!slicedModels.length) {
      showPagination = false;
    }

    return (
      <div style={this.props.style}>
        <DataTable
          plain
          baseId="TableCollection"
          style={this.props.sticky ? {position: 'absolute', top: 0, left: 0, right: 0, bottom: 56} : {}}
        >
          <TableHeader>
            <TableRow style={this.props.sticky ? {position: 'sticky', top: 0, background: 'rgba(255,255,255,.888)', zIndex: 9}: {}}>
              {this.props.fields.map((field: iFieldProp, i) => {
                let arrowIcon = "";
                let isSortActive = !!this.props.sort_by && this.props.sort_by == field.key;
                if (isSortActive) {
                  if (this.props.sort_dir === 'ASC') arrowIcon = 'arrow_upward';
                  if (this.props.sort_dir === 'DESC') arrowIcon = 'arrow_downward';
                }
                return (
                <TableColumn key={`header-${i}`} style={(this.props.sticky && (i === 0)) ? {position: 'sticky', left: 0, background: 'rgba(255,255,255,1)', zIndex: 10}: {}}>
                  <Button
                    flat
                    primary={isSortActive}
                    iconChildren={arrowIcon}
                    onClick={() => {
                      if (!this.props.onSort) return;
                      if (!field.key) return;
                      this.props.onSort(field.key, this.props.sort_dir === 'ASC' ? 'DESC' : 'ASC');
                    }}
                  >{field.label}</Button>
                </TableColumn>
                );
              })}
            </TableRow>
          </TableHeader>
          <TableBody>
            {slicedModels.map((model: BaseModel, i) => {
              return (
                <TableRow key={i}>
                  {this.props.fields.map((field: iFieldProp, j) => <TableColumn key={`col-${i}-${j}`} style={(this.props.sticky && (j === 0)) ? {position: 'sticky', left: 0, background: 'rgba(255,255,255,.888)', zIndex: 8}: {}}>{field.render(model)}</TableColumn>)}
                </TableRow>
              )
            })}
          </TableBody>
          {showPagination &&
          <TablePagination
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            rows={models.length}
            rowsPerPageLabel="Rows"
            onPagination={this.handlePagination}
            style={this.props.sticky ? {position: 'fixed', bottom: 20, left: -60} : {}}
          />}
        </DataTable>
        {loadingEl}
      </div>
    );
  }
}

export default TableCollectionView;