import { AppState, getAppState } from './AppState';
import { AppSettings, getAppSettings } from './AppSettings';
import { modelTypes, getModelIcon, BaseModel, BaseModelCollection, MergedModelCollection, getModelLabelSingular, getModelLabelPlural } from './Models';
import { Activity, Activities, getActivities } from './Activities';
import { Notification, Notifications, getNotifications } from './Notifications';

import { Contact, Contacts, getContacts } from './Contacts';
import { Client, Clients, getClients } from './Clients';
import { Task, Tasks, getTasks } from './Tasks';
import { Note, Notes, getNotes } from './Notes';
import { File, Files, getFiles } from './Files';
import { Reminder, Reminders, getReminders } from './Reminders';
import { Group, Groups, getGroups } from './Groups';
import { User, Users, getUsers } from './Users';



function getCollectionByName(name: string): BaseModelCollection {
  switch (name) {
    case 'activity': return getActivities();
    case 'activities': return getActivities();
    case 'notification': return getNotifications();
    case 'notifications': return getNotifications();

    case 'contact': return getContacts();
    case 'contacts': return getContacts();
    case 'client': return getClients();
    case 'clients': return getClients();
    case 'task': return getTasks();
    case 'tasks': return getTasks();
    case 'note': return getNotes();
    case 'notes': return getNotes();
    case 'file': return getFiles();
    case 'files': return getFiles();
    case 'reminder': return getReminders();
    case 'reminders': return getReminders();
    case 'group': return getGroups();
    case 'groups': return getGroups();
    case 'user': return getUsers();
    case 'users': return getUsers();
    
  }

  throw new Error(`collection not found: ${name}`);
}

export {
  modelTypes,
  getModelIcon,
  getModelLabelSingular,
  getModelLabelPlural,
  getCollectionByName,
  AppState,
  getAppState,
  AppSettings,
  getAppSettings,
  Activity,
  Activities,
  getActivities,
  BaseModel,
  BaseModelCollection,
  MergedModelCollection,
  Notification,
  Notifications,
  getNotifications,

  
  Contact,
  Contacts,
  getContacts,
  
  Client,
  Clients,
  getClients,
  
  Task,
  Tasks,
  getTasks,
  
  Note,
  Notes,
  getNotes,
  
  File,
  Files,
  getFiles,
  
  Reminder,
  Reminders,
  getReminders,
  
  Group,
  Groups,
  getGroups,
  
  User,
  Users,
  getUsers,
  
}
