
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Tasks extends BaseApi {
  objectName = 'tasks'

  

  
}


class MockTasks extends BaseMockApi {
    objectName = 'tasks'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Tasks,
  MockTasks,
};
