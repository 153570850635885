
import { observer } from "mobx-react";

import {
  File,
  Files,
  AppState,
  getFiles,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface FileEditProps {
  history?: any;
  match?: any;
  location?: any;
  file?: File;
}

type ComponentState = any;


@observer
class FileEdit extends BaseEditPage<FileEditProps, ComponentState> {
  viewName = 'File Edit';
  objectName = 'file';

  

  get objectLabel() {
    return "File";
  }

  newObject() {
    const obj = new File();

  

    return obj;
  }

  getCollection(): Files {
    return getFiles();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default FileEdit;