import React, { Component } from 'react';
import { toJS } from "mobx";
import { observer, componentByNodeRegistery } from "mobx-react";
import { createViewModel } from 'mobx-utils'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardTitle,
  FontIcon,
  Collapse,
  CircularProgress,
  DialogContainer,
  Toolbar,
  TextField,
  List,
  Subheader,
  ListItem,
  SelectField,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getCollectionByName,
  getAppState,
} from '../models';


import ObjectEditor from '../components/ObjectEditor';
import ObjectListItem from './ObjectListItem';
import ObjectPicker from './ObjectPicker';
import ObjectMiniItem from './ObjectMiniItem';


interface MultiObjectFilterFormProps {
  object: BaseModel;
  collection: BaseModelCollection;
  objectLabel: string;
  extraFields?: any[];
  onSelect: (objects: BaseModel[]) => void;
  addToast?: (message: string) => void;
  className?: string;
  helpText?: string;
}


@observer
class MultiObjectFilterForm extends Component<MultiObjectFilterFormProps> {
  state = {
    search: "",
    filter_extra: {},
    searching: false,
    results: [],
    notFound: false,
    showFilterDialog: false,
    error: false,
    errorMessage: "",
    dialogPageX: 0,
    dialogPageY: 0,
  }

  addToast(message: string) {
    if (this.props.addToast) {
      this.props.addToast(message);
    }
  }

  get objectLabel() {
    return this.props.objectLabel;
  }
  
  getObjectTitle(): string {
    return this.props.object.getTitle();
  }

  getObjectSubtitle(): string {
    return this.props.object.getSubtitle();
  }

  showLoading() {
    
  }

  hideLoading() {
    
  }

  async componentDidMount() {

  }


  componentWillMount() {
    
  }

  async runFilter() {
    if (this.state.searching) return;

    this.showLoading();

    let error = false;
    let errorMessage = "";
    this.addToast('Searching...');
    this.setState({searching: true, error, errorMessage});
    let filter: any = {search: this.state.search};

    if (this.props.extraFields) {
      this.props.extraFields.forEach((field: any) => {
        let value = (this.state.filter_extra as any)[field.key];
        if (value){
          filter[field.key] = value;
        }
      });
    }

    let results = await this.props.collection.query({
      sort: {
        sortFn: this.props.collection.sortFn
      }
    }, filter);

    if (results.length === 0) {
      error = true;
      errorMessage = "No result found.";
    }

    this.setState({searching: false, results, error, errorMessage});
    this.hideLoading();
  }

  onCancelFilterButton = () => {
      this.onHideFilterDialog();
  }

  onFormSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return await this.runFilter();
  }

  onSelectAllResult = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSelect(this.state.results);
    this.onHideFilterDialog();
  }

  onShowEditDialog = (e: any) => {
    let state: any = {showFilterDialog: true};
    if (e && e.changedTouches) {
      state['dialogPageX'] = e.changedTouches[0].pageX;
      state['dialogPageY'] = e.changedTouches[0].pageY;
    }

    this.setState(state);
  }

  onHideFilterDialog = () => {
    this.setState({showFilterDialog: false});
  }

  onSearchChange = (search: any, e: any) => {
    this.setState({search});
  }

  render() {
    let object: BaseModel = this.props.object;
    let className = "ObjectFilterForm";
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    let button = <Button icon className="ShowFilterDialogButton" onClick={this.onShowEditDialog}>search</Button>;

    let extraFields: any[] = [];
    if (this.props.extraFields) {
      this.props.extraFields.forEach((field: any) => {
        let el: any = null;
        switch (field.type) {
          case 'select':
            el = (<SelectField
              id={`object-filter-${field.key}`}
              key={`object-filter-${field.key}`}
              label={field.label}
              value={(this.state.filter_extra as any)[field.key]}
              menuItems={field.options}
              simplifiedMenu={true}
              className="md-full-width md-text-field-container"
              sameWidth
              onChange={(value: any) => {
                (this.state.filter_extra as any)[field.key] = value;
                this.setState({filter_extra: this.state.filter_extra})
              }}
              required={false}
            />);
            break;
          
          case 'object':
            let collection = getCollectionByName(field.objectName);
            let extra = (<></>);

            if ((this.state.filter_extra as any)[field.key]) {
              let objectId = (this.state.filter_extra as any)[field.key];
              extra = (<div style={{paddingLeft: '20px'}}>
                <ObjectMiniItem
                  key={`object-filter-${field.key}-${objectId}`}
                  objectId={objectId}
                  objectName={collection.object_name}
                  removable={true}
                  onClick={() => {
                    (this.state.filter_extra as any)[field.key] = undefined;
                    this.setState({filter_extra: this.state.filter_extra})
                  }}
                />
              </div>);
            }
            el = (<div style={{marginLeft: -20}}>
              <ObjectPicker
                id={`object-filter-${field.key}`}
                key={`object-filter-${field.key}`}
                label={field.label}
                value={(this.state.filter_extra as any)[field.key]}
                placeholder={`type to search ${field.label}...`}
                collection={collection}
                onAutocomplete={(value: any) => {
                  (this.state.filter_extra as any)[field.key] = value;
                  this.setState({filter_extra: this.state.filter_extra})
                }}
              />
              {extra}
            </div>);
            break;
        
          default:
              el = (<TextField
                id={`object-filter-${field.key}`}
                key={`object-filter-${field.key}`}
                label={field.label}
                value={(this.state.filter_extra as any)[field.key]}
                onChange={(value: any) => {
                  (this.state.filter_extra as any)[field.key] = value;
                  this.setState({filter_extra: this.state.filter_extra})
                }}
                required={false}
              />);
            break;
        }
        if (el) {
          extraFields.push(el);
        }
      })
    }

    return (
      <div className="ObjectFilterForm">
        {button}
        <DialogContainer
          id={`ObjectFilterForm-${this.props.object.object_type}-${this.props.object.id}`}
          visible={this.state.showFilterDialog}
          pageX={this.state.dialogPageX}
          pageY={this.state.dialogPageY}
          fullPage
          onHide={this.onHideFilterDialog}
          aria-labelledby={`new ${this.props.object.object_type}`}
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          renderNode={document.body}
        >
          <Toolbar
            fixed
            colored
            title={`Find ${this.objectLabel}`}
            titleId={`find ${this.props.object.object_type}`}
            nav={<Button icon onClick={this.onHideFilterDialog} disabled={this.state.searching}>close</Button>}
            actions={<Button flat onClick={this.onSelectAllResult} disabled={this.state.searching}>Select All Result</Button>}
          />
          <section className="ObjectFilterForm--Content">
            <Collapse collapsed={!this.state.searching}>
              <CircularProgress id={`ObjectFilterForm-loading-${this.props.object.object_type}-${this.props.object.id}`} />
            </Collapse>

            <form
              onSubmit={this.onFormSubmit}
              id={`ObjectFilterForm-form-${this.props.object.object_type}-${this.props.object.id}`}
            >
              <TextField
                id="object-filter-search"
                key="object-filter-search"
                label="Search"
                value={this.state.search}
                onChange={this.onSearchChange}
                required={false}
              />
              {extraFields}
              <div>
                <Button raised primary type="submit" className="md-cell--left" iconChildren="check" disabled={this.state.searching}>Filter</Button>
              </div>
            </form>
            <List className="objects-search-result">
              <Subheader primaryText="Results" primary />
              <div className="listItemContainer">
              {this.state.results.map((obj: BaseModel) =>
                <ObjectListItem
                  key={`${this.props.collection.object_name}-${obj.object_type}-${obj.id}`}
                  title={obj.getTitle()}
                  subTitle={obj.getSubtitle()}
                  to={obj.viewURL}
                  type={obj.object_type}
                  starred={obj.starred}
                  mine={obj.mine}
                  object={obj}
                  onOpened={(obj: BaseModel) => {
                    this.props.onSelect([obj]);
                    this.onHideFilterDialog();
                  }}
                />
              )}

              {this.state.searching && 
                <div className="progress-container"><CircularProgress id={`collection-loading-${this.props.collection.object_name}`} /></div>
              }

              {this.state.error &&
                <ListItem primaryText={this.state.errorMessage } />
              }

              {!this.state.searching && this.state.results.length === 0 &&
                <ListItem primaryText={`Use the form above to filter your ${this.props.objectLabel} list.`} />
              }

              </div>
            </List>
          </section>
        </DialogContainer>
      </div>
    );
  }
}

export default MultiObjectFilterForm;