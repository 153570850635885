import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

import {
  Avatar,
  ListItem,
  FontIcon,
  Button,
} from 'react-md';
import { getModelIcon, BaseModel, Task } from '../models';


interface ObjectListItemProps {
  title?: string,
  subTitle?: string,
  extraTitle?: string,
  to?: string,
  type?: string,
  className?: any,
  rightIcon?: string,
  rightText?: string,
  starred?: boolean,
  mine?: boolean,
  object?: BaseModel,
  children?: any,
  onOpened?: any,
}


class ObjectListItem extends Component<ObjectListItemProps> {
  getAvatar() {
    let type = this.props.type;
    let suffix = 'white';
    let icon = getModelIcon(type);

    switch (type) {
      case 'task':
        if (this.props.object && (this.props.object as any).completed) {
          icon = 'check';
          suffix = 'green';
        }
      break;
    }
    return <Avatar suffix={suffix}><FontIcon>{icon}</FontIcon></Avatar>;
  }

  get extraClass() {
    let extraClass = 'ObjectListItem';
    let type = this.props.type;

    switch (type) {
      case 'task':
        if (this.props.object && (this.props.object as any).isOverdue) {
          extraClass += ' TaskItem--overdue';
        }
      break;
    }
    return extraClass;
  }

  renderStar() {
    let rightIcon: any;

    if (this.props.rightIcon) {
      rightIcon = <FontIcon>{this.props.rightIcon}</FontIcon>;
    }

    if (this.props.rightText) {
      rightIcon = <div className="md-tile-text--secondary md-text--secondary">{this.props.rightText}</div>;
    }

    if (this.props.starred || this.props.mine) {
      rightIcon = <FontIcon style={{color: 'gold'}}>star</FontIcon>;
    }
    return rightIcon;
  }

  renderDownloadFile() {
    let suffix = 'white';
    return (
      <Button
        icon
        primary
        onClick={(e) => {
          e.preventDefault();
          let url = '';
          if (this.props.object) {
            url = (this.props.object as any).file;
          }
          if (url) {
            window.open(url, '_blank');
          }
        }}
        style={{
          marginTop: '-10px'
        }}
      >cloud_download</Button>
    );
  }

  renderRightIcon() {
    let type = this.props.type;
    if (type === 'file') {
      return this.renderDownloadFile();
    }
    else {
      return this.renderStar();
    }
  }

  render() {
    let className = this.props.className ? this.props.className : '';
    className = `${className} ${this.extraClass}`;
    let subTitle = this.props.subTitle;

    let extraProps: any = {};

    if (this.props.extraTitle) {
      subTitle = `${subTitle}\n${this.props.extraTitle}`;
      extraProps['threeLines'] = true;
    }

    if (this.props.children) {
      extraProps['children'] = this.props.children;
    }

    let secondaryEl: any;
    if (this.props.type === 'note') {
      className = `${className} multiline-text-field`;
    }

    extraProps['rightIcon'] = this.renderRightIcon();

    return (
      <ListItem
        component={Link}
        to={this.props.to}
        leftAvatar={this.getAvatar()}
        primaryText={this.props.title}
        secondaryText={subTitle}
        className={className}
        onClick={(e: any) => {
          if (this.props.onOpened) {
            if (!this.props.onOpened(this.props.object)) {
              e.preventDefault();
            }
          }
        }}
        {...extraProps}
      />
    );
  }
}

export default ObjectListItem;