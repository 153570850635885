
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Reminders extends BaseApi {
  objectName = 'reminders'

  

  
}


class MockReminders extends BaseMockApi {
    objectName = 'reminders'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Reminders,
  MockReminders,
};
