import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import moment from 'moment';

import {
  Button,
  Card,
  CardTitle,
  CardActions,
  List,
  TextField,
  LinearProgress,
  Collapse,
} from 'react-md';

import packageJson from '../../package.json';
import { User, getUsers } from '../models';
import { apiEndpoint, loginEndpoint } from '../api';



interface LoginProps {
  onLogin: (user: User) => void;
  skipAutomaticLoginCheck?: boolean;
  loginMessage?: string;
  plain?: boolean;
}


class Login extends Component<LoginProps> {
  state = {
    loading: false,
    username: '',
    password: '',
    error: false,
  }

  async componentDidMount() {
    if (this.props.skipAutomaticLoginCheck) return;

    return await this.checkLogin();
  }

  async checkLogin() {
    this.setState({loading: true});
    let users = getUsers();
    let isLoggedIn = false;
    let user: any;
    try {
      user = await users.getCurrentUser();
      isLoggedIn = true;
    }
    catch (e) {
      console.error(e)
    }
    this.setState({loading: false});
    if (isLoggedIn && user) {
      this.props.onLogin(user);
    }
  }

  onReportIssueButton = () => {
    let Raven = (window as any).Raven;
    if (!Raven) {
      window.open(packageJson.reportUrl, '_blank');
      return;
    }

    let reported = false;
    try {
      Raven.showReportDialog();
      reported = true;
    } catch (error) {
      console.log(error);
    }

    if (!reported) {
      let now = moment().format('YYYY-MM-DD h:mm:ss a');
      let err = new Error(`New Error Report - ${now}`);
      Raven.captureException(err);
      Raven.showReportDialog();
    }
  }

  onFieldChange(key: string) {
    return (value: any, event: any) => {
      let st: any = {};
      st[key] = value;
      this.setState(st);
    }
  }

  async fetchCsrfToken() {
    let url = `${apiEndpoint}csrf/`
    let response = await fetch(url, {
      credentials: 'include',
    });

    let raw = await response.json();
    console.log(raw);

    (window as any).csrfToken = raw.csrfmiddlewaretoken;
    return raw.csrfmiddlewaretoken;
  }

  async performLogin(username: string, password: string, csrfmiddlewaretoken: string) {
    let url = loginEndpoint;

    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('csrfmiddlewaretoken', csrfmiddlewaretoken);

    this.setState({loading: true});
    let response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      body: formData,
    });

    let loggedIn = false;
    try {
      let raw = await response.json();
      if (raw.loggedin) {
        loggedIn = true;
      }
    } catch (error) {
      this.setState({error: true});
    }

    this.setState({loading: false});
    if (loggedIn) {
      await this.checkLogin()
    }
  }

  async login(username: string, password: string) {
    console.log('login', username);
    let csrfmiddlewaretoken = await this.fetchCsrfToken();
    await this.performLogin(username, password, csrfmiddlewaretoken);
  }

  onFormSubmit = async (e: any) => {
    e.preventDefault();
    if (this.state.loading) return;
    if (this.state.username && this.state.password) {
      await this.login(this.state.username, this.state.password);
    }
  }

  render() {
    let message = this.props.loginMessage ? this.props.loginMessage : `Welcome to ${packageJson.appName}`;
    if (this.state.loading) {
      message = "Loading...";
    }

    let titleEl = (
      <img src="https://crm.pagmarketingsc.com/media/user-files/2020/07/15/pagcrm-logo-2.png" alt={packageJson.appName} className="main-logo-img" />
    );

    return (
      <Card className={this.props.plain ? "LoginForm md-cell--12 md-block-centered" : "LoginForm md-cell--4 md-block-centered"}>
        <CardTitle title={titleEl} subtitle={message} />
        <form onSubmit={this.onFormSubmit}>
        <List ordered>
          <TextField
            id={`login-username-field`}
            key={`login-username-field`}
            label={'Username'}
            value={this.state.username}
            onChange={this.onFieldChange('username')}
            type="text"
            required={true}
            error={this.state.error}
          />
          <TextField
            id={`login-password-field`}
            key={`login-password-field`}
            label={'Password'}
            value={this.state.password}
            onChange={this.onFieldChange('password')}
            type="password"
            required={true}
            error={this.state.error}
          />
        </List>
        <CardActions className="md-cell md-cell--12">
          <Button raised primary type="submit" className="md-cell--left" iconChildren="lock_open" disabled={this.state.loading}>Login</Button>
          
        </CardActions>
        </form>
        <Collapse collapsed={!this.state.loading}>
          <LinearProgress id="loginloading" query={false} />
        </Collapse>
      </Card>
    );
  }
}

export default Login;