
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Clients extends BaseApi {
  objectName = 'clients'

  

  
}


class MockClients extends BaseMockApi {
    objectName = 'clients'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Clients,
  MockClients,
};
