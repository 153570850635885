import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class File extends BaseModel {
  object_name: string = 'files';
  object_type: string = 'file';
  object_meta: any = {};
  @observable name: string = '';
  @observable file: string = '';
  @observable attached_to_contact?: number;
  @observable attached_to_client?: number;
  @observable attached_to_task?: number;
  @observable attached_to_note?: number;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    name: {"type":"text"},
    file: {"type":"file","required":true},
    attached_to_contact: {"type":"model","objectName":"contacts","label":"Contact","inlineCreate":true},
    attached_to_client: {"type":"model","objectName":"contacts","label":"Client","inlineCreate":true},
    attached_to_task: {"type":"model","objectName":"tasks","label":"Task","inlineCreate":true},
    attached_to_note: {"type":"model","objectName":"notes","label":"Note","inlineCreate":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return this.name;
    
  }

  getSubtitle() {
    
    if (this.file) {
let name = this.file.split('/').pop();
return name ? name : '';
}
return this.file;
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).name) {
      
      
      keywords += (this as any).name;
    }
    
    
    return keywords;
  }
}


class Files extends BaseModelCollection {
  object_name: string = 'files';
  object_type: string = 'file';
  baseClass: any = File;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<File>([])

  
  

  async get(id: number): Promise<File> {
    let model: any = this.baseGet(id);
    return (model as File);
  }

  
  

}


let files: Files;
let getFiles = () => {
    if (!files) {
        files = new Files();
    }

    return files;
}

export {
  File,
  Files,
  getFiles,
}