import React, { Component } from 'react';
import { toJS } from "mobx";
import { Redirect } from 'react-router-dom';
import formatNumber from 'format-number';
import moment from 'moment';
import { observer } from "mobx-react";

import {
  List,
  ListItem,
  Checkbox
} from 'react-md';

import { BaseModel } from '../models';

import ObjectMiniItem from './ObjectMiniItem';


interface ObjectViewerProps {
  object: BaseModel,
  dataShown?: string, // all, main, meta
}

@observer
class ObjectViewer extends Component<ObjectViewerProps> {
  state = {
    redirectTo: '',
  };

  isHidden(key: string) {
    return this.props.object.isHidden(key);
  }

  getLabel(key: string) {
    return this.props.object.getLabel(key);
  }

  onItemClicked(key: string, value: any) {
    let info = this.props.object.getFieldInfo(key);
    console.log('clicked', key, value, info);

    if (info.type === 'file') {
      window.open(value, '_blank')
    }
  }

  renderRedirect() {
    if (this.state.redirectTo !== '') {
      return <Redirect to={this.state.redirectTo} />;
    }
  }

  renderNumberField(key: string, fieldInfo: any, value: any) {
    let processedValue: any = value;
    let formatOpt: any = {};

    if (fieldInfo.prefix) {
      formatOpt['prefix'] = fieldInfo.prefix;
    }

    if (fieldInfo.format) {
      processedValue = formatNumber(formatOpt)(value);
    }
    else {
      processedValue = formatNumber(formatOpt)(value, {noSeparator: true});
    }

    return processedValue;
  }

  renderTextField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    return <div>{value}</div>;
  }

  renderFileField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let name = value.split('/').pop();
    return <div>{name ? `Click to download ${name}` : '-'}</div>;
  }

  renderSelectField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let label = `${value}`;
    fieldInfo.options.forEach((option: any) => {
      if (option.value === value) {
        label = option.label;
      }
    })
    return <div>{label}</div>;
  }

  renderCheckField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};
    return (<Checkbox
      id={`object-viewer-field-${key}`}
      key={`object-viewer-field-${key}`}
      label={fieldInfo.label}
      name={fieldInfo.label}
      className="object-viewer-checkbox"
      value={value}
      checked={value}
      disabled={true}
      {...extraProps}
    />);
  }

  renderEmailField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    return <a className="md-tile-text--secondary md-text--secondary" href={`mailto:${value}`} target="_blank">{value}</a>;
  }

  renderPhoneField(key: string, fieldInfo: any, value: any) {
    return <a className="md-tile-text--secondary md-text--secondary" href={`tel:${value}`} target="_blank">{value}</a>;
  }

  renderDateTimeField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('MM/DD/YYYY hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderTimeField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderDateField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('MM/DD/YYYY');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderModelField(key: string, fieldInfo: any, value: any) {
    return (
    <ObjectMiniItem
      key={key}
      objectId={value}
      objectName={fieldInfo.objectName}
    />
    );
  }

  renderModelsField(key: string, fieldInfo: any, idList: number[]) {
    let items = idList.map((id, i) => this.renderModelField(`${key}-${i}`, fieldInfo, id));
    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}>
        {items}
      </div>);
  }

  renderItem(key: string, value: any) {
    if (value === undefined) return;
    if (value === null) return;

    let info = this.props.object.getFieldInfo(key);
    let processedValue: any = value;
    let width = info.width ? info.width : 12;
    if (info.widthView) {
      width = info.widthView;
    }

    let className = `md-cell--${width}`;
    if (info.autoHeight) {
      className = `${className} viewer-autoheight`;
    }

    if (info.hideViewer) return;
    if (!value && info.hideIfEmpty) return;

    switch (info.type) {
      case 'number':
        processedValue = this.renderNumberField(key, info, value);
        break;
      case 'text':
        processedValue = this.renderTextField(key, info, value);
        if (info.multiline) {
          className = `${className} multiline-text-field`;
        }
        break;
      case 'select':
        processedValue = this.renderSelectField(key, info, value);
        break;
      case 'check':
        processedValue = this.renderCheckField(key, info, value);
        return (
          <ListItem
            key={`object-field-${key}`}
            primaryText={processedValue}
            onClick={() => this.onItemClicked(key, value)}
            className={className}
           />
        );
        break;
      case 'model':
        processedValue = this.renderModelField(key, info, value);
        break;
      case 'models':
        processedValue = this.renderModelsField(key, info, value);
        break;
      case 'datetime':
        processedValue = this.renderDateTimeField(key, info, value);
        break;
      case 'date':
        processedValue = this.renderDateField(key, info, value);
        break;
      case 'time':
        processedValue = this.renderTimeField(key, info, value);
        break;
      case 'email':
        processedValue = this.renderEmailField(key, info, value);
        break;
      case 'phone':
        processedValue = this.renderPhoneField(key, info, value);
        break;
      case 'file':
        processedValue = this.renderFileField(key, info, value);
        break;
    }

    return (
      <ListItem
        key={`object-field-${key}`}
        primaryText={info.hideLabel ? processedValue : info.label}
        secondaryText={info.hideLabel ? '' : processedValue}
        onClick={() => this.onItemClicked(key, value)}
        className={className}
       />
    );
  }

  render() {
    let redirect = this.renderRedirect();
    if (redirect) return redirect;

    let dataShown = this.props.dataShown ? this.props.dataShown : 'all';
    let showMeta = true;
    let showMain = true;
    if (dataShown === 'meta') {
      showMain = false;
    }
    if (dataShown === 'main') {
      showMeta = false;
    }

    let replica = toJS(this.props.object);
    let items: any[] = [];
    Object.keys(replica).forEach(key => {
      if (this.isHidden(key)) return;
      if (this.props.object.isMeta(key) && !showMeta) return;
      if (!this.props.object.isMeta(key) && !showMain) return;
      items.push({
        key: key,
        value: (this.props.object as any)[`render_${key}`] ? (this.props.object as any)[`render_${key}`]() : (replica as any)[key]
      })
    });
    return (
      <List className="ObjectViewer">
        {items.map((item) => this.renderItem(item.key, item.value))}
      </List>
    );
  }
}

export default ObjectViewer;