import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import * as WebFontLoader from 'webfontloader';

import packageJson from '../package.json';

import App from './modules/App';
import * as serviceWorker from './serviceWorker';

import './index.scss';

let sentryDSN = (process.env.NODE_ENV === 'production') ? packageJson.sentryDSN : packageJson.sentryDSNDebug;
if ((window as any).Raven && sentryDSN) {
  (window as any).Raven.config(sentryDSN).install()
}
else {
  (window as any).Raven = undefined;
}

WebFontLoader.load({
  google: {
    families: ['Roboto:300,400,500,700', 'Material Icons'],
  },
});


// mobx-react won't re-render component on route update unless props is updated
// by wrapping App inside a Route, props will be updated when route changes 
ReactDOM.render(<BrowserRouter><Route component={App} /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
