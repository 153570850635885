
import { observer } from "mobx-react";

import {
  Client,
  Clients,
  AppState,
  getClients,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface ClientEditProps {
  history?: any;
  match?: any;
  location?: any;
  client?: Client;
}

type ComponentState = any;


@observer
class ClientEdit extends BaseEditPage<ClientEditProps, ComponentState> {
  viewName = 'Client Edit';
  objectName = 'client';

  

  get objectLabel() {
    return "Client";
  }

  newObject() {
    const obj = new Client();

  

    return obj;
  }

  getCollection(): Clients {
    return getClients();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default ClientEdit;