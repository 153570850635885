import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

import {
  Card,
  CardTitle,
  CardText,
  FontIcon,
  List,
  ListItem
} from 'react-md';

class About extends Component {
    onReportIssueButton = () => {
        window.open('mailto:donny@groverwebdesign.com?subject=Issue%20Report%20for%20KoppersCRM', '_blank')
    }

    render() {
        return (
           <div className="md-grid">
            <Card className="md-cell--6 md-block-centered">
              <CardTitle title="404 Not Found" subtitle="We can't locate the resource you're trying to access!" />
              <List ordered>
                <ListItem component={Link} to={'/'} primaryText="Go back to Dashboard" rightIcon={<FontIcon>dashboard</FontIcon>} />
                <ListItem primaryText="Report Issue" rightIcon={<FontIcon>open_in_new</FontIcon>} onClick={this.onReportIssueButton} />
              </List>
            </Card>
          </div>
        );
    }
}

export default About;