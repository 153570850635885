
import { observer } from "mobx-react";

import {
  User,
  Users,
  AppState,
  getUsers,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface UserDetailProps {
  history?: any;
  match?: any;
  location?: any;
  user?: User;
}

type ComponentState = any;



@observer
class UserDetail extends BaseTabbedDetailPage<UserDetailProps, ComponentState> {

  viewName = 'User Detail';
  objectName = 'user';

  
  get readOnly() {
    
    
    return true;
  }
  

  


  

  

  newObject() {
    return new User();
  }

  getCollection(): Users {
    return getUsers();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
        {
          name: 'Clients',
          related_field: 'sales_person',
          for_model: 'Client',
          
          collection: getCollectionByName('client')
        },
      
      
        {
          name: 'Tasks - User',
          related_field: 'attached_to_user',
          for_model: 'Task',
          
          collection: getCollectionByName('task')
        },
      
        {
          name: 'Tasks - Additional Users',
          related_field: 'additional_users',
          for_model: 'Task',
          
          collection: getCollectionByName('task')
        },
      
      
        {
          name: 'Notes - Users',
          related_field: 'users',
          for_model: 'Note',
          
          collection: getCollectionByName('note')
        },
      
      
      
        {
          name: 'Reminders',
          related_field: 'user',
          for_model: 'Reminder',
          
          collection: getCollectionByName('reminder')
        },
      
      
        {
          name: 'Groups - Members',
          related_field: 'members',
          for_model: 'Group',
          
          collection: getCollectionByName('group')
        },
      
      
    ];
  }

}

export default UserDetail;