import React, { Component } from 'react';
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import { withRouter, RouteComponentProps } from 'react-router';
import { Route, Link, Switch } from 'react-router-dom';
import { spring, AnimatedSwitch } from 'react-router-transition';

import moment from 'moment';
import DevTools from 'mobx-react-devtools';
import packageJson from '../../package.json';

import {
  NavigationDrawer,
  LinearProgress,
  Collapse,
  DialogContainer,
  Button,
} from 'react-md';

import { getNavigationStack } from '../utils/NavigationStack';

import AppToolbar from '../components/AppToolbar';
import NavItemLink from '../components/NavItemLink';
import Login from '../components/Login';

import {
  Dashboard,
  Settings,
  About,
  PageNotFound,
  Profile,
  Activities,
  ActivityReport,
  Notifications,

  
  Contacts,
  ContactDetail,
  ContactEdit,
    
  
  Clients,
  ClientDetail,
  ClientEdit,
    
  
  Tasks,
  TaskDetail,
  TaskEdit,
    
    MyTasks,
    
    CompletedTasks,
    
  
  Notes,
  NoteDetail,
  NoteEdit,
    
  
  Files,
  FileDetail,
  FileEdit,
    
  
  Reminders,
  ReminderDetail,
  ReminderEdit,
    
  
  Groups,
  GroupDetail,
  GroupEdit,
    
  
  Users,
  UserDetail,
  UserEdit,
    
  
} from '../pages';

import NotificationToolbarIcon from '../components/NotificationToolbarIcon';
import { getAppState, getAppSettings, User } from '../models';
import { logoutUrl } from '../api';





import './App.scss';


declare global {
  interface Window { AppVersion: any; }
}


let appState = getAppState();
let appSettings = getAppSettings();


@observer
class App extends Component {
  navItems = [
    {
      label: "Dashboard",
      to: "/",
      icon: "dashboard",
      exact: true
    },
    
    {
      label: "Contacts",
      to: "/contacts/",
      icon: "contacts",
      exact: false
    },
    
    
    {
      label: "Clients",
      to: "/clients/",
      icon: "people",
      exact: false
    },
    
    
    {
      label: "Tasks",
      to: "/tasks/",
      icon: "playlist_add_check",
      exact: false
    },
    
    {
      label: "My Tasks",
      to: "/tasks-mine/",
      icon: "playlist_add_check",
      exact: false
    },
    
    {
      label: "Completed Tasks",
      to: "/tasks-completed/",
      icon: "playlist_add_check",
      exact: false
    },
    
    
    {
      label: "Notes",
      to: "/notes/",
      icon: "note",
      exact: false
    },
    
    
    {
      divider: true,
      key: 'divider-2'
    },{
      label: "Activity Report",
      to: "/reports/activities/",
      icon: "poll",
      exact: false,
      isReport: true,
    },
    
    

    {
      divider: true,
      key: 'divider-3',
      isReport: true,
    },
    {
      isSystemMenu: true,
      key: 'system-medu'
    },
    {
      divider: true,
      key: 'divider-4'
    },
    {
      label: "Logout",
      to: logoutUrl,
      icon: "arrow_back",
      external: true,
      target: "_self",
      exact: false
    },
    {
      logo: true,
      key: 'developer-logo-1',
      image: '/developer-transparent.png',
      to: 'https://groverwebdesign.com',
      external: true,
    },
  ].map((props: any) => {
    return (<NavItemLink
        key={props.key ? props.key : props.to}
        {...props}
      />);
    });

  mapStyles(styles: any) {
    const stylesheet = { position: 'relative', opacity: styles.opacity, transform: 'none', height: 'auto' };
    if ( (styles.scale !== 0)) {
      stylesheet.transform = `translateX(${styles.scale}%)`;
    }
    if ( (styles.opacity !== 1)) {
      stylesheet.position = 'absolute';
    }
    return stylesheet;
  }

  bounce(val: any) {
    return spring(val, {
      stiffness: 330,
      damping: 35,
    });
  }

  _loginIntervalRef: any = null;

  async componentDidMount() {
    this._loginIntervalRef = window.setInterval(() => appState.checkLogin(), 60000);
    window.setTimeout(() => appState.checkLogin(), 1000);
  }

  async componentWillUnmount() {
    window.clearInterval(this._loginIntervalRef);
  }

  logout = () => {
    appState.isLoggedIn = false;
    appState.wasLoggedIn = true;
  }

  onLogin = (user: User) => {
    appState.isLoggedIn = true;
    appState.wasLoggedIn = true;
    appState.userProfile = user;
  }

  renderLoginForm() {
    return <div className={appSettings.darkMode ? "App App--DarkMode" : "App"}><div className="LoginPage"><div className="LoginPageBackground"></div><div className="md-grid"><Login onLogin={this.onLogin} /></div></div></div>;
  }

  renderLoginModal() {
    let visible = (!appState.isLoggedIn) && appState.wasLoggedIn;

    return (
      <DialogContainer
        id="modal-login"
        visible={visible}
        width={400}
        aria-label="You have been logged out."
        modal
      >
        <Login
          onLogin={this.onLogin}
          skipAutomaticLoginCheck={true}
          plain={true}
          loginMessage={"You have been logged out."}
        />
      </DialogContainer>
    );
  }

  onCloseErrorModal = () => {
    appState.showErrorMessage = false;
    appState.errorMessage = undefined;
  }

  onReportIssueButton = () => {
    let Raven = (window as any).Raven;
    if (!Raven) {
      window.open(packageJson.reportUrl, '_blank');
      return;
    }

    let reported = false;
    try {
      Raven.showReportDialog();
      reported = true;
    } catch (error) {
      console.log(error);
    }

    if (!reported) {
      let now = moment().format('YYYY-MM-DD h:mm:ss a');
      let err = new Error(`New Error Report - ${now}`);
      Raven.captureException(err);
      Raven.showReportDialog();
    }
  }


  onCloseQueuedModal = () => {
    appState.dismissQueuedModal();
  }

  renderQueuedModal() {
    let visible = false;
    let title = null;
    let actions: any = null;
    let content = null;
    let width = undefined;

    let modal = appState.currentModal;
    if (modal) {
      visible = true
      title = modal.title;
      content = modal.content;
      actions = modal.actions;
      width = modal.width;
    }

    // don't show if other modal is visible
    let loginModalVisible = (!appState.isLoggedIn) && appState.wasLoggedIn;
    let errorModalVisible = appState.showErrorMessage;
    if (errorModalVisible || loginModalVisible) visible = false;

    return (
      <DialogContainer
        id="modal-queued"
        visible={visible}
        title={title}
        aria-label={title ? title : 'Modal'}
        onHide={this.onCloseQueuedModal}
        actions={actions}
        width={width}
      >
        {content}
      </DialogContainer>
    );
  }

  renderErrorModal() {
    let visible = appState.showErrorMessage;
    let errorMessage = appState.errorMessage ? appState.errorMessage : 'An error has accured. Please try again later.';

    return (
      <DialogContainer
        id="modal-error"
        visible={visible}
        width={400}
        aria-label="Error"
        title="Error"
        onHide={this.onCloseErrorModal}
        actions={[
          {
            onClick: this.onCloseErrorModal,
            primary: true,
            children: 'Close',
          },
          {
            onClick: this.onReportIssueButton,
            primary: true,
            children: 'Report issue',
          }
        ]}
      >
        <p className="md-color--secondary-text">{errorMessage}</p>
      </DialogContainer>
    );
  }

  onCloseAppUpdateModal = () => {
    appState.ignoreAppUpdated = true;
  }

  onReloadApplication = () => {
    window.location.reload();
  }

  renderAppUpdatedModal() {
    let visible = appState.appUpdated;
    if (appState.ignoreAppUpdated) visible = false;
    let message = `A new version of ${packageJson.appName} is available. Click "Reload Application" to load the latest version now.`;

    return (
      <DialogContainer
        id="modal-app-updated"
        visible={visible}
        width={400}
        aria-label="Application Updated"
        title="New Version Available"
        onHide={this.onCloseAppUpdateModal}
        actions={[
          {
            onClick: this.onCloseAppUpdateModal,
            primary: false,
            children: 'Later',
          },
          {
            onClick: this.onReloadApplication,
            primary: true,
            children: 'Reload Application',
          }
        ]}
      >
        <p className="md-color--secondary-text">{message}</p>
      </DialogContainer>
    );
  }

  componentDidUpdate(prevProps: any) {
    if ((this.props as any).location.pathname != prevProps.location.pathname) {
      let queuedModal = appState.popQueuedModal();
      // console.log('popQueuedModal', queuedModal)

      // after saving (or canceling an edit), make sure the detail view is not pushed to
      // navigation stack to avoid double entries for detail view url
      if ((this.props as any).location.pathname.endsWith('/edit/') && ! (this.props as any).location.pathname.endsWith('/0/edit/')) {
        return;
      }

      this.onRouteChanged(prevProps.location.pathname);
    }
  }

  onRouteChanged(pathname: string) {
    // don't save current route in history stack if it's an edit page
    if (pathname.endsWith('/edit/')) {
      return;
    }
    let stack = getNavigationStack();
    stack.push(pathname);
  }

  render() {
    if ((!appState.isLoggedIn) && (!appState.wasLoggedIn)) {
      return this.renderLoginForm();
    }

    // child matches will...
    const bounceTransition = {
      // start in a transparent, upscaled state
      atEnter: {
        opacity: 0,
        scale: 100,
      },
      // leave in a transparent, downscaled state
      atLeave: {
        opacity: this.bounce(0),
        scale: this.bounce(0),
      },
      // and rest at an opaque, normally-scaled state
      atActive: {
        opacity: this.bounce(1),
        scale: this.bounce(0),
      },
    };

    let appClassName = "App";
    if (appSettings.darkMode) {
      appClassName = `${appClassName} App--DarkMode`;
      var htmlS = document.getElementsByTagName('html');
      if (htmlS.length && !htmlS[0].classList.contains('DarkMode')) {
        htmlS[0].classList.add('DarkMode')
      }
    }
    else {
      var htmlS = document.getElementsByTagName('html');
      if (htmlS.length && htmlS[0].classList.contains('DarkMode')) {
        htmlS[0].classList.remove('DarkMode')
      }
    }
    if (!appState.isLoggedIn) {
      appClassName = `${appClassName} App--LoggedOut`;
    }

    let loginModal = this.renderLoginModal();
    let errorModal = this.renderErrorModal();
    let queuedModal = this.renderQueuedModal();
    let appUpdatedModal = this.renderAppUpdatedModal();

    let logoEl = (
      <h2 className="with-logo">
        <img src="https://crm.pagmarketingsc.com/media/user-files/2020/07/15/pagcrm-logo-2.png" alt={packageJson.appName} className="main-logo-img" />
      </h2>
    );

    return (
      <NavigationDrawer
        toolbarTitle={appState.pageTitle}
        drawerTitle={logoEl}
        navItems={this.navItems}
        className={appClassName}
        toolbarActions={<AppToolbar />}
      >
        <div style={ {position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'auto'} }>
          <Collapse collapsed={!appState.isLoading()}>
            <LinearProgress id="mainloading" query={false} />
          </Collapse>
          <AnimatedSwitch
            atEnter={bounceTransition.atEnter}
            atLeave={bounceTransition.atLeave}
            atActive={bounceTransition.atActive}
            mapStyles={this.mapStyles}
            className="switch-wrapper"
          >
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/activities/" component={Activities} />
            <Route exact path="/reports/activities/" component={ActivityReport} />
            <Route exact path="/about/" component={About} />

            
            <Route exact path="/contacts/" component={ Contacts } />
            <Route exact path="/contacts/:id/" component={ ContactDetail } />
            <Route exact path="/contacts/:id/edit/" component={ ContactEdit } />
            
            
            <Route exact path="/clients/" component={ Clients } />
            <Route exact path="/clients/:id/" component={ ClientDetail } />
            <Route exact path="/clients/:id/edit/" component={ ClientEdit } />
            
            
            <Route exact path="/tasks/" component={ Tasks } />
            <Route exact path="/tasks/:id/" component={ TaskDetail } />
            <Route exact path="/tasks/:id/edit/" component={ TaskEdit } />
            
            <Route exact path="/tasks-mine/" component={ MyTasks } />
            
            <Route exact path="/tasks-completed/" component={ CompletedTasks } />
            
            
            <Route exact path="/notes/" component={ Notes } />
            <Route exact path="/notes/:id/" component={ NoteDetail } />
            <Route exact path="/notes/:id/edit/" component={ NoteEdit } />
            
            
            <Route exact path="/files/" component={ Files } />
            <Route exact path="/files/:id/" component={ FileDetail } />
            <Route exact path="/files/:id/edit/" component={ FileEdit } />
            
            
            <Route exact path="/reminders/" component={ Reminders } />
            <Route exact path="/reminders/:id/" component={ ReminderDetail } />
            <Route exact path="/reminders/:id/edit/" component={ ReminderEdit } />
            
            
            <Route exact path="/groups/" component={ Groups } />
            <Route exact path="/groups/:id/" component={ GroupDetail } />
            <Route exact path="/groups/:id/edit/" component={ GroupEdit } />
            
            
            <Route exact path="/users/" component={ Users } />
            <Route exact path="/users/:id/" component={ UserDetail } />
            <Route exact path="/users/:id/edit/" component={ UserEdit } />
            
            

            
            
            <Route exact path="/notifications/" component={Notifications} />
            <Route exact path="/profile/" component={Profile} />
            <Route exact path="/settings/" component={Settings} />
            <Route component={PageNotFound} />
           </AnimatedSwitch>
           {appSettings.debugMode && <DevTools />}
           {loginModal}
           {errorModal}
           {queuedModal}
           {appUpdatedModal}
        </div>
       </NavigationDrawer>
    );
  }
}

export default App;
