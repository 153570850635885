import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Badge,
  Button
} from 'react-md';

import NotificationToolbarButton from './NotificationToolbarButton';

import { getAppState } from '../models';

let appState = getAppState();

interface AppToolbarProps {
  
}

class AppToolbar extends Component<AppToolbarProps> {
  onClick = () => {
    if (appState.toolbarButton && appState.toolbarButton.onClick) {
      appState.toolbarButton.onClick();
    }
  }

  renderNotificationButton() {
    return <NotificationToolbarButton />
  }

  render() {
    if (!appState.toolbarButton) return this.renderNotificationButton();

    let button = appState.toolbarButton;
    return (
      <Button
        className="md-btn--toolbar"
        icon
        tooltipLabel={button.label}
        tooltipPosition={'bottom'}
        onClick={this.onClick}
      >{button.icon}</Button>
    );
  }
}

export default AppToolbar;