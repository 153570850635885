import React from 'react';
import { createViewModel } from 'mobx-utils'
import { Redirect } from 'react-router-dom';

import {
  Avatar,
  Card,
  CardTitle,
  FontIcon,
  Snackbar,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  AppState,
} from '../models';

import {
  BasePage,
} from './BasePage';

import ObjectEditForm from '../components/ObjectEditForm';

type ComponentState = any;


abstract class BaseEditPage<P, ComponentState> extends BasePage<P, ComponentState> {
  state = {
    object: this.newObject(),
    objectEdit: createViewModel(this.newObject()),
    isNew: false,
    saving: false,
    notFound: false,
    toasts: [],
    redirectTo: '',
    error: false,
    errorFields: {}
  }

  viewName = 'ObjectEdit';
  objectName = 'object';

  get objectLabel() {
    return this.objectName;
  }

  abstract newObject(): BaseModel;
  abstract getCollection(): BaseModelCollection;
  abstract getAppState(): AppState;
  
  get helpText() {
    return '';
  }

  get readOnly() {
    return false;
  }

  getObjectTitle(): string {
    return this.state.object.getTitle();
  }

  getObjectSubtitle(): string {
    return this.state.object.getSubtitle();
  }

  getMatch() {
    return (this.props as any).match;
  }

  getObjectFromProp(): BaseModel {
    return (this.props as any).match;
  }

  async getObject() {
    let collection = this.getCollection();
    let match = this.getMatch();

    if (match) {
      if (match.params.id === '0') {
        return this.newObject();
      }
      return await collection.get(parseInt(match.params.id));
    }

    let object = this.getObjectFromProp();
    if (object) {
      return object;
    }
    return this.newObject();
  }

  onSaved = () => {
    this.redirectTo(this.state.object.viewURL);
  }

  onBackButton = () => {
    console.log(this.state.object.id)
    if (!this.state.object.id) {
      let backUrl = this.getBackUrl();
      if (backUrl) {
        this.redirectTo(backUrl);
        return;
      }
    }
    
    this.redirectTo(this.state.object.viewURL);
  }

  showLoading = () => {
    let appState = this.getAppState();
    appState.showLoading();
  }

  hideLoading = () => {
    let appState = this.getAppState();
    appState.hideLoading();
  }

  async componentDidMount() {
    let appState = this.getAppState();

    this.showLoading();
    try {
      let object = await this.getObject();
      this.setState({
        object: object,
        objectEdit: createViewModel(object),
        isNew: !object.id,
      });
    }
    catch (e) {
      this.addToast('Error fetching data! Please try again.');
      this.setState({notFound: true})
      console.error(e);
    }
    finally {
      this.hideLoading();
    }

    let title = this.state.object.getTitle();
    if (title) {
      appState.pageTitle = `Editing ${title}`;
    }
    if (!this.state.object.id) {
      appState.pageTitle = `New ${this.objectLabel}`;
    }

  }

  render404() {
    let object: BaseModel = this.state.object;
    return (<div className="md-grid">
      <Card className="md-cell--6 md-block-centered">
        <CardTitle
          title="Not Found"
          subtitle={`${this.objectName} not found!`}
          avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
        />
      </Card>
      <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
    </div>);
  }

  renderReadOnly() {
    let object: BaseModel = this.state.object;
    return (<div className="md-grid">
      <Card className="md-cell--6 md-block-centered">
        <CardTitle
          title="Edit Disabled"
          subtitle={`You are not authorized to edit this item!`}
          avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
        />
      </Card>
      <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
    </div>);
  }

  renderLoading() {
    let appState = this.getAppState();
    let object: BaseModel = this.state.object;
    return (<div className="md-grid">
      <Card className="md-cell--6 md-block-centered">
        <CardTitle
          title={appState.isLoading() ? 'Loading...' : 'Empty'}
          avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
        />
      </Card>
      <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
    </div>);
  }

  render() {
    let redirect = this.renderRedirect();

    if (this.state.notFound) {
      return this.render404();
    }

    let object: BaseModel = this.state.object;

    if (!object.id && !this.state.isNew) {
      return this.renderLoading();
    }

    return (
      <div className={`${this.className} md-grid`}>
        <ObjectEditForm
          isNew={this.state.isNew}
          className="md-cell--12 md-block-centered"
          object={this.state.object}
          collection={this.getCollection()}
          objectLabel={this.objectLabel}
          onSaved={this.onSaved}
          onCancel={this.onBackButton}
          onBackButtonClicked={this.onBackButton}
          addToast={this.addToast}
          showLoading={this.showLoading}
          hideLoading={this.hideLoading}
          setToolbarButton={true}
          helpText={this.helpText}
        />

        <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
        {redirect}
        {(!redirect && this.readOnly) && 
          <Redirect to={this.state.object.viewURL} />
        }
      </div>
    );
  }
}

export {
  BaseEditPage,
};