
import { BaseApi, BaseMockApi } from './BaseApi';

class Notifications extends BaseApi {
  objectName = 'notifications'

  async markAllRead() {
    let raw;
    let url =`${this.baseUrl}mark_all_read/`;

    try {
      let response = await fetch(url, {
        credentials: 'include',
        method: 'POST',
        headers: {
          "X-CSRFToken": this.csrfToken,
          'Content-Type': 'application/json',
        }
      });

      raw = await response.json();
      // console.log(raw);
    }
    catch (e) {
      throw new Error(`Error marking all notifications as read!`);
      console.error(e);
    }

    if (raw.success) {
      return;
    }
    else {
      throw new Error(`Error marking all notifications as read!`);
    }
  }
}


class MockNotifications extends BaseMockApi {
    objectName = 'notifications'
    incrementalId = 1;
    mockData = [];

    async markAllRead() {
      let tmp = await this.arrayPromiseTimeout(() => this.mockData[0], 500);
      return;
    }
  
}


export {
  Notifications,
  MockNotifications,
};
