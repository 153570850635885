
import { observer } from "mobx-react";

import {
  Task,
  Tasks,
  AppState,
  getTasks,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface TaskEditProps {
  history?: any;
  match?: any;
  location?: any;
  task?: Task;
}

type ComponentState = any;


@observer
class TaskEdit extends BaseEditPage<TaskEditProps, ComponentState> {
  viewName = 'Task Edit';
  objectName = 'task';

  

  get objectLabel() {
    return "Task";
  }

  newObject() {
    const obj = new Task();

  

    return obj;
  }

  getCollection(): Tasks {
    return getTasks();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default TaskEdit;