import React, { Component } from 'react';
import { toJS } from "mobx";
import { Redirect } from 'react-router-dom';
import formatNumber from 'format-number';
import moment from 'moment';
import { observer } from "mobx-react";

import {
  List,
  ListItem,
  DatePicker,
  SelectField,
  TextField,
  FileInput,
  TimePicker,
  Checkbox,
  Button,
  CardActions,
  Autocomplete,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getCollectionByName,
  getModelLabelPlural,
  getAppState,
} from '../models';

import ObjectMiniItem from './ObjectMiniItem';
import ObjectPicker from './ObjectPicker';
import InlineObjectEditForm from './InlineObjectEditForm';


interface ObjectViewerAndEditorProps {
  object: BaseModel,
  collection: BaseModelCollection;
  objectLabel: string;
  allowCreate?: boolean;
  onSaved: (object: BaseModel) => void;
  onCancel?: any;
  onDelete?: any;
  onBackButtonClicked: () => void;
  addToast?: (message: string) => void;
  showLoading?: () => void;
  hideLoading?: () => void;
  className?: string;
  setToolbarButton?: boolean;
  helpText?: string;
}

interface FieldInfo {
  type?: string;
  label?: string;
  width?: number;
  options?: {label: string; value: string;}[];
}

@observer
class ObjectViewerAndEditor extends Component<ObjectViewerAndEditorProps> {
  state = {
    redirectTo: '',
  };

  isNewObject() {
    if (!this.props.object.id) return true;
    return false;
  }

  isHidden(key: string) {
    return this.props.object.isHidden(key);
  }

  getLabel(key: string) {
    return this.props.object.getLabel(key);
  }

  renderRedirect() {
    if (this.state.redirectTo !== '') {
      return <Redirect to={this.state.redirectTo} />;
    }
  }

  renderItem(key: string, value: any) {
    // if (value === undefined) return;
    // if (value === null) return;

    let info = this.props.object.getFieldInfo(key);
    if (info.hideViewer) return;
    // if (!value && info.hideIfEmpty) return;

    return (
      <ObjectField
        object={this.props.object}
        fieldKey={key}
        fieldInfo={info}
        fieldValue={value}
      />
    )
  }

  groupedItems(): ({key: string; value?: any}[])[] {
    let groupedItems: ({key: string; value?: any}[])[] = [];
    let defaultGroup: {key: string; value?: any}[] = [];
    let processedFields: string[] = [];

    let replica = toJS(this.props.object);
    this.props.object.form_layout.forEach(item => {
      if (typeof item === 'string') {
        let key = item;
        defaultGroup.push({
          key: key,
          value: (this.props.object as any)[`render_${key}`] ? (this.props.object as any)[`render_${key}`]() : (replica as any)[key]
        });
        processedFields.push(key);
      }
      else {
        let newGroup: {key: string; value?: any}[] = [];
        item.forEach(subItem => {
          let key = subItem;
          newGroup.push({
            key: key,
            value: (this.props.object as any)[`render_${key}`] ? (this.props.object as any)[`render_${key}`]() : (replica as any)[key]
          });
          processedFields.push(key);
        });
        groupedItems.push(newGroup);
      }
    })

    Object.keys(replica).forEach(key => {
      if (processedFields.includes(key)) return;
      if (this.isHidden(key)) return;
      if (this.props.object.isMeta(key)) return;
      defaultGroup.push({
        key: key,
        value: (this.props.object as any)[`render_${key}`] ? (this.props.object as any)[`render_${key}`]() : (replica as any)[key]
      })
    });

    if (defaultGroup.length > 0) groupedItems.push(defaultGroup);

    return groupedItems;
  }


  isError(key: string, fieldInfo: any, value: any) {
    let fieldType = fieldInfo.type ? fieldInfo.type : 'text'
    switch (fieldType) {
      case 'number':
        if (fieldInfo.required && ((value === undefined) || (value === null) || (value === ''))) return true;
        break;
    
      default:
        if (fieldInfo.required && !value) return true;
    }
    return false;
  }

  hasError() {
    let hasError = false;
    let replica = toJS(this.props.object);
    Object.keys(replica).forEach(key => {
      if (this.isHidden(key)) return;
      if (this.props.object.isMeta(key)) return;
      let value = (replica as any)[key];
      let fieldInfo = this.props.object.getFieldInfo(key);
      if (this.isError(key, fieldInfo, value)) {
        hasError = true;
      }
    });

    return hasError;

  }

  render() {
    let redirect = this.renderRedirect();
    if (redirect) return redirect;

    let groupedItems = this.groupedItems();

    return (
      <>
        {groupedItems.map((items, i) => {
          return (
            <List className="ObjectViewerAndEditor" style={{paddingBottom: 6, borderBottom: (i < (groupedItems.length - 1)) ? 'solid 1px #ddd' : undefined}}>
              {items.map((item) => this.renderItem(item.key, item.value))}
            </List>
          )
        })}
        {(this.isNewObject() && this.props.allowCreate) &&
          <CardActions className="md-cell md-cell--12">
            <Button
              raised
              primary
              className="md-cell--left"
              onClick={async () => {
                let success = await this.props.object.save();
                if (success) this.setState({redirectTo: this.props.object.viewURL})
              }}
              disabled={this.hasError()}
              iconChildren="edit"
            >Save</Button>
            <Button
              raised
              className="md-cell--right"
              onClick={() => {
                this.setState({redirectTo: this.props.object.collectionViewURL})
              }}
            >Cancel</Button>
          </CardActions>}
      </>
    );
  }
}

class ViewerFieldsRenderer {

  isError(key: string, fieldInfo: any, value: any) {
    let fieldType = fieldInfo.type ? fieldInfo.type : 'text'
    switch (fieldType) {
      case 'number':
        if (fieldInfo.required && ((value === undefined) || (value === null) || (value === ''))) return true;
        break;
    
      default:
        if (fieldInfo.required && !value) return true;
    }
    return false;
  }

  getErrorMessage(key: string, fieldInfo: any, value: any) {
    if (this.isError(key, fieldInfo, value)) return 'This field is required';
    return '';
  }

  renderNumberField(key: string, fieldInfo: any, value: any) {
    let processedValue: any = value;
    let formatOpt: any = {};

    if (fieldInfo.prefix) {
      formatOpt['prefix'] = fieldInfo.prefix;
    }

    if (fieldInfo.format) {
      processedValue = formatNumber(formatOpt)(value);
    }
    else {
      processedValue = formatNumber(formatOpt)(value, {noSeparator: true});
    }

    return processedValue;
  }

  renderTextField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (!value) value = '-';
    if (errorMessage) {
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    return <div>{value}</div>;
  }

  renderFileField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div>-</div>);
    let name = value.split('/').pop();
    return <div>{name ? `Click to download ${name}` : '-'}</div>;
  }

  renderSelectField(key: string, fieldInfo: any, value: any) {
    if (!value && (value !== 0)) return (<div>-</div>);
    let label = `${value}`;
    fieldInfo.options.forEach((option: any) => {
      if (option.value === value) {
        label = option.label;
      }
    })
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (!value && (value !== 0)) label = '-';
    if (errorMessage) {
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{label}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    return <div>{label}</div>;
  }

  renderCheckField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};
    return (<Checkbox
      id={`object-viewer-field-${key}`}
      key={`object-viewer-field-${key}`}
      label={fieldInfo.label}
      name={fieldInfo.label}
      className="object-viewer-checkbox"
      value={value}
      checked={value}
      disabled={true}
      {...extraProps}
    />);
  }

  renderEmailField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    return <a className="md-tile-text--secondary md-text--secondary" href={`mailto:${value}`} onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}}>{value}</a>;
  }

  renderPhoneField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    return <a className="md-tile-text--secondary md-text--secondary" href={`tel:${value}`} onClick={(e) => e.stopPropagation()} style={{display: 'inline-block'}}>{value}</a>;
  }

  renderDateTimeField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('MM/DD/YYYY hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderTimeField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderDateField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    let formatted = moment(value).format('MM/DD/YYYY');
    let formattedFromNow = moment(value).fromNow();
    return <div>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderModelField(key: string, fieldInfo: any, value: any) {
    let errorMessage = this.getErrorMessage(key, fieldInfo, value);
    if (errorMessage) {
      if (!value) value = '-';
      return (
        <div style={{color: 'rgb(244, 67, 54)'}}>
          <div>{value}</div>
          <div>{errorMessage}</div>
        </div>
      );
    }
    if (!value) return (<div>-</div>);
    return (
    <ObjectMiniItem
      key={key}
      objectId={value}
      objectName={fieldInfo.objectName}
      stopPropagation
    />
    );
  }

  renderModelsField(key: string, fieldInfo: any, idList: number[]) {
    if (idList.length === 0) return (<div>-</div>)
    let items = idList.map((id, i) => this.renderModelField(`${key}-${i}`, fieldInfo, id));
    return (
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
      }}>
        {items}
      </div>);
  }

}

class EditorFieldsRenderer {

  autofocusRefProcessing = (ref?: any) => {
    if (ref && ref._container) {
      if (ref && ref.focus) ref.focus();
    }
    if (ref && !ref._container) {
      console.log('>>>>', ref, ref ? ref._container : 'null')
    }
  }

  isError(key: string, fieldInfo: any, value: any) {
    let fieldType = fieldInfo.type ? fieldInfo.type : 'text'
    switch (fieldType) {
      case 'number':
        if (fieldInfo.required && ((value === undefined) || (value === null) || (value === ''))) return true;
        break;
    
      default:
        if (fieldInfo.required && !value) return true;
    }
    return false;
  }

  getErrorMessage(key: string, fieldInfo: any, value: any) {
    if (this.isError(key, fieldInfo, value)) return 'This field is required';
  }

  renderTextField(key: string, fieldInfo: any, value: any, object: BaseModel, onCommit: () => void, onCancel: () => void) {
    let extraProps: any = {};

    if (fieldInfo.multiline) {
      extraProps.rows = 3;
    }

    let el = (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={object.getField(key)}
      onChange={(value, e) => {
        object.apply_value(key, value);
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      error={this.isError(key, fieldInfo, value)}
      errorText={this.getErrorMessage(key, fieldInfo, value)}
      ref={this.autofocusRefProcessing}
      {...extraProps}
    />);

    if (fieldInfo.multiline) {
      return (
      <div id={`object-editor-field-multiline-${key}`} key={`object-editor-field-multiline-${key}`}>
        {el}
        <div style={{}}>
          <Button raised primary onClick={onCommit}>Done</Button>
          <Button raised onClick={onCancel} style={{marginLeft: 5}}>Cancel</Button>
        </div>
      </div>
      );
    }
    return el;
  }

  renderAutocompleteField(key: string, fieldInfo: any, value: any, object: BaseModel, onCommit: () => void, onCancel: () => void) {
    let extraProps: any = {};

    if (fieldInfo.multiline) {
      extraProps.rows = 3;
    }

    let options: string[] = [];
    if (fieldInfo.source = 'distinct_value') {
      let collection = getCollectionByName(fieldInfo.objectName);
      options = collection.getDistinctValues(key);
    }

    return (
      <div id={`object-editor-field-multiline-${key}`} key={`object-editor-field-multiline-${key}`}>
        <Autocomplete
          id={`object-editor-field-${key}`}
          key={`object-editor-field-${key}`}
          label={fieldInfo.label}
          value={object.getField(key)}
          data={options}
          onChange={(value, e) => {
            object.apply_value(key, value);
          }}
          onAutocomplete={(value: any) => {
            console.log('>>', value);
            object.apply_value(key, value);
          }}
          disabled={fieldInfo.disabled}
          required={fieldInfo.required ? true : false}
          error={this.isError(key, fieldInfo, value)}
          errorText={this.getErrorMessage(key, fieldInfo, value)}
          ref={this.autofocusRefProcessing}
          {...extraProps}
        />
        <div style={{}}>
          <Button raised primary onClick={onCommit}>Done</Button>
          <Button raised onClick={onCancel} style={{marginLeft: 5}}>Cancel</Button>
        </div>
      </div>
    );
  }

  renderEmailField(key: string, fieldInfo: any, value: any, object: BaseModel) {
    let extraProps: any = {};

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      type="email"
      label={fieldInfo.label}
      value={object.getField(key)}
      onChange={(value, e) => {
        object.apply_value(key, value);
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      ref={this.autofocusRefProcessing}
      {...extraProps}
    />);
  }

  renderPhoneField(key: string, fieldInfo: any, value: any, object: BaseModel) {
    let extraProps: any = {};

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      type="tel"
      label={fieldInfo.label}
      value={object.getField(key)}
      onChange={(value, e) => {
        object.apply_value(key, value);
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      ref={this.autofocusRefProcessing}
      {...extraProps}
    />);
  }

  numberFieldRefProcessing = (ref?: any) => {
    if (ref && ref._container) {
      if (ref && ref.focus) ref.focus();
      let input = ref._container.querySelector('input');
      input.addEventListener('keypress', (event: any) => {
        // console.log('keypress', event);
        if (event && event.which) {
          if (((event.which > 47) && (event.which < 58)) || (event.which == 13) || (event.which == 45) || (event.which == 46)) {

          }
          else {
            event.preventDefault();
          }
        }
      });
    }
  }

  renderNumberField(key: string, fieldInfo: any, value: any, object: BaseModel) {
    let extraProps: any = {
      type: "number",
      step: 1,
    };

    if (fieldInfo.min !== undefined) {
      extraProps.min = fieldInfo.min;
    }

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={object.getField(key)}
      onChange={(value, e) => {
        object.apply_value(key, value);
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      ref={this.numberFieldRefProcessing}
      {...extraProps}
    />);
  }

  renderModelsField(key: string, fieldInfo: any, v: any, object: BaseModel, onCommit: () => void, onCancel: () => void) {
    let values = object.getField(key);
    let extra: any;
    let createInline: any;
    let filterTool: any;
    const getId = (object: any) => object;
    const getObjectName = (object: any) => fieldInfo.objectName;

    const collection = getCollectionByName(fieldInfo.objectName);

    const onChange = (id: number) => {
      let newValues = [...values];

      let exist = false;
      newValues.forEach(val => {
        if (val === id) exist = true;
      })
      if (!exist) {
        newValues.push(id);
        object.apply_value(key, newValues);
        console.log(newValues)
      }
    };

    const onRemove = (value: any) => {
      let values = object.getField(key);
      let newValues = (values as any[]).filter(svalue => svalue !== value);
      console.log(newValues);
      object.apply_value(key, newValues);
    }
    
    if (fieldInfo.inlineCreate) {
      let newObject = new collection.baseClass();
      createInline = (
        <InlineObjectEditForm
          isNew={true}
          object={newObject}
          collection={collection}
          objectLabel={newObject.object_type}
          onSaved={(model: BaseModel) => {
            console.log(model);
            if (model.id) onChange(model.id);
          }}
        />
      );
    }

    if (values) {
      let items = (values as any[]).map((value: any, i: number) => {
        let id = getId(value);
        // console.log(id, value);
        return (<ObjectMiniItem
          key={`${key}-${i}-${id}`}
          objectId={getId(value)}
          objectName={getObjectName(value)}
          removable={true}
          onClick={() => onRemove(value)}
        />);
      });
      extra = (<div style={{paddingLeft: '20px'}}>
        <span>{items}</span>
        <Button raised primary onClick={onCommit} style={{marginTop: 5}}>Done</Button>
        <Button raised onClick={onCancel} style={{marginTop: 5, marginLeft: 5}}>Cancel</Button>
      </div>);
    }

    const modelNamePlural = getModelLabelPlural(collection.object_name);

    return (<div id={`object-editor-field-container-${key}`} key={`object-editor-field-container-${key}`} className="ModelsFieldContainer">
    <ObjectPicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      placeholder={`type to search ${modelNamePlural}...`}
      collection={collection}
      onAutocomplete={onChange}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      autofocus
      style={{
        marginTop: 8,
        marginBottom: 8,
        marginRight: fieldInfo.objectFilterTool ? 50 : 0,
        marginLeft: -20,
      }}
    />
    {extra}
    {createInline}
    </div>);
  }

  renderModelField(key: string, fieldInfo: any, value: any, object: BaseModel, onCommit: () => void, onCancel: () => void) {
    let values: any[] = [];
    if (value) values.push(value);
    let extra: any;
    let createInline: any;
    let filterTool: any;
    const getId = (object: any) => object;
    const getObjectName = (object: any) => fieldInfo.objectName;

    const collection = getCollectionByName(fieldInfo.objectName);

    const onChange = (id: number) => {
      let newValues: any[] = [];

      let exist = false;
      newValues.forEach(val => {
        if (val === id) exist = true;
      })
      if (!exist) {
        newValues.push(id);
        object.apply_value(key, id);
        console.log(newValues)
      }
    };

    const onRemove = (value: any) => {
      object.apply_value(key, undefined);
    }
    
    if (fieldInfo.inlineCreate) {
      let newObject = new collection.baseClass();
      createInline = (
        <InlineObjectEditForm
          isNew={true}
          object={newObject}
          collection={collection}
          objectLabel={newObject.object_type}
          onSaved={(model: BaseModel) => {
            console.log(model);
            if (model.id) onChange(model.id);
          }}
        />
      );
    }

    if (values) {
      let items = (values as any[]).map((value: any, i: number) => {
        let id = getId(value);
        // console.log(id, value);
        return (<ObjectMiniItem
          key={`${key}-${i}-${id}`}
          objectId={getId(value)}
          objectName={getObjectName(value)}
          removable={true}
          onClick={() => onRemove(value)}
        />);
      });
      extra = (<div style={{paddingLeft: '20px'}}>
        <span>{items}</span>
        <Button raised primary onClick={onCommit} style={{marginTop: 5}}>Done</Button>
        <Button raised onClick={onCancel} style={{marginTop: 5, marginLeft: 5}}>Cancel</Button>
      </div>);
    }

    const modelNamePlural = getModelLabelPlural(collection.object_name);

    return (<div id={`object-editor-field-container-${key}`} key={`object-editor-field-container-${key}`} className="ModelsFieldContainer">
    <ObjectPicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      placeholder={`type to search ${modelNamePlural}...`}
      collection={collection}
      onAutocomplete={onChange}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      autofocus
      style={{
        marginTop: 8,
        marginBottom: 8,
        marginRight: fieldInfo.objectFilterTool ? 50 : 0,
        marginLeft: -20,
      }}
    />
    {extra}
    {createInline}
    </div>);
  }

  renderCheckField(key: string, fieldInfo: any, value: any, object: BaseModel, onChange?: () => void) {
    let extraProps: any = {};
    return (<Checkbox
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      name={fieldInfo.label}
      value={value}
      checked={object.getField(key)}
      onChange={(value, e) => {
        object.apply_value(key, value);
        if (onChange) onChange();
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      style={{marginLeft: -10}}
      {...extraProps}
    />);
  }

  selectFieldRefProcessing = (ref?: any) => {
    if (ref && ref._container) {
      if (ref && ref.focus) ref.focus();
      if (ref && ref._toggle) ref._toggle();
    }
  }

  renderSelectField(key: string, fieldInfo: any, value: any, object: BaseModel, onChange?: () => void) {
    let extraProps: any = {};
    return (<SelectField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      simplifiedMenu={true}
      className="md-full-width md-text-field-container"
      sameWidth
      value={object.getField(key)}
      menuItems={fieldInfo.options}
      onChange={(value, e) => {
        object.apply_value(key, value);
        if (onChange) onChange();
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      // error={this.isError(key)}
      // errorText={this.getErrorMessage(key)}
      ref={this.selectFieldRefProcessing}
      {...extraProps}
    />);
  }

  renderDateField(key: string, fieldInfo: any, value: any, object: BaseModel, onChange: () => void, onCancel: () => void) {
    let extraProps: any = {};

    return (<DatePicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={object.getField(key) ? moment(object.getField(key)).toDate() : undefined}
      icon={null}
      closeYearOnSelect={true}
      closeOnEsc={false}
      autoOk={true}
      onChange={(value, e) => {
        let m = moment(value);
        object.apply_value(key, m.format('YYYY-MM-DD'));
        if (onChange) onChange();
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      inline
      defaultVisible={true}
      {...extraProps}
    />);
  }

  renderTimeField(key: string, fieldInfo: any, value: any, object: BaseModel, onChange: () => void, onCancel: () => void) {
    let extraProps: any = {};

    return (<TimePicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={object.getField(key) ? moment(object.getField(key)).toDate() : undefined}
      icon={null}
      closeOnEsc={false}
      autoOk={true}
      onChange={(value, e) => {
        let m = moment(value);
        object.apply_value(key, m.format());
        if (onChange) onChange();
      }}
      disabled={fieldInfo.disabled}
      required={fieldInfo.required ? true : false}
      inline
      defaultVisible={true}
      {...extraProps}
    />);
  }

  renderDateTimeField(key: string, fieldInfo: any, value: any, object: BaseModel, onChange: () => void, onCancel: () => void) {
    let extraProps: any = {};

    return (<div id={`object-editor-datetime-field-${key}`}key={`object-editor-datetime-field-${key}`}>
      <DatePicker
        id={`object-editor-date-field-${key}`}
        key={`object-editor-date-field-${key}`}
        label={fieldInfo.label}
        value={object.getField(key) ? moment(object.getField(key)).toDate() : undefined}
        icon={null}
        closeYearOnSelect={true}
        closeOnEsc={false}
        autoOk={true}
        onChange={(value, e) => {
          let m = moment(value);
          object.apply_value(key, m.format());
          if (onChange) onChange();
        }}
        disabled={fieldInfo.disabled}
        required={fieldInfo.required ? true : false}
        inline
        defaultVisible={true}
        {...extraProps}
      />
      <TimePicker
        id={`object-editor-time-field-${key}`}
        key={`object-editor-time-field-${key}`}
        label={fieldInfo.label}
        value={object.getField(key) ? moment(object.getField(key)).toDate() : undefined}
        icon={null}
        closeOnEsc={false}
        autoOk={true}
        onChange={(value, e) => {
          let m = moment(value);
          object.apply_value(key, m.format());
          if (onChange) onChange();
        }}
        disabled={fieldInfo.disabled}
        required={fieldInfo.required ? true : false}
        inline
        defaultVisible={true}
        {...extraProps}
      />
    </div>)
  }

}

function ObjectField(props: {
  object: BaseModel,
  fieldKey: string;
  fieldInfo: any;
  fieldValue: any;
}) {
  let defaultEditState = false;
  let autocommit = false;
  if (!props.fieldInfo.disabled) {
    
    switch (props.fieldInfo.type) {
      case 'check':
        defaultEditState = true;
        autocommit = true;
        break;
      case 'select':
        defaultEditState = false;
        autocommit = true;
        break;
    }
  }
  const [isEditing, setIsEditing] = React.useState(defaultEditState);
  const originalValue = React.useRef<any>(props.fieldValue)

  const isNewObject = !props.object.id;

  const commitChange = () => {
    if (props.object.getField(props.fieldKey) !== originalValue.current) {
      console.log('saving changes', props.fieldKey, originalValue.current);
      const data: any = {};
      data[props.fieldKey] = props.object.getField(props.fieldKey);
      if (isNewObject) console.log('new object: skipping field update')
      else props.object.put(data);
    }
    else {
      console.log('no changes detected');
    }
  }

  if (isEditing) {
    return (
      <ObjectEditorField
        object={props.object}
        fieldKey={props.fieldKey}
        fieldInfo={props.fieldInfo}
        fieldValue={props.fieldValue}
        defaultEditState={defaultEditState}
        onCancel={() => {
          props.object.apply_value(props.fieldKey, originalValue.current)
          if (!defaultEditState) setIsEditing(!isEditing);
        }}
        onCommit={() => {
          console.log('commit', props.fieldKey)
          if (!defaultEditState) setIsEditing(!isEditing);
          commitChange();
        }}
        onChange={() => {
          if (autocommit) commitChange();
          if (!defaultEditState) setIsEditing(false);
        }}
      />
    );
  }
  return (
    <ObjectViewerField
      object={props.object}
      fieldKey={props.fieldKey}
      fieldInfo={props.fieldInfo}
      fieldValue={props.fieldValue}
      onClick={(e) => {
        e.stopPropagation();
        console.log('clicked', props.fieldKey, props.fieldValue, props.fieldInfo);
        if (props.fieldInfo.disabled) return;
        originalValue.current = props.object.getField(props.fieldKey);
        setIsEditing(!isEditing);
      }}
      startEdit={() => {
        originalValue.current = props.object.getField(props.fieldKey);
        setIsEditing(true);
      }}
      cancelEdit={() => {
        originalValue.current = props.object.getField(props.fieldKey);
        setIsEditing(true);
      }}
    />
  )
}


function ObjectEditorField(props: {
  object: BaseModel,
  fieldKey: string;
  fieldInfo: any;
  fieldValue: any;
  defaultEditState?: boolean;
  onCancel: () => void;
  onCommit: () => void;
  onChange: () => void;
}) {

  const containerRef = React.useRef<HTMLLIElement|null>(null);

  const key = props.fieldKey;
  const info = props.fieldInfo;
  const value = props.fieldValue
  let processedValue: any = props.fieldValue;
  let width = info.width ? info.width : 12;

  if (info.widthView) {
    width = info.widthView;
  }

  let className = `md-list-item md-cell--${width}`;

  let enableClickPortal = true;
  
  switch (props.fieldInfo.type) {
    case 'select':
    case 'date':
    case 'time':
    case 'datetime':
    case 'model':
    case 'models':
      enableClickPortal = false;
      break;
    case 'text':
      if (props.fieldInfo.multiline) {
        enableClickPortal = false;
      }
      break;
    case 'autocomplete':
      enableClickPortal = false;
      break;
  }

  const keyUpHandler = (e: KeyboardEvent) => {
    // console.log('key', e.key, e.isComposing, key);
    if (e.key === 'Escape') {
      e.stopImmediatePropagation();
      return props.onCancel();
    }
    // if (e.key === 'Tab') {
    //   e.stopImmediatePropagation();
    //   return props.onCommit();
    // }
    if ((e.key === 'Enter') && (!info.multiline)) {
      e.stopImmediatePropagation();
      return props.onCommit();
    }
  }

  const portalClickHandler = (e: Event) => {
    props.onCommit()
  }

  const propagationStopper = (e: Event) => {
    e.stopImmediatePropagation()
  }

  React.useEffect(() => {
    if (props.defaultEditState) return;
    if ((window as any)._autoupdate_field_closer) {
      (window as any)._autoupdate_field_closer();
    }
    window.document.body.addEventListener('keyup', keyUpHandler);
    if (enableClickPortal) {
        window.document.body.addEventListener('click', portalClickHandler);
        if (containerRef.current) containerRef.current.addEventListener('click', propagationStopper);
    }

    (window as any)._autoupdate_field_closer = () => {
      console.log('close', props.fieldKey);
      window.document.body.removeEventListener('keyup', keyUpHandler);
      if (enableClickPortal) window.document.body.removeEventListener('click', portalClickHandler);
      props.onCommit();
      (window as any)._autoupdate_field_closer = undefined;
    };
    (window as any)._autoupdate_field_closer.owner = props.fieldKey;
    console.log('register', props.fieldKey);
    return () => {
      console.log('destroy', props.fieldKey);
      window.document.body.removeEventListener('keyup', keyUpHandler);
      window.document.body.removeEventListener('click', portalClickHandler);
      if ((window as any)._autoupdate_field_closer && (window as any)._autoupdate_field_closer.owner == props.fieldKey) {
        (window as any)._autoupdate_field_closer = undefined;
      }
    }
  }, []);

  const renderer = new EditorFieldsRenderer();

  let inputType = info.type ? info.type : 'text';
  switch (inputType) {
    case 'number':
      processedValue = renderer.renderNumberField(key, info, value, props.object);
      break;
    case 'text':
      processedValue = renderer.renderTextField(key, info, value, props.object, props.onCommit, props.onCancel);
      if (info.multiline) {
        className = `${className} multiline-text-field`;
      }
      break;
    case 'autocomplete':
      processedValue = renderer.renderAutocompleteField(key, info, value, props.object, props.onCommit, props.onCancel);
      break;
    case 'email':
      processedValue = renderer.renderEmailField(key, info, value, props.object);
      break;
    case 'phone':
      processedValue = renderer.renderPhoneField(key, info, value, props.object);
      break;
    case 'select':
      processedValue = renderer.renderSelectField(key, info, value, props.object, props.onChange);
      break;
    case 'check':
      processedValue = renderer.renderCheckField(key, info, value, props.object, props.onCommit);
      break;
    case 'model':
      processedValue = renderer.renderModelField(key, info, value, props.object, props.onCommit, props.onCancel);
      break;
    case 'models':
      processedValue = renderer.renderModelsField(key, info, value, props.object, props.onCommit, props.onCancel);
      className = `${className} models-field`;
      break;
    case 'datetime':
      processedValue = renderer.renderDateTimeField(key, info, value, props.object, props.onCommit, props.onCancel);
      break;
    case 'date':
      processedValue = renderer.renderDateField(key, info, value, props.object, props.onCommit, props.onCancel);
      break;
    case 'time':
      processedValue = renderer.renderTimeField(key, info, value, props.object, props.onCommit, props.onCancel);
      break;
    // case 'file':
    //   processedValue = renderer.renderFileField(key, info, value);
    //   break;
      default:
        processedValue = `Editing ${key}`;
  }

  return (
    <li
      className={className}
      ref={containerRef}
    >
      <div className="EditorField">
        {processedValue}
      </div>
    </li>
  );
}

function ObjectViewerField(props: {
  object: BaseModel,
  fieldKey: string;
  fieldInfo: any;
  fieldValue: any;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  startEdit: () => void;
  cancelEdit: () => void;
}) {
  const buttonRef = React.useRef<any|null>(null)
  const renderer = new ViewerFieldsRenderer();

  const key = props.fieldKey;
  const info = props.fieldInfo;
  const value = props.fieldValue
  let processedValue: any = props.fieldValue;
  let width = info.width ? info.width : 12;

  const onItemClicked = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    props.onClick(e);

    if (info.type === 'file') {
      window.open(value, '_blank');
    }
  }

  if (info.widthView) {
    width = info.widthView;
  }

  let className = `md-cell--${width}`;

  if (info.autoHeight) {
    className = `${className} viewer-autoheight`;
  }

  let inputType = info.type ? info.type : 'text';
  switch (inputType) {
    case 'number':
      processedValue = renderer.renderNumberField(key, info, value);
      break;
    case 'text':
      processedValue = renderer.renderTextField(key, info, value);
      if (info.multiline) {
        className = `${className} multiline-text-field`;
      }
      break;
    case 'autocomplete':
      processedValue = renderer.renderTextField(key, info, value);
      if (info.multiline) {
        className = `${className} multiline-text-field`;
      }
      break;
    case 'select':
      processedValue = renderer.renderSelectField(key, info, value);
      break;
    case 'check':
      processedValue = renderer.renderCheckField(key, info, value);
      return (
        <ListItem
          key={`object-field-${key}`}
          primaryText={processedValue}
          onClick={(e) => onItemClicked(e)}
          className={className}
         />
      );
      break;
    case 'model':
      processedValue = renderer.renderModelField(key, info, value);
      break;
    case 'models':
      processedValue = renderer.renderModelsField(key, info, value);
      className = `${className} models-field`;
      break;
    case 'datetime':
      processedValue = renderer.renderDateTimeField(key, info, value);
      break;
    case 'date':
      processedValue = renderer.renderDateField(key, info, value);
      break;
    case 'time':
      processedValue = renderer.renderTimeField(key, info, value);
      break;
    case 'email':
      processedValue = renderer.renderEmailField(key, info, value);
      break;
    case 'phone':
      processedValue = renderer.renderPhoneField(key, info, value);
      break;
    case 'file':
      processedValue = renderer.renderFileField(key, info, value);
      break;
  }

  React.useEffect(() => {
    if (!props.fieldInfo.disabled) {
      buttonRef.current._container.children[0].addEventListener('focus', () => {
        console.log('focus', props.fieldKey)
        props.startEdit()
      });
    }
  }, []);

  return (
    <ListItem
      key={`object-field-${key}`}
      primaryText={info.hideLabel ? processedValue : info.label}
      secondaryText={info.hideLabel ? '' : processedValue}
      onClick={(e) => onItemClicked(e)}
      className={className}
      ref={buttonRef}
     />
  );
}

export default ObjectViewerAndEditor;