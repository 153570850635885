import React, { Component } from 'react';
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Link, Route, Redirect } from 'react-router-dom';
import moment from 'moment';
import formatNumber from 'format-number';
import throttle from "lodash.throttle";

import {
  FontIcon,
  List,
  ListItem,
  TextField,
  Button,
  Snackbar,
  Toolbar,
  MenuButton,
  Paper,
  SelectField,
  Checkbox,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  AppState,
  getCollectionByName,
} from '../models';

import {
  BasePage,
} from './BasePage';

import ObjectListItem from '../components/ObjectListItem';
import ObjectPicker from '../components/ObjectPicker';
import TableCollectionView from '../components/TableCollectionView';
import ObjectMiniItem from '../components/ObjectMiniItem';

type ComponentState = any;


abstract class BaseCollectionPage<P, ComponentState> extends BasePage<P, ComponentState> {

  state = {
    redirectTo: '',
    toasts: [],
    models: [],
    viewMode: this.defaultViewMode,
    selectedFilter: this.defaultFilter,
    hiddenColumns: [] as string[],
    customFilters: [],
    sort_by: this.sortBy,
    sort_dir: this.sortDir as 'ASC'|'DESC',
  }

  abstract getCollection(): BaseModelCollection;

  abstract getAppState(): AppState;

  get readOnly() {
    return false;
  }

  get enableSearch() {
    return true;
  }

  get defaultViewMode() {
    return 'list';
  }

  get sortBy() {
    return 'objTitle';
  }

  get sortDir() {
    return 'ASC';
  }

  viewName = 'Collection';
  objectName = 'object';
  store = 'models';

  getObjectName() {
    return this.objectName;
  }

  getViewName() {
    return this.viewName;
  }

  get objectLabel() {
    return this.objectName;
  }

  get collectionFilter(): any | undefined {
    return this.state.selectedFilter.filter;
  }

  get defaultFilter(): {label: string, filter: any} {
    return {
      label: 'All',
      filter: {
        mine: 1
      }
    }
  }

  get filterOptions(): {label: string, filter: any}[] {
    return [
      {
        label: 'All',
        filter: {
          mine: 1
        }
      }
    ];
  }

  get customFilters(): any[] {
    return [];
  }

  get viewModeOptions(): string[] {
    return [
      'list',
      'table'
    ];
  }

  get persistentFilter(): any {
    return {};
  }

  async loadCollection() {
    let collection = this.getCollection();
    let appState = this.getAppState();
    let models;

    appState.showLoading();
    let persistentFilter = this.persistentFilter;
    let finalFilter = {...persistentFilter, ...this.collectionFilter};

    for (let key in finalFilter) {
      let value = finalFilter[key];
      if (value == '[[current_user_id]]') {
        if (appState.userProfile) {
          finalFilter[key] = appState.userProfile.id;
        }
        else {
          delete finalFilter[key];
        }
      }
    }

    try {
      // await collection.fetch();
      collection.sort_by = this.state.sort_by;
      collection.sort_dir = this.state.sort_dir;
      console.log(collection.sort_by, collection.sort_dir)
      models = await collection.query({
        sort: {
          sortFn: collection.sortFn
        }
      }, finalFilter);
      this.setState({models});
    }
    catch (e) {
      this.addToast('Error fetching data! Please try again.');
      console.error(e);
    }
    finally {
      appState.hideLoading();
    }
  }

  _loadCollectionThrottled = throttle(this.loadCollection, 600, {leading: false, trailing: true});

  restored = false;
  componentDidMount() {
    this.restored = this.restoreState();
    window.setTimeout(() => {
      this.loadCollection();
    }, 0);
  }

  restoreState() {
    let appState = this.getAppState();
    let state = appState.getCachedViewStates(this.viewName);

    if (window.localStorage && window.localStorage[`collection-view-${this.objectName}-hidden-columns`]) {
      if (!state) state = {}
      state['hiddenColumns'] = JSON.parse(window.localStorage[`collection-view-${this.objectName}-hidden-columns`]);
    }

    if (state) {
      this.setState(state);
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    let appState = this.getAppState();
    appState.setCachedViewStates(this.viewName, this.state);
  }

  sortModels(sort_by: string, sort_dir: 'ASC'|'DESC') {
    let models = this.state.models;
    let collection = this.getCollection();
    collection.sort_by = sort_by;
    collection.sort_dir = sort_dir;
    let sortedModels = models.sort(collection.sortFn);
    this.setState({models: sortedModels, sort_by, sort_dir});
  }

  onActionButtonClicked = () => {
    this.redirectTo(this.getCollection().getEditURL(0));
  }

  onSearchResultClicked = (id: number, model: BaseModel) => {
    console.log('clicked', model, model.viewURL)
    this.redirectTo(model.viewURL);
  }

  _tableFields?: any[];
  get tableFields() {
    if (!this._tableFields) {
      let fields: any = [
        {
          label: this.getViewName(),
          render: (model: BaseModel) => (<ObjectMiniItem key={`primary-${model.id}`} objectId={(model as any).id} objectName={model.object_name} style={{margin: 0}} />),
        }
      ];

      let collection = this.getCollection();
      let object = new collection.baseClass();
      let replica = toJS(object);
      let items: any[] = [];
      let targets: any[] = [];
      let metas: any[] = [];
      Object.keys(replica).forEach(key => {
        if (object.isHidden(key)) return;

        let info = object.getFieldInfo(key);

        if (info.multiline) return;
        if (info.hideViewer) return;

        if (object.isMeta(key)) {
          metas.push({
            label: info.label,
            render: (model: BaseModel) => {
              return this.renderField(info, key, (model as any)[key]);
            }
          });
          return;
        }

        if ((info.type === 'model') && !info.meta) {
          targets.push({
            key: key,
            info: info,
          });
          return;
        }

        fields.push({
          label: info.label,
          key: key,
          render: (model: BaseModel) => {
            return this.renderField(info, key, (model as any)[key]);
          }
        })
      });

      if (targets.length > 0) {
        fields.push({
          label: 'Target',
          render: (model: BaseModel) => {
            let rendered = targets.map((target: any) => {
              return this.renderField(target.info, target.key, (model as any)[target.key]);
            });
            return (<div key={`table-field-target`}>{rendered}</div>);
          }
        });
      }

      metas.forEach((meta: any) => {
        fields.push(meta);
      })

      if ((fields.length > 1) && (fields[1].key)) fields[0].key = fields[1].key;

      this._tableFields = fields;
    }
    return this._tableFields;
  }

  renderField(info: any, key: string, value: any) {
    if (value === undefined) return (<div key={`table-field-target-${key}`}></div>);
    if (value === null) return (<div key={`table-field-target-${key}`}></div>);

    let processedValue: any = value;
    let className = "";

    switch (info.type) {
      case 'number':
        processedValue = this.renderNumberField(key, info, value);
        break;
      case 'text':
        processedValue = this.renderTextField(key, info, value);
        if (info.multiline) {
          className = `${className} multiline-text-field`;
        }
        break;
      case 'select':
        processedValue = this.renderSelectField(key, info, value);
        break;
      case 'check':
        processedValue = this.renderCheckField(key, info, value);
        break;
      case 'model':
        processedValue = this.renderModelField(key, info, value);
        break;
      case 'models':
        processedValue = this.renderModelsField(key, info, value);
        break;
      case 'datetime':
        processedValue = this.renderDateTimeField(key, info, value);
        break;
      case 'date':
        processedValue = this.renderDateField(key, info, value);
        break;
      case 'time':
        processedValue = this.renderTimeField(key, info, value);
        break;
      case 'email':
        processedValue = this.renderEmailField(key, info, value);
        break;
      case 'phone':
        processedValue = this.renderPhoneField(key, info, value);
        break;
      case 'file':
        processedValue = this.renderFileField(key, info, value);
        break;

    }

    return <div key={`table-field-target-${key}`}>{processedValue}</div>;
  }

  renderNumberField(key: string, fieldInfo: any, value: any) {
    let processedValue: any = value;
    let formatOpt: any = {};

    if (fieldInfo.prefix) {
      formatOpt['prefix'] = fieldInfo.prefix;
    }

    if (fieldInfo.format) {
      processedValue = formatNumber(formatOpt)(value);
    }
    else {
      processedValue = formatNumber(formatOpt)(value, {noSeparator: true});
    }

    return processedValue;
  }

  renderTextField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    return <div style={{minWidth: '120px'}}>{value}</div>;
  }

  renderFileField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    let name = value.split('/').pop();
    return (<a href={value} style={{minWidth: '120px'}} target="_blank">{name ? `${name}` : '-'}</a>);
  }

  renderSelectField(key: string, fieldInfo: any, value: any) {
    if (!value && (value !== 0)) return (<div></div>);
    let label = `${value}`;
    fieldInfo.options.forEach((option: any) => {
      if (option.value === value) {
        label = option.label;
      }
    })
    return <div>{label}</div>;
  }

  renderCheckField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};
    if (value) {
      return (<FontIcon style={{color: 'green'}}>check_circle</FontIcon>);
    }
    else {
      return (<FontIcon style={{color: 'red'}}>clear</FontIcon>);
    }
  }

  renderEmailField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    return <a className="md-tile-text--secondary md-text--secondary" href={`mailto:${value}`}>{value}</a>;
  }

  renderPhoneField(key: string, fieldInfo: any, value: any) {
    return <a className="md-tile-text--secondary md-text--secondary" href={`tel:${value}`}>{value}</a>;
  }

  renderDateTimeField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    let formatted = moment(value).format('MM/DD/YYYY hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div style={{minWidth: '140px'}}>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderTimeField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    let formatted = moment(value).format('hh:mm A');
    let formattedFromNow = moment(value).fromNow();
    return <div style={{minWidth: '120px'}}>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderDateField(key: string, fieldInfo: any, value: any) {
    if (!value) return (<div></div>);
    let formatted = moment(value).format('MM/DD/YYYY');
    let formattedFromNow = moment(value).fromNow();
    return <div style={{minWidth: '120px'}}>{`${formatted} (${formattedFromNow})`}</div>;
  }

  renderModelField(key: string, fieldInfo: any, value: any) {
    return (
    <ObjectMiniItem
      key={key}
      objectId={value}
      objectName={fieldInfo.objectName}
    />
    );
  }

  renderModelsField(key: string, fieldInfo: any, idList: number[]) {
    let items = idList.map((id, i) => this.renderModelField(`${key}-${i}`, fieldInfo, id));
    return <div>{items}</div>;
  }

  onSearchBoxChanged = (value: any, event: any) => {
    let selectedFilter = this.state.selectedFilter;
    selectedFilter.filter.search = value;
    this.setState({selectedFilter});
    this._loadCollectionThrottled();
  }

  addCustomFilter = (filter: any) => {
    let customFilters: any[] = this.state.customFilters;
    if (!customFilters) customFilters = [];
    
    let exist = false;
    customFilters.forEach((f) => {
      if (f.key == filter.key) exist = true;
    })

    if (exist) return;
    customFilters.push(filter);
    this.setState({customFilters});
  }

  removeCustomFilter = (key: string) => {
    let customFilters: any[] = this.state.customFilters;
    if (!customFilters) customFilters = [];
    let newCustomFilters: any[] = []
    customFilters.forEach((f) => {
      if (f.key == key) return;
      newCustomFilters.push(f);
    });

    let selectedFilter = this.state.selectedFilter;
    delete selectedFilter.filter[key];

    this.setState({customFilters: newCustomFilters, selectedFilter});
    this._loadCollectionThrottled();
  }

  setCustomFilterValue = (key: string, id: any) => {
    let selectedFilter = this.state.selectedFilter;
    selectedFilter.filter[key] = id;
    this.setState({selectedFilter});
    this._loadCollectionThrottled();
  }

  removeCustomFilterValue = (key: string) => {
    let selectedFilter = this.state.selectedFilter;
    delete selectedFilter.filter[key];
    this.setState({selectedFilter});
    this._loadCollectionThrottled();
  }

  renderCustomFilter = () => {
    let customFilters: any[] = this.state.customFilters;
    if (!customFilters) customFilters = [];
    return customFilters.map((filter) => {
      let filterField;
      if (filter.type === 'model') filterField = this.renderCustomFilterFieldModel(filter);
      if (filter.type === 'models') filterField = this.renderCustomFilterFieldModel(filter);
      if (filter.type === 'select') filterField = this.renderCustomFilterFieldSelect(filter);
      if (filter.type === 'check') filterField = this.renderCustomFilterFieldCheck(filter);
      if (filter.type === 'text') filterField = this.renderCustomFilterFieldText(filter);
      return (
        <div className="md-cell md-cell--4" style={{position: 'relative'}}>{filterField}</div>
      )
    })
  }

  renderCustomFilterFieldModel = (filter: any) => {
    let selectedFilter = this.state.selectedFilter;
    let objectId = selectedFilter.filter[filter.key];
    let collection = getCollectionByName(filter.collectionName);
    return (
      <>
        <ObjectPicker
          id={`custom-filter-${filter.key}`}
          key={`custom-filter-${filter.key}`}
          label={filter.label}
          collection={collection}
          onAutocomplete={(id: number, model: BaseModel) => {
            this.setCustomFilterValue(filter.key, id);
          }}
          style={{marginRight: '30px'}}
        />
        <Button
          icon
          primary
          style={{
            position: 'absolute',
            top: 20,
            right: 0
          }}
          onClick={() => {
            this.removeCustomFilter(filter.key);
          }}
        >close</Button>
        {objectId &&
          <ObjectMiniItem
            key={`${filter.key}-${objectId}`}
            objectId={objectId}
            objectName={collection.object_name}
            removable={true}
            onClick={() => {
              this.removeCustomFilterValue(filter.key);
            }}
          />
        }
      </>
    );
  }

  renderCustomFilterFieldSelect = (filter: any) => {
    let selectedFilter = this.state.selectedFilter;
    let value = selectedFilter.filter[filter.key];
    return (
      <>
        <SelectField
          id={`custom-filter-${filter.key}`}
          key={`custom-filter-${filter.key}`}
          label={filter.label}
          value={value}
          menuItems={filter.options}
          simplifiedMenu={true}
          block
          onChange={(value) => {
            this.setCustomFilterValue(filter.key, value);
          }}
          style={{marginRight: '30px'}}
        />
        <Button
          icon
          primary
          style={{
            position: 'absolute',
            top: 20,
            right: 0
          }}
          onClick={() => {
            this.removeCustomFilter(filter.key);
          }}
        >close</Button>
      </>
    );
  }

  renderCustomFilterFieldText = (filter: any) => {
    let selectedFilter = this.state.selectedFilter;
    let value = selectedFilter.filter[filter.key];
    return (
      <>
        <TextField
          id={`custom-filter-${filter.key}`}
          key={`custom-filter-${filter.key}`}
          label={filter.label}
          value={value}
          onChange={(value) => {
            this.setCustomFilterValue(filter.key, value);
          }}
          style={{marginRight: '30px'}}
        />
        <Button
          icon
          primary
          style={{
            position: 'absolute',
            top: 20,
            right: 0
          }}
          onClick={() => {
            this.removeCustomFilter(filter.key);
          }}
        >close</Button>
      </>
    );
  }

  renderCustomFilterFieldCheck = (filter: any) => {
    let selectedFilter = this.state.selectedFilter;
    let value = selectedFilter.filter[filter.key];
    return (
      <>
        <Checkbox
          id={`custom-filter-${filter.key}`}
          key={`custom-filter-${filter.key}`}
          label={filter.label}
          name={filter.label}
          value={value}
          checked={value}
          onChange={(value) => {
            this.setCustomFilterValue(filter.key, value);
          }}
        />
        <Button
          icon
          primary
          style={{
            position: 'absolute',
            top: 0,
            right: 0
          }}
          onClick={() => {
            this.removeCustomFilter(filter.key);
          }}
        >close</Button>
      </>
    );
  }

  _tableRef?: TableCollectionView;
  setTableRef = (ref: TableCollectionView) => {
    this._tableRef = ref;
  }

  render() {
    let redirect = this.renderRedirect();

    let collection = this.getCollection();
    let appState = this.getAppState();

    let helpComponent = this.renderHelp();

    let search = ''
    if (this.state.selectedFilter && this.state.selectedFilter.filter && this.state.selectedFilter.filter.search) {
      search = this.state.selectedFilter.filter.search;
    }

    let models: BaseModel[] = this.state.models ? this.state.models : [];
    let emptyMessage = 'Your list is empty. Use the "+" button to a create a new item.'
    if (this.enableSearch && search) {
      emptyMessage = `No result found for "${search}"`;
    }

    let filterMenuOptions: any[] = [];
    let filterMenuText = 'Filter';

    if (this.filterOptions.length > 1) {
      filterMenuOptions = this.filterOptions.map((filter: any) => {
        return {
          primaryText: `Show ${filter.label}`,
          onClick: () => {
            this.setState({selectedFilter: filter});
            window.setTimeout(() => {
              return this.loadCollection();
            }, 100);
          }
        }
      });
  
      filterMenuText = `Show: ${this.state.selectedFilter.label}`;
    }

    this.customFilters.forEach((filter) => {
      let customFilters: any[] = this.state.customFilters;
      if (!customFilters) customFilters = [];

      let exist = false;
      customFilters.forEach((f) => {
        if (f.key == filter.key) exist = true;
      })

      if (exist) return;

      filterMenuOptions.push({
        primaryText: filter.label,
        onClick: () => this.addCustomFilter(filter)
      })
    })

    let tableFields = this.tableFields ? this.tableFields : [];
    let filteredTableFields = tableFields.filter(field => {
      if (this.state.hiddenColumns.includes(field.key)) return false;
      return true;
    });

    return (
      <div className={this.className} style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
        <div className="md-grid md-cell--12">
          <div className="md-cell md-cell--5 md-grid" style={{padding: 0}}>
            {this.enableSearch &&
            <TextField
              id={"search-field"}
              key={"search-field"}
              className="base-collection-search md-cell--12"
              placeholder="Search"
              value={this.state.selectedFilter.filter.search}
              onChange={this.onSearchBoxChanged}
            />
            }
          </div>
          <div className="md-cell md-cell--7 md-cell--right" style={{textAlign: 'right'}}>
            <MenuButton
              id={`collection-${this.objectName}-filter`}
              flat
              primary
              iconChildren="filter_list"
              menuItems={filterMenuOptions}
            >{filterMenuText}</MenuButton>

            <Button
              id={`collection-${this.objectName}-csv`}
              flat
              primary
              iconChildren={'download'}
              onClick={() => {
                let collection = this.getCollection();
                let api = collection.getApi();
                let url = api.baseUrl.slice(0, -1) + '-csv/';

                let persistentFilter = this.persistentFilter;
                let finalFilter = {...persistentFilter, ...this.collectionFilter};

                for (let key in finalFilter) {
                  let value = finalFilter[key];
                  if (value == '[[current_user_id]]') {
                    if (appState.userProfile) {
                      finalFilter[key] = appState.userProfile.id;
                    }
                    else {
                      delete finalFilter[key];
                    }
                  }
                }

                let downloadUrl = url + '?' + new URLSearchParams(finalFilter).toString();
                window.open(downloadUrl, '_blank');
              }}
              tooltipLabel={`Export ${this.objectLabel} data as CSV`}
              tooltipPosition="bottom"
            >CSV</Button>

            {this.viewModeOptions.length > 1 &&
            <MenuButton
              id={`collection-${this.objectName}-view-mode`}
              flat
              primary
              iconChildren={this.state.viewMode === 'list' ? 'view_agenda' : 'view_list' }
              menuItems={this.viewModeOptions.map((viewMode: string) => {
                return {
                  primaryText: viewMode.toUpperCase(),
                  onClick: () => {
                    this.setState({viewMode: viewMode});
                  }
                }
              })}
            >View as: {this.state.viewMode}</MenuButton>}

            {this.state.viewMode === 'table' &&
            <MenuButton
              id={`collection-${this.objectName}-show-columns-${this.state.hiddenColumns.length}`}
              key={`collection-${this.objectName}-show-columns-${this.state.hiddenColumns.length}`}
              flat
              primary
              iconChildren='view_column'
              tooltipLabel="Hide/Show Columns"
              tooltipPosition="bottom"
              menuItems={tableFields.map((field: any) => {
                return {
                  leftIcon: this.state.hiddenColumns.includes(field.key) ? null : <FontIcon>check</FontIcon>,
                  primaryText: field.label,
                  onClick: () => {
                    let hiddenColumns = this.state.hiddenColumns;
                    if (this.state.hiddenColumns.includes(field.key)) {
                      hiddenColumns = this.state.hiddenColumns.filter((column) => {
                        if (column === field.key) return false;
                        return true;
                      });
                      this.setState({
                        hiddenColumns: hiddenColumns
                      });
                    }
                    else {
                      hiddenColumns = [...this.state.hiddenColumns, field.key];
                      this.setState({
                        hiddenColumns: hiddenColumns
                      });
                    }

                    if (window.localStorage) {
                      window.localStorage[`collection-view-${this.objectName}-hidden-columns`] = JSON.stringify(hiddenColumns);
                    }
                  }
                }
              })}
            >Columns</MenuButton>
            }

            {!this.readOnly &&
            <Button
              id={`collection-${this.objectName}-add-new`}
              flat
              primary
              iconChildren={'add'}
              onClick={this.onActionButtonClicked}
              tooltipLabel={`New ${this.objectLabel}`}
              tooltipPosition="bottom"
            >Add {this.objectLabel}</Button>
            }
          </div>
          {this.renderCustomFilter()}
        </div>

          {this.state.viewMode === 'list' &&
          <div style={{position: 'relative', padding: 20, flexGrow: 1, height: 100, overflow: 'auto'}}>
            <List className="md-cell md-cell--12 md-paper md-paper--1" style={{maxWidth: '100%', overflowX: 'hidden'}}>
              {models.map((obj: BaseModel) =>
                <ObjectListItem
                  key={`${obj.object_type}-${obj.id}`}
                  title={obj.getTitle()}
                  subTitle={obj.getSubtitle()}
                  to={obj.viewURL}
                  starred={obj.starred}
                  mine={obj.mine}
                  type={obj.object_type}
                  object={obj}
                />
              )}

              {models.length === 0 &&
                <ListItem primaryText={appState.isLoading() ? 'Loading...' : emptyMessage } />
              }
            </List>
          </div>}

          {this.state.viewMode === 'table' &&

          <Paper zDepth={1} style={{position: 'relative', margin: 20, flexGrow: 1, height: 100}}>
              <TableCollectionView
                id="QuoteReport"
                title="QuoteReport"
                models={models}
                // fields={this.tableFields ? this.tableFields : []}
                fields={filteredTableFields}
                ref={this.setTableRef}
                sort_by={this.state.sort_by}
                sort_dir={this.state.sort_dir}
                onSort={(sort_by, sort_dir) => {
                  this.sortModels(sort_by, sort_dir);
                }}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                sticky
              />
            </Paper>
          }

          {helpComponent}

          {!this.readOnly &&
          <div className="floating-buttons-container">
              <Button
                  floating
                  primary
                  onClick={this.onActionButtonClicked}
                  tooltipLabel={`New ${this.objectLabel}`}
                  tooltipPosition="left"
              >add</Button>
          </div>
          }
        {this.renderToasts()}
        {redirect}
      </div>
    );
  }
}

export {
  BaseCollectionPage,
};