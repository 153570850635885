import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Reminder extends BaseModel {
  object_name: string = 'reminders';
  object_type: string = 'reminder';
  object_meta: any = {};
  @observable reminder_time: string = '';
  @observable user?: number;
  @observable triggered: boolean = false;
  @observable triggered_at?: string = undefined;
  @observable description: string = '';
  @observable attached_to_contact?: number;
  @observable attached_to_task?: number;
  @observable attached_to_note?: number;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    reminder_time: {"type":"datetime","label":"Reminder time","required":true},
    user: {"type":"model","objectName":"users","required":true},
    triggered: {"type":"check","forceHideEditor":true},
    triggered_at: {"type":"datetime","label":"Triggered at","forceHideEditor":true},
    description: {"type":"text","multiline":true},
    attached_to_contact: {"type":"model","objectName":"contacts","label":"Contact","inlineCreate":true},
    attached_to_task: {"type":"model","objectName":"tasks","label":"Task","inlineCreate":true},
    attached_to_note: {"type":"model","objectName":"notes","label":"Note","inlineCreate":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return moment(this.reminder_time).format('LLLL');
    
  }

  getSubtitle() {
    
    let value = this.reminder_time;
 if (this.triggered && this.triggered_at) value = this.triggered_at;
 let formatted = moment(value).format('MM/DD/YYYY');
 return moment(value).fromNow();
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).description) {
      
      
      keywords += (this as any).description;
    }
    
    
    return keywords;
  }
}


class Reminders extends BaseModelCollection {
  object_name: string = 'reminders';
  object_type: string = 'reminder';
  baseClass: any = Reminder;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Reminder>([])

  
  

  async get(id: number): Promise<Reminder> {
    let model: any = this.baseGet(id);
    return (model as Reminder);
  }

  
  

}


let reminders: Reminders;
let getReminders = () => {
    if (!reminders) {
        reminders = new Reminders();
    }

    return reminders;
}

export {
  Reminder,
  Reminders,
  getReminders,
}