import React, { Component } from 'react';
import { observer } from "mobx-react";
import throttle from "lodash.throttle";

import {
  Autocomplete,
  Avatar,
  FontIcon,
  LinearProgress,
} from 'react-md';

import {
  BaseModelCollection, BaseModel, getAppState,
} from '../models';



interface ObjectPickerStandardProps {
  collection: BaseModelCollection
  label?: string;
  placeholder?: string;
  block?: boolean;
  id: string;
  key: string;
  onAutocomplete: (id: any, model: any, event: any) => void;
  error?: boolean;
  errorText?: string;
  className?: string;
  minChar?: number;
  style?: any;
}

@observer
class ObjectPickerStandard extends Component<ObjectPickerStandardProps> {
  state = {
    error: false,
    errorMessage: '',
    data: [],
    loading: false,
  }

  getAppState() {
    return getAppState();
  }

  dataFromCollection(models: BaseModel[]) {
    let map: any = {};
    let data: any[] = [];
    models.forEach(model => {
      if (map[`${model.object_type}-${model.id}`]) return;
      map[`${model.object_type}-${model.id}`] = model.id;
      data.push({
        key: `search-result-${model.object_type}-${model.id}`,
        value: model.id,
        label: model.getSearchKeywords(),
        model: model,
        leftAvatar: <Avatar suffix="white"><FontIcon>{model.icon}</FontIcon></Avatar>,
      });
    });
    return data;
  }

  handleSearch = async (value: string) => {
    if (!value) {
      this.setState({data: []});
      return;
    }

    let minChar = this.props.minChar ? this.props.minChar : 2;

    if (value && (value.length < minChar)) return;

    this.setState({loading: true});
    let data = await this.props.collection.query({
      sort: {
        sortFn: this.props.collection.sortFn
      }
    }, {search: value});
    this.setState({data: this.dataFromCollection(data), loading: false});
  }

  onAutocomplete = (suggestion: any, suggestionIndex: any, matched: any) => {
    // console.log('onAutocomplete', suggestion, suggestionIndex, matched);
    let item: any = matched[suggestionIndex];
    this.props.onAutocomplete(suggestion, item.model, undefined);
  }

  render() {
    let className = 'ObjectPicker';
    let errorText: any = null;
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    if (this.props.error) {
      className = `${className} ObjectPicker--error`;
      
      if (this.props.errorText) {
        errorText = (
          <div className="md-text-field-message-container md-full-width md-text--error">
            <div className="md-text-field-message">{this.props.errorText}</div>
          </div>
        );
      }
    }
    return (
      <div className={className} style={this.props.style}>
        <Autocomplete
          id={`autocomplete-${this.props.id}`}
          key={`key-${this.props.id}`}
          label={this.props.label}
          placeholder={this.props.placeholder}
          data={this.state.data}
          dataLabel={'label'}
          dataValue={'value'}
          onChange={throttle(this.handleSearch, 600, {leading: false, trailing: true})}
          onAutocomplete={this.onAutocomplete}
          clearOnAutocomplete
          block={this.props.block}
        />
        <div className="progress-container">
          {this.state.loading && <LinearProgress id={`autocomplete-loading-${this.props.id}`} />}
        </div>
        {errorText}
      </div>
    );
  }
}

export default ObjectPickerStandard;