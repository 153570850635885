import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { observer } from "mobx-react";

import {
  Badge,
  Button
} from 'react-md';

import { getAppState } from '../models';

let appState = getAppState();

interface NotificationToolbarButtonProps {
  
}

@observer
class NotificationToolbarButton extends Component<NotificationToolbarButtonProps> {
  render() {
    let button = (
      <Button
        className="md-btn--toolbar"
        icon
        tooltipLabel={'Notifications'}
        tooltipPosition={'bottom'}
        component={Link}
        to="/notifications/"
      >notifications</Button>
    );

    
    return (
      <Badge
        id="toolbar-notification-badge"
        className="toolbar-notification-badge"
        secondary
        invisibleOnZero
        badgeId="toolbar-notification-badge"
        badgeContent={appState.unreadNotifications}
      >
        {button}
      </Badge>
    );
  }
}

export default NotificationToolbarButton;