import React from 'react';
import { observer } from "mobx-react";

import {
  BaseModelCollection,
  AppState,
  Task,
  getTasks,
  getAppState,
} from '../models';

import {
  BaseCollectionPage,
} from './BaseCollectionPage';
import { TextField, List, Subheader, ListItem, Button } from "react-md";
import { getApi } from "../api";
import HelpView from "../components/HelpView";


interface TasksProps {
  history?: any;
  match?: any;
  location?: any;
}

type ComponentState = any;



@observer
class Tasks extends BaseCollectionPage<TasksProps, ComponentState> {
  viewName = 'My Tasks';
  objectName = 'task';
  store = 'my_models';

  get objectLabel() {
    return "Task";
  }

  

  
  get sortBy() {
    return 'due_date';
  }
  
  

  

  getCollection(): BaseModelCollection {
    return getTasks();
  }

  getAppState(): AppState {
    return getAppState();
  }

  get customFilters(): any[] {
    let filters: any = []
    
      filters = ["created_by","attached_to_note","attached_to_contact","attached_to_client","attached_to_user","additional_users","priority","completed"]
    

    let customFilters: any[] = [];

    let obj = new Task();
    filters.forEach((key: string) => {
      let field = obj.getFieldInfo(key);
      if (!field) return;

      let filter: any = {
        key: key,
        label: field.label,
        type: field.type,
      };

      if ((field.type == 'model') || (field.type == 'models')) {
        filter['collectionName'] = field.objectName;
      }

      if (field.type == 'select') {
        filter['options'] = field.options;
      }

      customFilters.push(filter);
    })

    return customFilters;
  }

  

  

  

  
  
  get persistentFilter(): any {
    return {
      optional_includes_field: 'additional_users',optional_includes_target: 'attached_to_user',optional_includes_value: '[[current_user_id]]',completed: false
    };
  }
  


}

export default Tasks;