import { observable } from "mobx";
import moment from 'moment';

import { BaseModel, BaseModelCollection } from './Models';


class Activity extends BaseModel {
  object_name: string = 'activities';
  object_type: string = 'activity';
  @observable full_description: string = '';
  @observable action: string = '';
  @observable target_object_name: string = '';
  @observable target_object_type: string = '';
  @observable target_id: number = 0;
  @observable target_name: string = '';
  created_by?: number;
  created_at: string = '';
  modified_by?: number;
  updated_at: string = '';

  getTitle() {
    return this.full_description;
  }

  getSubtitle() {
    if (this.created_at) {
      let formatted = moment(this.created_at).format('MM/DD/YYYY hh:mm A');
      let formattedFromNow = moment(this.created_at).fromNow();
      return `${formatted} (${formattedFromNow})`;
    }

    return this.created_at;
  }

  get viewURL() {
    if (this.target_id) {
      return `/${this.target_object_name}/${this.target_id}/`;
    }
    return `/${this.target_object_name}/`;
  }

}


class Activities extends BaseModelCollection {
  object_name: string = 'activities';
  baseClass: any = Activity;
  sorted: boolean = true;
  sort_by: string = 'created_at';
  sort_dir: string = 'DESC';
  // @observable models: IObservableArray<Activity> = ([] as IObservableArray<Activity>);
  readonly models = observable<Activity>([])

  async get(id: number): Promise<Activity> {
    let model: any = this.baseGet(id);
    return (model as Activity);
  }

}


let activities: Activities;
let getActivities = () => {
    if (!activities) {
        activities = new Activities();
    }

    return activities;
}

export {
  Activity,
  Activities,
  getActivities,
}