import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Group extends BaseModel {
  object_name: string = 'groups';
  object_type: string = 'group';
  object_meta: any = {};
  @observable name: string = '';
  @observable members: number[] = [];
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    name: {"type":"text","required":true},
    members: {"label":"Members","type":"models","objectName":"users","required":true,"objectFilterTool":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return this.name;
    
  }

  getSubtitle() {
    
    return '';
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).name) {
      
      
      keywords += (this as any).name;
    }
    
    
    return keywords;
  }
}


class Groups extends BaseModelCollection {
  object_name: string = 'groups';
  object_type: string = 'group';
  baseClass: any = Group;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Group>([])

  
  

  async get(id: number): Promise<Group> {
    let model: any = this.baseGet(id);
    return (model as Group);
  }

  
  

}


let groups: Groups;
let getGroups = () => {
    if (!groups) {
        groups = new Groups();
    }

    return groups;
}

export {
  Group,
  Groups,
  getGroups,
}