
import { observer } from "mobx-react";

import {
  Reminder,
  Reminders,
  AppState,
  getReminders,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface ReminderEditProps {
  history?: any;
  match?: any;
  location?: any;
  reminder?: Reminder;
}

type ComponentState = any;


@observer
class ReminderEdit extends BaseEditPage<ReminderEditProps, ComponentState> {
  viewName = 'Reminder Edit';
  objectName = 'reminder';

  

  get objectLabel() {
    return "Reminder";
  }

  newObject() {
    const obj = new Reminder();

  

    return obj;
  }

  getCollection(): Reminders {
    return getReminders();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default ReminderEdit;