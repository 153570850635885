import { BaseApi, BaseMockApi, apiEndpoint, loginEndpoint, logoutUrl } from './BaseApi';

import { Activities, MockActivities } from './Activities';
import { Notifications, MockNotifications } from './Notifications';

import { Contacts, MockContacts } from './Contacts';

import { Clients, MockClients } from './Clients';

import { Tasks, MockTasks } from './Tasks';

import { Notes, MockNotes } from './Notes';

import { Files, MockFiles } from './Files';

import { Reminders, MockReminders } from './Reminders';

import { Groups, MockGroups } from './Groups';

import { Users, MockUsers } from './Users';




class Api {
  activities: Activities;
  notifications: Notifications;
  
  contacts: Contacts;
  
  clients: Clients;
  
  tasks: Tasks;
  
  notes: Notes;
  
  files: Files;
  
  reminders: Reminders;
  
  groups: Groups;
  
  users: Users;
  

  constructor() {
    this.activities = new Activities();
    this.notifications = new Notifications();
    
    this.contacts = new Contacts();
    
    this.clients = new Clients();
    
    this.tasks = new Tasks();
    
    this.notes = new Notes();
    
    this.files = new Files();
    
    this.reminders = new Reminders();
    
    this.groups = new Groups();
    
    this.users = new Users();
    
  }
}


class MockApi extends Api {
  activities: MockActivities;
  notifications: MockNotifications;
  
  contacts: MockContacts;
  
  clients: MockClients;
  
  tasks: MockTasks;
  
  notes: MockNotes;
  
  files: MockFiles;
  
  reminders: MockReminders;
  
  groups: MockGroups;
  
  users: MockUsers;
  

  constructor() {
    super();
    this.activities = new MockActivities();
    this.notifications = new MockNotifications();
    
    this.contacts = new MockContacts();
    
    this.clients = new MockClients();
    
    this.tasks = new MockTasks();
    
    this.notes = new MockNotes();
    
    this.files = new MockFiles();
    
    this.reminders = new MockReminders();
    
    this.groups = new MockGroups();
    
    this.users = new MockUsers();
    
  }
}


let apiInstance: Api;

function getApi(): Api {
  if (!apiInstance) {
    apiInstance = new Api();
  }
  return apiInstance;
}


function regenerateApi(mock=false) {
  if (mock) {
    apiInstance = new MockApi();
  }
  else {
    apiInstance = new Api();
  }
}

export {
  getApi,
  regenerateApi,
  BaseApi,
  BaseMockApi,
  apiEndpoint,
  loginEndpoint,
  logoutUrl,
  Users,
  Notifications,
}
