import React from 'react';

import moment from 'moment';

import {
  Tab,
  Tabs,
  TabsContainer,
} from 'react-md';

import {
  getActivities,
} from '../models';

import MiniCollectionView from '../components/MiniCollectionView';
import {BasePage} from './BasePage';

type ComponentProp = any;
type ComponentState = any;

class Activities extends BasePage<ComponentProp, ComponentState> {
  viewName = 'Activities (Last 7 Days)';

  render() {
    return (
      <TabsContainer panelClassName={this.className} colored>
        <Tabs tabId="activities-tab" mobile={false}>
          <Tab label="My Activities">

            <MiniCollectionView
              title="Me"
              collection={getActivities()}
              filter={{
                mine: 1,
                created_at__gte: moment().subtract(1, 'week').format('YYYY-MM-DDT00:00:00'),
              }}
              cellSize={12}
              hideSeeMore={true}
            />

          </Tab>
          <Tab label="Global Activities">
            
            <MiniCollectionView
              title="Everyone"
              collection={getActivities()}
              cellSize={12}
              hideSeeMore={true}
              filter={{
                created_at__gte: moment().subtract(1, 'week').format('YYYY-MM-DDT00:00:00'),
              }}
            />

          </Tab>
        </Tabs>
      </TabsContainer>

    );
  }
}

export default Activities;