
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Files extends BaseApi {
  objectName = 'files'

  
  async save(data: any) {
    let raw;

    let formData = new FormData();
    console.log('saved data', data);
    for ( var key in data ) {
      if (data[key] === undefined) continue;
      if (data[key] === null) continue;
      if (data[key] === "") continue;
      if (data[key] === "null") continue;
      if ((key === "file") && (typeof data[key] === "string")) continue;
      if ((key === "cover_image") && (typeof data[key] === "string")) continue;
      if ((key === "attachment") && (typeof data[key] === "string")) continue;
      if (Array.isArray(data[key])) {
        let dataArray = (data[key] as any[]);
        dataArray.forEach((content) => {
          formData.append(key, content);
        });
        continue;
      }

      formData.append(key, data[key]);
    }

    let url = this.baseUrl;
    let method = 'POST';
    if (data.id) {
      url = `${url}${data.id}/`;
      method = 'PUT';
    }

    let response = await fetch(url, {
      credentials: 'include',
      method: method,
      body: formData,
      headers: {
        "X-CSRFToken": this.csrfToken,
      }
    });

    if ((response.status != 200) && (response.status != 201)) {
      throw new ApiError(response.statusText, response.status, null);
    }
    
    raw = await response.json();
    // console.log(raw);

    if (raw.id) {
      return raw;
    }
    else {
      throw new Error(`Error saving ${this.objectName} data!`);
    }
  }
  

  
}


class MockFiles extends BaseMockApi {
    objectName = 'files'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Files,
  MockFiles,
};
