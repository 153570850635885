
import { observer } from "mobx-react";

import {
  Group,
  Groups,
  AppState,
  getGroups,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface GroupEditProps {
  history?: any;
  match?: any;
  location?: any;
  group?: Group;
}

type ComponentState = any;


@observer
class GroupEdit extends BaseEditPage<GroupEditProps, ComponentState> {
  viewName = 'Group Edit';
  objectName = 'group';

  

  get objectLabel() {
    return "Group";
  }

  newObject() {
    const obj = new Group();

  

    return obj;
  }

  getCollection(): Groups {
    return getGroups();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default GroupEdit;