
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Groups extends BaseApi {
  objectName = 'groups'

  

  
}


class MockGroups extends BaseMockApi {
    objectName = 'groups'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Groups,
  MockGroups,
};
