import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Task extends BaseModel {
  object_name: string = 'tasks';
  object_type: string = 'task';
  object_meta: any = {};
  @observable subject: string = '';
  @observable due_date: string = '';
  @observable reminder_time: string | null = null;
  @observable priority: number = 0;
  @observable description: string = '';
  @observable completed: boolean = false;
  @observable attached_to_contact?: number;
  @observable attached_to_client?: number;
  @observable attached_to_user?: number;
  @observable additional_users: number[] = [];
  @observable attached_to_note?: number;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    subject: {"type":"text","required":true},
    due_date: {"type":"date","required":true},
    reminder_time: {"type":"datetime"},
    priority: {"type":"select","options":[{"label":"Low","value":0},{"label":"Medium","value":1},{"label":"High","value":2}]},
    description: {"type":"text","multiline":true},
    completed: {"type":"check"},
    attached_to_contact: {"type":"model","objectName":"contacts","label":"Contact","inlineCreate":true},
    attached_to_client: {"type":"model","objectName":"clients","label":"Client","inlineCreate":true},
    attached_to_user: {"type":"model","objectName":"users","label":"User"},
    additional_users: {"label":"Additional Users","type":"models","objectName":"users","objectFilterTool":true,"inlineCreate":true},
    attached_to_note: {"type":"model","objectName":"notes","label":"Note","inlineCreate":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  
  get isOverdue() {
    if (moment(this.due_date).diff(moment()) < 0) {
      if (this.completed) return false;
      return true;
    }
    return false;
  }
  

  getTitle() {
    
    return this.subject;
    
  }

  getSubtitle() {
    
    let value = this.due_date;
 if (this.completed) value = this.modified_at;
 let formatted = moment(value).format('MM/DD/YYYY');
 let formattedFromNow = moment(value).fromNow();
 let dateFormatted = `${formatted} (${formattedFromNow})`;
 return this.completed ? `Completed on ${dateFormatted}` : `Due on ${dateFormatted}`;
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).subject) {
      
      
      keywords += (this as any).subject;
    }
    
    
    return keywords;
  }
}


class Tasks extends BaseModelCollection {
  object_name: string = 'tasks';
  object_type: string = 'task';
  baseClass: any = Task;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Task>([])

  
  sort_by: string = 'due_date';
  
  

  async get(id: number): Promise<Task> {
    let model: any = this.baseGet(id);
    return (model as Task);
  }

  
  

}


let tasks: Tasks;
let getTasks = () => {
    if (!tasks) {
        tasks = new Tasks();
    }

    return tasks;
}

export {
  Task,
  Tasks,
  getTasks,
}