
import packageJson from '../../package.json';

class ApiError extends Error {
  status: number;
  errorData: any;
  constructor(message?: string, statusCode?: number, errorData?: any) {
    super(message)
    if (statusCode) {
      this.status = statusCode;
    }
    else {
      this.status = 0;
    }

    if (errorData) {
      this.errorData = errorData;
    }
    else {
      this.errorData = {}
    }
  }
}


abstract class BaseApi {
  apiEndpoint = (process.env.NODE_ENV === 'production') ? packageJson.apiEndpoint : packageJson.apiEndpointDebug;
  objectName = 'objects';
  
  get csrfToken() {
    return (window as any).csrfToken ? (window as any).csrfToken : '';
  }

  get baseUrl() {
    return `${this.apiEndpoint}${this.objectName}/`;
  }

  async save(data: any) {
    let raw: any;

    let formData = new FormData();
    for ( var key in data ) {
        formData.append(key, data[key]);
    }

    let url = this.baseUrl;
    let method = 'POST';
    if (data.id) {
      url = `${url}${data.id}/`;
      method = 'PUT';
    }

    let response;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        // body: formData,
        body: JSON.stringify(data),
        headers: {
          "X-CSRFToken": this.csrfToken,
          'Content-Type': 'application/json',
        }
      });
    }
    catch (error) {
      throw new ApiError((error as Error).message, 1200, null);
    }

    if (response.status == 400) {
      let statusText = response.statusText;
      raw = await response.json();
      Object.keys(raw).forEach((key, i) => {
        statusText = `${statusText} - [${key}] ${raw[key]}`
      });

      throw new ApiError(statusText, response.status, null);
    }

    if ((response.status != 200) && (response.status != 201)) {
      throw new ApiError(response.statusText, response.status, null);
    }


    raw = await response.json();
    // console.log(raw);
  

    if (raw.id) {
      return raw;
    }
    else {
      throw new ApiError(`Error saving ${this.objectName} data!`, 1500, null);
    }
  }

  async put(data: any) {
    let raw: any;

    let formData = new FormData();
    for ( var key in data ) {
        formData.append(key, data[key]);
    }

    let url = this.baseUrl;
    let method = 'PUT';
    if (data.id) {
      url = `${url}${data.id}/`;
    }

    let response;

    try {
      response = await fetch(url, {
        credentials: 'include',
        method: method,
        // body: formData,
        body: JSON.stringify(data),
        headers: {
          "X-CSRFToken": this.csrfToken,
          'Content-Type': 'application/json',
        }
      });
    }
    catch (error) {
      throw new ApiError((error as any).message, 1200, null);
    }

    if (response.status == 400) {
      let statusText = response.statusText;
      raw = await response.json();
      Object.keys(raw).forEach((key, i) => {
        statusText = `${statusText} - [${key}] ${raw[key]}`
      })

      throw new ApiError(statusText, response.status, null);
    }

    if ((response.status != 200) && (response.status != 201)) {
      throw new ApiError(response.statusText, response.status, null);
    }


    raw = await response.json();
    // console.log(raw);
  

    if (raw.id) {
      return raw;
    }
    else {
      throw new ApiError(`Error saving ${this.objectName} data!`, 1500, null);
    }
  }

  async star(id?: number, status?: boolean) {
    let raw;

    if (!id) throw new Error('No id specified');

    let formData = new FormData();
    formData.append('status', status ? 'true' : 'false');

    let url = `${this.baseUrl}${id}/star/`;

    let response = await fetch(url, {
      credentials: 'include',
      method: 'POST',
      // body: formData,
      body: JSON.stringify({status: status}),
      headers: {
        "X-CSRFToken": this.csrfToken,
        'Content-Type': 'application/json',
      }
    });

    if (response.status != 200) {
      throw new ApiError(response.statusText, response.status, null);
    }

    raw = await response.json();

    return raw;
  }

  async delete(id?: number) {
    let raw;

    if (!id) throw new Error('No id specified');

    let url = `${this.baseUrl}${id}/`;

    let response = await fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        "X-CSRFToken": this.csrfToken,
      }
    });

    if ((response.status != 200) && (response.status != 204)) {
      throw new ApiError(response.statusText, response.status, null);
    }

    raw = await response.text();
    // console.log(raw);
    
    // return raw;
  }

  async doFetch(params?: any): Promise<object[]> {
    let url = new URL(this.baseUrl);

    if (params) {
      (url.search as any) = new URLSearchParams(params);
    }

    // console.log('fetch', params, url.toString());

    let raw;
    let response = await fetch(url.toString(), {
      credentials: 'include'  
    });

    if (response.status != 200) {
      throw new ApiError(response.statusText, response.status, null);
    }

    raw = await response.json();
    this.isFetching = false;

    // console.log(raw);
    return raw;
  }

  isFetching: boolean = false;
  fetchPromise?: Promise<object[]>;

  fetch(params?: any): Promise<object[]> {
    return this.doFetch(params);
  }

  async getDistinctValues(field: string): Promise<any[]> {
    let raw;
    let url = `${this.baseUrl}distinct_values/?field=${field}`;

    try {
      let fetchPromise = fetch(url, {
        credentials: 'include'  
      });

      let response = await fetchPromise;      

      raw = await response.json();
      console.log(raw);
    }
    catch (e) {
      console.error(e);
      throw new Error(`Error fetching ${field} data!`);
    }

    if (raw.success) {
      return raw.values;
    }
    else {
      throw new Error(`Error fetching ${field} data!`);
    }
  }

  pendingGet: any = {}

  async get(id: number): Promise<object> {
    // if multiple get() run at the same time
    // no need to open multiple connections to the server
    if (this.pendingGet[id]) {
      let p = this.pendingGet[id];
      let value = await p;
      return value;
    }
    else {
      this.pendingGet[id] = this.rawGet(id);
      let value = await this.pendingGet[id];
      delete this.pendingGet[id];
      return value;
    }
  }

  async rawGet(id: number): Promise<object> {
    let raw;
    let url = `${this.baseUrl}${id}/`;

    try {
      let fetchPromise = fetch(url, {
        credentials: 'include'  
      });

      let response = await fetchPromise;      

      raw = await response.json();
      // console.log(raw);
    }
    catch (e) {
      console.error(e);
      throw new Error(`Error fetching ${this.objectName}=${id} data!`);
    }

    if (raw.id) {
      return raw;
    }
    else {
      throw new Error(`Error fetching ${this.objectName}=${id} data!`);
    }
  }
}


class BaseMockApi extends BaseApi {
  constructor() {
    super();
  }

  // mock api
  arrayPromiseTimeout(func: any, timeout: number): Promise<object[]> {
    return new Promise<object[]>((resolve, reject) => {
      setTimeout(() => {
        resolve(func());
      }, timeout)
    });
  }


  incrementalId = 1;
  mockData: any[] = [];

  async save(data: any) {
    let tmp = await this.arrayPromiseTimeout(() => data, 500);

    if (data.id) {
      let orig: any = await this.get(data.id);
      Object.keys(data).forEach((key, i) => {
        orig[key] = data[key];
      });
    }
    else {
      data.id = this.incrementalId;
      data.mine = true;
      this.mockData.push(data);
      this.incrementalId += 1;
    }
  
    return data.id;
  }

  async star(id?: number, status?: boolean) {
    if (!id) throw new Error('No id specified');

    let data: any = await this.get(id);
    if (data) {
      data.starred = status;
    }

    return;
  }


  async delete(id?: number) {
    if (!id) throw new Error('No id specified');

    let tmp = await this.arrayPromiseTimeout(() => null, 500);
  
    let index = -1;
    this.mockData.forEach((mockObject, i) => {
      if (mockObject.id === id) index = i;
    });
    if (index > -1) {
       this.mockData.splice(index, 1);
    }
  
    return;
  }

  async fetch() {
    let tmp = await this.arrayPromiseTimeout(() => this.mockData, 500);
    return tmp;
  }

  async get(id: number): Promise<object> {
    let mockObjects = await this.fetch();

    for (var i = mockObjects.length - 1; i >= 0; i--) {
      let data: any = mockObjects[i];
      if (data['id'] === id) {
        return data;
      }
    }

    throw new Error('Not Found!')
  }
}

let apiEndpoint = (process.env.NODE_ENV === 'production') ? packageJson.apiEndpoint : packageJson.apiEndpointDebug;
let loginEndpoint = (process.env.NODE_ENV === 'production') ? packageJson.loginEndpoint : packageJson.loginEndpointDebug;
let logoutUrl = (process.env.NODE_ENV === 'production') ? packageJson.logoutUrl : packageJson.logoutUrlDebug;

export {
  BaseApi,
  BaseMockApi,
  ApiError,
  apiEndpoint,
  loginEndpoint,
  logoutUrl,
};