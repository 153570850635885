import React, { Component } from 'react';
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from 'moment';
import queryString from 'query-string';

import {
  DatePicker,
  List,
  SelectField,
  TextField,
  FileInput,
  TimePicker,
  Checkbox,
  Button,
  Autocomplete,
} from 'react-md';

import { BaseModel, getCollectionByName, MergedModelCollection, BaseModelCollection } from '../models';
import ObjectMiniItem from './ObjectMiniItem';
import ObjectPicker from './ObjectPicker';
import { object, instanceOf } from 'prop-types';
import InlineObjectEditForm from './InlineObjectEditForm';
import MultiObjectFilterForm from './MultiObjectFilterForm';


interface ObjectEditorProps {
  object: BaseModel;
  disabled?: boolean;
  error?: boolean;
  errorFields?: any;
  onChange?: any;
}


@observer
class ObjectEditor extends Component<ObjectEditorProps> {
  async componentWillMount() {
    this.assignInitialValue(this.props);
  }

  assignInitialValue(props: ObjectEditorProps) {
    // setup initial id/ref value (if any)
    let replica = toJS((props.object as any).model);
    Object.keys(replica).forEach((key, i) => {
      let value = this.getInitialIdValue(key);
      let info = (this.props.object as any).model.getFieldInfo(key);

      // models field accept array of id, while model field accept single numerical id
      if ((value !== undefined) && info && (info.type === 'models')) {
        if ((props.object as any)[key] && (props.object as any)[key].length === 0) {
          (props.object as any)[key] = [value]; 
        }
      }
      else if ((value !== undefined) && (!(props.object as any)[key])) {
        (props.object as any)[key] = value;
      }
    });
  }

  isHidden(key: string) {
    return (this.props.object as any).model.isHidden(key);
  }

  getLabel(key: string) {
    return (this.props.object as any).model.getLabel(key);
  }

  getInitialIdValue(key: string) {
    let qdata = queryString.parse(window.location.search);

    let relValue = qdata[`_rel_${key}`];
    if (relValue && (typeof relValue === 'string') && parseInt(relValue)) {
      // console.log('getInitialValue', key, relValue, parseInt(relValue));
      return parseInt(relValue);
    }
    return undefined;
  }

  getValue(key: string) {
    return (this.props.object as any)[key];
  }

  isError(key: string) {
    if (this.props.errorFields && this.props.errorFields[key]) {
      return true;
    }
    return false;
  }

  getErrorMessage(key: string) {
    let fieldInfo = (this.props.object as any).model.object_fields[key];
    let errorMessage = fieldInfo && fieldInfo.errorMessage ? fieldInfo.errorMessage : 'This field is required';
    if (this.isError(key)) {
      return errorMessage
    }
    return '';
  }

  onFieldChange = (key: string, type: string) => {
    return (value: any, event: any) => {
      // console.log('onFieldChange', type, key, value);
      switch (type) {
        case 'date': {
          let newValue = moment(event).format('YYYY-MM-DD');
          console.log('transformed to', newValue);
          (this.props.object as any)[key] = newValue;
        }
        break;
        case 'datetime': {
          let newValue = moment(event).format();
          console.log('transformed to', newValue);
          (this.props.object as any)[key] = newValue;
        }
        break;
        case 'time': {
          // second parameter (event) is actually raw value returned by the time picker
          let newValue = moment(event).format();
          console.log('transformed to', newValue);
          (this.props.object as any)[key] = newValue;
        }
        break;
      
        default:
          (this.props.object as any)[key] = value;
          break;
      }

      if (this.props.onChange) {
        this.props.onChange(key, value, event);
      }
    }
  }

  renderTextField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    if (fieldInfo.multiline) {
      extraProps.rows = 3;
    }

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block`;

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      label={fieldInfo.label}
      value={value}
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      required={fieldInfo.required ? true : false}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderAutocompleteField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    if (fieldInfo.multiline) {
      extraProps.rows = 3;
    }

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block`;

    let options: string[] = [];
    if (fieldInfo.source = 'distinct_value') {
      let collection = getCollectionByName(fieldInfo.objectName);
      options = collection.getDistinctValues(key);
    }

    return (
      <Autocomplete
        id={`object-editor-field-${key}`}
        key={`object-editor-field-${key}`}
        className={className}
        label={fieldInfo.label}
        value={value}
        data={options}
        onChange={this.onFieldChange(key, fieldInfo.type)}
        onAutocomplete={(value: any) => this.onFieldChange(key, fieldInfo.type)(value, null)}
      />
    );
  }

  renderEmailField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block`;

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      type="email"
      label={fieldInfo.label}
      value={value}
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      required={fieldInfo.required ? true : false}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderPhoneField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block`;

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      type="tel"
      label={fieldInfo.label}
      value={value}
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      required={fieldInfo.required ? true : false}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderNumberField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {
      type: "number",
      step: 1,
    };

    if (fieldInfo.min !== undefined) {
      extraProps.min = fieldInfo.min;
    }

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block`;

    return (<TextField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      label={fieldInfo.label}
      value={value}
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      required={fieldInfo.required ? true : false}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderSelectField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block md-text-field-container`;

    return (<SelectField
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      label={fieldInfo.label}
      value={value}
      menuItems={fieldInfo.options}
      simplifiedMenu={true}
      sameWidth
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderFileField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    if (fieldInfo.multiline) {
      extraProps.rows = 3;
    }

    if (typeof value === "string") {
      value = undefined;
    }

    return (
      <div id={`object-editor-file-field-${key}`}key={`object-editor-file-field-${key}`} className="FileInputContainer">
        <TextField
          id={`object-editor-file-field-chosen-${key}`}
          key={`object-editor-file-field-chosen-${key}`}
          label={fieldInfo.label}
          value={value ? (value as File).name : ''}
          disabled={this.props.disabled}
          required={fieldInfo.required ? true : false}
          error={this.isError(key)}
          errorText={this.getErrorMessage(key)}
          {...extraProps}
        />
        <FileInput
          id={`object-editor-file-field-select-${key}`}
          key={`object-editor-file-field-select-${key}`}
          flat
          iconBefore
          label={fieldInfo.label}
          accept={fieldInfo.accept ? fieldInfo.accept : undefined}
          onChange={(file: File|File[]|null) => {
            this.onFieldChange(key, fieldInfo.type)(file, undefined);
          }}
          disabled={this.props.disabled}
        />
      </div>
    );
  }

  renderCheckField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};
    return (<Checkbox
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      name={fieldInfo.label}
      value={value}
      checked={value}
      onChange={this.onFieldChange(key, fieldInfo.type)}
      disabled={this.props.disabled}
      {...extraProps}
    />);
  }

  renderDateField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    let width = fieldInfo.width ? fieldInfo.width : 12;
    let className = `md-cell--${width} inline-block md-text-field-container`;

    return (<DatePicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      className={className}
      label={fieldInfo.label}
      value={value ? moment(value).toDate() : undefined}
      icon={null}
      closeYearOnSelect={true}
      autoOk={true}
      required={fieldInfo.required ? true : false}
      onChange={this.onFieldChange(key, 'date')}
      disabled={this.props.disabled}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderTimeField(key: string, fieldInfo: any, value: any) {
    let extraProps: any = {};

    return (<TimePicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={value ? moment(value).toDate() : undefined}
      icon={null}
      required={fieldInfo.required ? true : false}
      onChange={this.onFieldChange(key, 'time')}
      disabled={this.props.disabled}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      {...extraProps}
    />);
  }

  renderDateTimeField(key: string, fieldInfo: any, value: any) {
    return (<div id={`object-editor-datetime-field-${key}`}key={`object-editor-datetime-field-${key}`}>
      <DatePicker
        id={`object-editor-date-field-${key}`}
        key={`object-editor-date-field-${key}`}
        label={`${fieldInfo.label} (date)`}
        value={value ? moment(value).toDate() : undefined}
        icon={null}
        autoOk={true}
        required={fieldInfo.required ? true : false}
        onChange={this.onFieldChange(key, 'datetime')}
        disabled={this.props.disabled}
      />
      <TimePicker
        id={`object-editor-time-field-${key}`}
        key={`object-editor-time-field-${key}`}
        label={`${fieldInfo.label} (time)`}
        value={value ? moment(value).toDate() : undefined}
        icon={null}
        required={fieldInfo.required ? true : false}
        onChange={this.onFieldChange(key, 'datetime')}
        disabled={this.props.disabled}
      />
    </div>)
  }

  renderModelField(key: string, fieldInfo: any, value: any, collection?: BaseModelCollection, onClear?: any, onChange?: any, getId?: any) {
    let extra: any;
    let createInline: any;

    if (!collection) {
      collection = getCollectionByName(fieldInfo.objectName);
    }

    if (!onClear) {
      onClear = () => (this.onFieldChange(key, fieldInfo.type))(null, undefined);
    }

    if (!onChange) {
      onChange = this.onFieldChange(key, fieldInfo.type);
    }

    if (!getId) {
      getId = (object: any) => object;
    }

    if (value) {
      let objectId = getId(value);
      extra = (<div style={{paddingLeft: '20px'}}>
        <ObjectMiniItem
          key={`${key}-${objectId}`}
          objectId={objectId}
          objectName={collection.object_name}
          removable={true}
          onClick={onClear}
        />
      </div>);
    }

    if (fieldInfo.inlineCreate) {
      let newObject = new collection.baseClass();
      createInline = (
        <InlineObjectEditForm
          isNew={true}
          object={newObject}
          collection={collection}
          objectLabel={newObject.object_type}
          onSaved={(model: BaseModel) => {
            console.log(model);

            onChange(model.id, model);
          }}
        />
      );
    }

    return (<div id={`object-editor-field-container-${key}`} key={`object-editor-field-container-${key}`} className="ModelFieldContainer">
    <ObjectPicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      value={value}
      placeholder={`type to search ${collection.object_name}...`}
      collection={collection}
      onAutocomplete={onChange}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
    />
    {extra}
    {createInline}
    </div>);
  }

  renderModelsField(key: string, fieldInfo: any, values: any) {
    let extra: any;
    let createInline: any;
    let filterTool: any;

    if (values) {
      let items = (values as number[]).map((value: number, i: number) => {
        return (<ObjectMiniItem
          key={`${key}-${i}-${value}`}
          objectId={value}
          objectName={fieldInfo.objectName}
          removable={true}
          onClick={() => (this.onFieldChange(key, fieldInfo.type))((values as number[]).filter(svalue => svalue !== value), undefined)}
        />);
      });
      extra = (<div style={{paddingLeft: '20px'}}>{items}</div>);
    }
    let collection = getCollectionByName(fieldInfo.objectName);
    
    let onChange = (id?: number) => {
      let fn = (this.onFieldChange(key, fieldInfo.type));
        let newValues = [...values];

        let exist = false;
        newValues.forEach(val => {
          if (val === id) exist = true;
        })
        if (!exist) {
          newValues.push(id);
          fn(newValues, undefined);
        }
    }

    if (fieldInfo.inlineCreate) {
      let newObject = new collection.baseClass();
      createInline = (
        <InlineObjectEditForm
          isNew={true}
          object={newObject}
          collection={collection}
          objectLabel={newObject.object_type}
          onSaved={(model: BaseModel) => {
            console.log(model);

            onChange(model.id);
          }}
        />
      );
    }

    if (fieldInfo.objectFilterTool) {
      let newObject = new collection.baseClass();
      filterTool = (
        <MultiObjectFilterForm
          object={newObject}
          collection={collection}
          objectLabel={newObject.object_type}
          extraFields={fieldInfo.objectFilterToolExtraFields ? fieldInfo.objectFilterToolExtraFields : null}
          onSelect={(models: BaseModel[]) => {
            let fn = (this.onFieldChange(key, fieldInfo.type));
            let newValues = [...values];
            models.forEach((model: BaseModel) => {
              // onChange(model.id);

              let exist = false;
              newValues.forEach(val => {
                if (val === model.id) exist = true;
              })
              if (!exist) {
                newValues.push(model.id);
              }
            });
            fn(newValues, undefined);
          }}
        />
      );
    }

    return (<div id={`object-editor-field-container-${key}`} key={`object-editor-field-container-${key}`} className="ModelsFieldContainer">
    <ObjectPicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={fieldInfo.label}
      placeholder={`type to search ${collection.getLabels()[1]}...`}
      collection={collection}
      onAutocomplete={(id: number) => {
        onChange(id);
      }}
      error={this.isError(key)}
      errorText={this.getErrorMessage(key)}
      style={{
        marginRight: fieldInfo.objectFilterTool ? 50 : 0,
      }}
    />
    {extra}
    {createInline}
    {filterTool}
    </div>);
  }

  renderCombinedModelField(key: string, fieldInfo: any, valueObj: any) {
    
    if (!valueObj) {
      // if value is empty, see if any of the subfield has value assigned
      fieldInfo.subFields.forEach((fieldKey: string) => {
        let fieldValue = (this.props.object as any)[fieldKey];
        if (fieldValue) {
          valueObj = {
            value: fieldValue,
            objectName: this.props.object.object_fields[fieldKey].objectName,
            fieldKey: fieldKey,
          }
        }
      });
    }

    let onClear = () => {
      // clear all subfields value
      this.onFieldChange(key, fieldInfo.type)(null, undefined)
      fieldInfo.subFields.forEach((fieldKey: string) => {
        let fieldInfo = this.props.object.object_fields[fieldKey];
        this.onFieldChange(fieldKey, fieldInfo.type)(null, undefined);
      });
    };

    let onChange = (value: any, model: any) => {
      let modelFieldKey = fieldKeysByObjectName[model.object_name];
      let modelFieldInfo = this.props.object.object_fields[modelFieldKey];

      let processedValue = {
        value: value,
        objectName: model.object_name,
        valueFieldKey: modelFieldKey,
      }

      // clear all subfields value
      fieldInfo.subFields.forEach((fieldKey: string) => {
        let fieldInfo = this.props.object.object_fields[fieldKey];
        this.onFieldChange(fieldKey, fieldInfo.type)(null, undefined);
      });

      // set value for combined field and appropriate subfield 
      this.onFieldChange(key, fieldInfo.type)(processedValue, model);
      this.onFieldChange(modelFieldKey, modelFieldInfo.type)(value, model);
    };
    
    let getId = (object: any) => object.value;

    let fieldKeysByObjectName: any = {}

    let collections = fieldInfo.subFields.map((fieldKey: string) => {
      let info = this.props.object.object_fields[fieldKey];
      fieldKeysByObjectName[info.objectName] = fieldKey;
      return getCollectionByName(info.objectName);
    })

    let collection = new MergedModelCollection(collections);
    collection.object_name = valueObj && valueObj.objectName ? valueObj.objectName : 'object';

    return this.renderModelField(key, fieldInfo, valueObj, collection, onClear, onChange, getId)
  }

  renderCombinedField(key: string, fieldInfo: any, valueObj: any) {

    switch (fieldInfo.subType) {
      case 'model':
      return this.renderCombinedModelField(key, fieldInfo, valueObj);
    }

    return this.renderTextField(key, fieldInfo, valueObj);
  }

  renderField(key: string) {
    let info = (this.props.object as any).model.getFieldInfo(key);
    let value = this.getValue(key);
    if (info.meta) return;
    // if (info.hideEditor && !value) return;
    if (info.hideEditor) {
      if (!value) return;
      if (info.type == 'models' && value.length == 0) return;
    }

    if (info.forceHideEditor) return;

    switch (info.type) {
      case 'number':
        return this.renderNumberField(key, info, value);
        break;
      case 'text':
        return this.renderTextField(key, info, value);
        break;
      case 'autocomplete':
        return this.renderAutocompleteField(key, info, value);
        break;
      case 'file':
        return this.renderFileField(key, info, value);
        break;
      case 'check':
        return this.renderCheckField(key, info, value);
        break;
      case 'select':
        return this.renderSelectField(key, info, value);
        break;
      case 'model':
        return this.renderModelField(key, info, value);
        break;
      case 'models':
        return this.renderModelsField(key, info, value);
        break;
      case 'combined':
        return this.renderCombinedField(key, info, value);
        break;
      case 'datetime':
        return this.renderDateTimeField(key, info, value);
        break;
      case 'date':
        return this.renderDateField(key, info, value);
        break;
      case 'time':
        return this.renderTimeField(key, info, value);
        break;
      case 'email':
        return this.renderEmailField(key, info, value);
        break;
      case 'phone':
        return this.renderPhoneField(key, info, value);
        break;
    }

    return this.renderTextField(key, info, value);
  }

  render() {
    let replica = toJS((this.props.object as any).model);
    return (
      <List className="ObjectEditor">
        {Object.keys(replica).map((key, i) => {
            if (!this.isHidden(key)) return this.renderField(key)
          })}
        
      </List>
    );
  }
}

export default ObjectEditor;