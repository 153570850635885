
import { BaseApi, BaseMockApi, ApiError } from './BaseApi';

class Notes extends BaseApi {
  objectName = 'notes'

  

  
}


class MockNotes extends BaseMockApi {
    objectName = 'notes'
    incrementalId = 1;
    mockData = [];

  
}


export {
  Notes,
  MockNotes,
};
