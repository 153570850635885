import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';

import {
  Avatar,
  Button,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
  Tab,
  Tabs,
  TabsContainer,
  TextField,
} from 'react-md';

import {
  Notification,
  getNotifications,
  getAppState,
} from '../models';

import MiniCollectionView from '../components/MiniCollectionView';
import {BasePage} from './BasePage';

type ComponentProp = any;
type ComponentState = any;

class Notifications extends BasePage<ComponentProp, ComponentState> {
  viewName = 'Notifications';
  unreadRef?: MiniCollectionView | null;
  readRef?: MiniCollectionView | null;

  unreadActions = [
    {
      label: 'Mark all as read',
      onClick: async () => {
        console.log('mark all notifications as read')
        let notifications = getNotifications();
        await notifications.markAllRead();
        if (this.readRef) this.readRef.loadData();
        if (this.unreadRef) this.unreadRef.loadData();

        let appState = getAppState();
        appState.unreadNotifications = 0;
      }
    }
  ];

  markNotificationAsRead = async (notification: Notification) => {
    if (!notification.read) {
      notification.read = true;
      await notification.save();

      let appState = getAppState();
      window.setTimeout(() => appState.checkLogin(), 0);
    }
  }

  onItemOpened = (notification: Notification) => {
    if (!notification.read) {
      this.markNotificationAsRead(notification);
    }

    console.log(notification, notification.target_object_type);
    if (notification.target_object_type == 'nonetype') {
      console.log('notification target is invalid');
      return false;
    }

    return true;
  }

  render() {
    return (
      <TabsContainer panelClassName={this.className} colored>
        <Tabs tabId="activities-tab" mobile={false}>
          <Tab label="Unread Notifications">

            <MiniCollectionView
              title="Unread"
              collection={getNotifications()}
              filter={{
                read: 0
              }}
              cellSize={12}
              hideSeeMore={true}
              actions={this.unreadActions}
              onItemOpened={this.onItemOpened}
              ref={(ref) => {
                this.readRef = ref;
              }}
            />

          </Tab>
          <Tab label="Read Notifications">
            
            <MiniCollectionView
              title="Read"
              collection={getNotifications()}
              filter={{
                read: 1
              }}
              cellSize={12}
              hideSeeMore={true}
              onItemOpened={this.onItemOpened}
              ref={(ref) => {
                this.unreadRef = ref;
              }}
            />

          </Tab>
        </Tabs>
      </TabsContainer>

    );
  }
}

export default Notifications;