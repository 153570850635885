import React from 'react';
import { observer } from "mobx-react";

import {
  BaseModelCollection,
  AppState,
  Reminder,
  getReminders,
  getAppState,
} from '../models';

import {
  BaseCollectionPage,
} from './BaseCollectionPage';
import { TextField, List, Subheader, ListItem, Button } from "react-md";
import { getApi } from "../api";
import HelpView from "../components/HelpView";


interface RemindersProps {
  history?: any;
  match?: any;
  location?: any;
}

type ComponentState = any;



@observer
class Reminders extends BaseCollectionPage<RemindersProps, ComponentState> {
  viewName = 'Reminders';
  objectName = 'reminder';
  store = 'my_models';

  get objectLabel() {
    return "Reminder";
  }

  

  
  

  

  getCollection(): BaseModelCollection {
    return getReminders();
  }

  getAppState(): AppState {
    return getAppState();
  }

  get customFilters(): any[] {
    let filters: any = []
    
      filters = ["created_by","user","attached_to_task","attached_to_note","attached_to_contact"]
    

    let customFilters: any[] = [];

    let obj = new Reminder();
    filters.forEach((key: string) => {
      let field = obj.getFieldInfo(key);
      if (!field) return;

      let filter: any = {
        key: key,
        label: field.label,
        type: field.type,
      };

      if ((field.type == 'model') || (field.type == 'models')) {
        filter['collectionName'] = field.objectName;
      }

      if (field.type == 'select') {
        filter['options'] = field.options;
      }

      customFilters.push(filter);
    })

    return customFilters;
  }

  

  

  

  
  


}

export default Reminders;