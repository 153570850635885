import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { observer } from "mobx-react";

import {
  Avatar,
  FontIcon,
  Chip,
} from 'react-md';

import { getModelIcon, getCollectionByName, BaseModel } from '../models';

interface ObjectMiniItemProps {
  objectId: number,
  objectName: string,
  removable?: boolean,
  onClick?: () => void;
  style?: any;
  stopPropagation?: boolean;
}

interface ObjectMiniItemState {
  object?: BaseModel,
  loading: boolean,
  notfound: boolean,
  redirectTo: string,
}


@observer
class ObjectMiniItem extends Component<ObjectMiniItemProps, ObjectMiniItemState> {
  state = {
    object: undefined,
    loading: true,
    notfound: false,
    redirectTo: "",
  }

  async componentDidMount() {
    try {
      let object = await this.getObject();
      this.setState({
        object: object,
        loading: false,
        notfound: false,
      });
    }
    catch (e) {
      this.setState({
        notfound: true,
        loading: false,
      });
      console.error(e);
    }
  }
  
  async getObject() {
    let collection = getCollectionByName(this.props.objectName);
    return await collection.get(this.props.objectId);
  }

  getAvatar() {
    let suffix = 'white';
    let icon = getModelIcon(this.props.objectName);
    return <Avatar><FontIcon>{icon}</FontIcon></Avatar>;
  }

  getLabel() {
    let object: any = this.state.object;
    if (object !== undefined) return object.getFullTitle();
    return "loading";
  }

  onClick = () => {
    if (this.props.onClick) {
      return this.props.onClick();
    }
  }

  objectViewUrl = () => {
    let object: any = this.state.object;
    if (object !== undefined) {
      return object.viewURL;
    }
  }

  renderRedirect() {
    if (this.state.redirectTo !== '') {
      return <Redirect to={this.state.redirectTo} />;
    }
  }

  render() {
    let redirect = this.renderRedirect();
    if (redirect) return redirect;

    let extra: any = {}
    if (this.props.removable) {
      extra.removable = true;
    }
    if (this.props.style) {
      extra.style = this.props.style;
    }

    if (this.state.loading) {
      return (
        <Chip
          className="ObjectMiniItem"
          avatar={<Avatar suffix="white"><FontIcon>access_time</FontIcon></Avatar>}
          label="loading"
        />
      );
    }
    else if (this.state.notfound) {
      return (<Chip
          className="ObjectMiniItem"
          avatar={<Avatar suffix="white"><FontIcon>warning</FontIcon></Avatar>}
          label={`${this.props.objectName} #${this.props.objectId}`}
        />
      );
    }
    else {
      if (this.props.onClick) {
        extra['onClick'] = this.onClick;
      }

      let chip = (
        <Chip
          className="ObjectMiniItem"
          avatar={this.getAvatar()}
          label={this.getLabel()}
          {...extra}
        />
      );

      if (this.props.onClick) {
        return chip;
      }

      let url = this.objectViewUrl();
      if (url) {
        return (
          <Link
            className="Link"
            to={url}
            onClick={(e) => {
              if (this.props.stopPropagation) e.stopPropagation();
            }}
          >{chip}</Link>);
      }
      else {
        return chip;
      }
    }
  }
}

export default ObjectMiniItem;