
import { observer } from "mobx-react";

import {
  File,
  Files,
  AppState,
  getFiles,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface FileDetailProps {
  history?: any;
  match?: any;
  location?: any;
  file?: File;
}

type ComponentState = any;



@observer
class FileDetail extends BaseDetailPage<FileDetailProps, ComponentState> {

  viewName = 'File Detail';
  objectName = 'file';

  

  


  

  

  newObject() {
    return new File();
  }

  getCollection(): Files {
    return getFiles();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
      
      
      
      
      
    ];
  }

}

export default FileDetail;