import React, { Component } from 'react';
import { observer } from "mobx-react";

import {
  Card,
  CardTitle,
  CardText,
  FontIcon,
  List,
  ListItem
} from 'react-md';

import packageJson from '../../package.json';

import { getAppState } from '../models';
import { BasePage } from './BasePage';
import UserDetail from './UserDetail';

type ComponentProp = any;
type ComponentState = any;

let appState = getAppState();

@observer
class Profile extends BasePage<ComponentProp, ComponentState> {
  viewName = 'Settings';

    onEditProfileButton = () => {
      window.open(packageJson.profileUrl, '_blank');
    }

    onChangePasswordButton = () => {
      window.open(packageJson.changePasswordUrl, '_blank');
    }

    onLogoutButton = () => {
      window.open(packageJson.logoutUrl);
    }

    render() {
      return (
          <UserDetail
            user={appState.userProfile}
            history={this.props.history}
            location={this.props.location}
          />
      );
    }
}

export default Profile;