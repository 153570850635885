import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { observer } from "mobx-react";

import {
  Avatar,
  Divider,
  FontIcon,
  List,
  ListItem,
  Subheader,
  Button,
  ListItemControl,
  Switch,
} from 'react-md';

import { getAppSettings, getAppState } from '../models';
import {BasePage} from './BasePage';

let appSettings = getAppSettings();

type ComponentProp = any;
type ComponentState = any;


@observer
class Settings extends BasePage<ComponentProp, ComponentState> {
  viewName = 'Settings';

  onDarkModeToggled = (checked: boolean, e: any) => {
    appSettings.darkMode = checked;
  }

  onDebugModeToggled = (checked: boolean, e: any) => {
    appSettings.debugMode = checked;
  }

  renderDeveloperMenu() {
    return (
      <List className="md-cell md-cell--12 md-paper md-paper--1">
        <Subheader primaryText="Developer" primary />
        <ListItemControl
          primaryAction={
            <Switch
              id="enable-debug-mode"
              name="debug_mode"
              label="Enable Debugging Mode"
              checked={appSettings.debugMode}
              onChange={this.onDebugModeToggled}
            />
          }
        />
      </List>
    );
  }

  render() {
    return (
      <div className="md-grid">
        <List className="md-cell md-cell--12 md-paper md-paper--1">
          <Subheader primaryText="Application" primary />
          <ListItemControl
            primaryAction={
              <Switch
                id="enable-dark-mode"
                name="dark_made"
                label="Use Dark Theme"
                checked={appSettings.darkMode}
                onChange={this.onDarkModeToggled}
              />
            }
          />
        </List>
        {appSettings.showDeveloperSettings && this.renderDeveloperMenu()}
      </div>
    );
  }
}

export default Settings;