import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Link, Route } from 'react-router-dom';
import { FontIcon, ListItem, Divider, Button } from 'react-md';
import { getAppState } from '../models';
import NotificationToolbarIcon from './NotificationToolbarIcon';

/**
 * Due to the fact that react-router uses context and most of the components
 * in react-md use PureComponent, the matching won't work as expected since
 * the PureComponent will block the context updates. This is a simple wrapper
 * with Route to make sure that the active state is correctly applied after
 * an item has been clicked.
 */
interface NavItemLinkProps {
  label?: string,
  labelFn?: any,
  extraLabel?: string,
  extraLabelFn?: any,
  primaryText?: string,
  to?: string,
  icon?: string,
  iconEl?: any,
  rightIcon?: string,
  exact?: boolean,
  match?: boolean,
  external?: boolean,
  target?: string,
  divider?: any,
  className?: any,
  logo?: boolean,
  image?: string,
  isReport?: boolean,
  isSystemMenu?: boolean,
}

class NavItemLink extends Component<NavItemLinkProps> {
  externalNavigate = () => {
    window.open(this.props.to, this.props.target ? this.props.target : "_blank");
  }

  isActive(to: string, isExact: boolean) {
    let currentUrl = (window as any).location.pathname;
    if (isExact) {
      return currentUrl === to;
    }
    else {
      return currentUrl.startsWith(to);
    }

    return false;
  }

  renderSystemMenu() {
    return (
    <ListItem primaryText={
      <div className="SystemMenuGroup">
        <Button
          icon
          primary
          to="/profile/"
          swapTheming={this.isActive("/profile/", true)}
          component={Link}
        >account_circle</Button>
        <Button
          icon
          primary
          to="/settings/"
          swapTheming={this.isActive("/settings/", true)}
          component={Link}
        >settings</Button>
        <Button
          icon
          primary
          iconEl={<NotificationToolbarIcon />}
          to="/notifications/"
          swapTheming={this.isActive("/notifications/", false)}
          component={Link}
        ></Button>
        <Button
          icon
          primary
          to="/about/"
          swapTheming={this.isActive("/about/", true)}
          component={Link}
        >info</Button>
      </div>
    } />
    );
  }

  render() {
    let appState = getAppState();
    if (this.props.isSystemMenu) {
      return this.renderSystemMenu();
    }

    if (this.props.isReport && appState.userProfile && !appState.userProfile.is_admin) {
      return (<div/>);
    }

    if (this.props.divider) {
      return (<Divider/>);
    }

    if (this.props.logo) {
      return (
        <div className="developer-logo-container" onClick={this.externalNavigate}>
          <img className="developer-logo" src={this.props.image} />
        </div>
      );
    }

    let currentUrl = (window as any).location.pathname;
    let isHighlighted = false;

    if (this.props.exact) {
      isHighlighted = currentUrl === this.props.to;
    }
    else {
      isHighlighted = currentUrl.startsWith(this.props.to);
    }

    let leftIcon;
    if (this.props.icon) {
      leftIcon = <FontIcon>{this.props.icon}</FontIcon>;
    }

    if (this.props.iconEl) {
      leftIcon = this.props.iconEl;
    }

    let extra: any = {};
    if (this.props.external) {
      extra.onClick = this.externalNavigate;
    }
    else {
      extra['component'] = Link;
      extra['activeClassName'] = 'active';
    }

    if (this.props.extraLabel) {
      extra['secondaryText'] = this.props.extraLabel;
    }

    if (this.props.extraLabelFn) {
      extra['secondaryText'] = this.props.extraLabelFn();
    }

    if (this.props.rightIcon) {
      extra['rightIcon'] = <FontIcon>open_in_new</FontIcon>;
    }

    let label = this.props.label;
    if (this.props.labelFn) {
      label = this.props.labelFn();
    }

    return (
      <ListItem
        className={isHighlighted ? "active" : ""}
        to={this.props.to}
        primaryText={label}
        leftIcon={leftIcon}
        {...extra}
      />
     );
  }
}

export default NavItemLink;