
import { observer } from "mobx-react";

import {
  Reminder,
  Reminders,
  AppState,
  getReminders,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface ReminderDetailProps {
  history?: any;
  match?: any;
  location?: any;
  reminder?: Reminder;
}

type ComponentState = any;



@observer
class ReminderDetail extends BaseDetailPage<ReminderDetailProps, ComponentState> {

  viewName = 'Reminder Detail';
  objectName = 'reminder';

  

  


  

  

  newObject() {
    return new Reminder();
  }

  getCollection(): Reminders {
    return getReminders();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
      
      
      
      
      
    ];
  }

}

export default ReminderDetail;