
import { observer } from "mobx-react";

import {
  Group,
  Groups,
  AppState,
  getGroups,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface GroupDetailProps {
  history?: any;
  match?: any;
  location?: any;
  group?: Group;
}

type ComponentState = any;



@observer
class GroupDetail extends BaseDetailPage<GroupDetailProps, ComponentState> {

  viewName = 'Group Detail';
  objectName = 'group';

  

  


  
  get enableStar() {
    return false;
  }
  

  

  newObject() {
    return new Group();
  }

  getCollection(): Groups {
    return getGroups();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
      
      
      
      
      
    ];
  }

}

export default GroupDetail;