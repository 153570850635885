import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Contact extends BaseModel {
  object_name: string = 'contacts';
  object_type: string = 'contact';
  object_meta: any = {};
  @observable first_name: string = '';
  @observable last_name: string = '';
  @observable email: string = '';
  @observable title: string = '';
  @observable address: string = '';
  @observable city: string = '';
  @observable state: string = '';
  @observable zip: string = '';
  @observable description: string = '';
  @observable phone: string = '';
  @observable cell: string = '';
  @observable fax: string = '';
  @observable do_not_call: boolean = false;
  @observable do_not_email: boolean = false;
  @observable do_not_fax: boolean = false;
  @observable attached_to_client?: number;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    first_name: {"type":"text","required":true,"width":4},
    last_name: {"type":"text","required":true,"width":4},
    email: {"type":"email","width":4},
    title: {"type":"text","width":4},
    address: {"type":"text","multiline":true},
    city: {"type":"text","width":4},
    state: {"type":"select","width":4,"options_preset":"us-states"},
    zip: {"type":"text","width":4},
    description: {"type":"text","multiline":true},
    phone: {"type":"phone","width":4},
    cell: {"type":"phone","width":4},
    fax: {"type":"phone","width":4},
    do_not_call: {"type":"check","width":4},
    do_not_email: {"type":"check","width":4},
    do_not_fax: {"type":"check","width":4},
    attached_to_client: {"type":"model","objectName":"clients","label":"Client","inlineCreate":true},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return `${this.first_name} ${this.last_name}`;
    
  }

  getSubtitle() {
    
    return this.email;
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).first_name) {
      
      
      keywords += (this as any).first_name;
    }
    
    
    
    if ((this as any).last_name) {
      
      keywords += ' ';
      
      keywords += (this as any).last_name;
    }
    
    
    
    if ((this as any).email) {
      
      keywords += ' ';
      
      keywords += (this as any).email;
    }
    
    
    return keywords;
  }
}


class Contacts extends BaseModelCollection {
  object_name: string = 'contacts';
  object_type: string = 'contact';
  baseClass: any = Contact;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Contact>([])

  
  

  async get(id: number): Promise<Contact> {
    let model: any = this.baseGet(id);
    return (model as Contact);
  }

  
  

}


let contacts: Contacts;
let getContacts = () => {
    if (!contacts) {
        contacts = new Contacts();
    }

    return contacts;
}

export {
  Contact,
  Contacts,
  getContacts,
}