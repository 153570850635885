import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardTitle,
  FontIcon,
  Snackbar,
  ListItem,
  List,
  Grid,
  Cell,
  TabsContainer,
  Tabs,
  Tab
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  AppState,
  getModelIcon,
} from '../models';

import {
  BasePage,
} from './BasePage';

import ObjectViewer from '../components/ObjectViewer';
import MiniCollectionView from '../components/MiniCollectionView';

type ComponentState = any;


abstract class BaseTabbedDetailPage<P, ComponentState> extends BasePage<P, ComponentState> {
  state = {
    object: this.newObject(),
    deleting: false,
    notFound: false,
    isActionMenuOpen: false,
    toasts: [],
    redirectTo: '',
    showMeta: false,
  }

  viewName = 'ObjectDetail';
  objectName = 'object';

  abstract newObject(): BaseModel;
  abstract getCollection(): BaseModelCollection;
  abstract getAppState(): AppState;

  getRelatedModelCollections(): any[] {
    return [];
  }

  getRelatedModelLinks() {
    let relatedCollections = this.getRelatedModelCollections();
    return relatedCollections.map((rc: any) => {
      let url = rc.collection.getEditURL(0);
      let fullUrl = `${url}?_rel_${rc.related_field}=${this.state.object.id}`;
      if (rc.add_object_extra_rels) {
        rc.add_object_extra_rels.forEach((rel: any) => {
          if (!rel.value) return;
          fullUrl = `${fullUrl}&_rel_${rel.field}=${rel.value}`;
        });
      }
      return {
        name: rc.name,
        related_field: rc.related_field,
        labels: rc.collection.getLabels(),
        for_model: rc.for_model,
        link: fullUrl,
        readOnly: rc.readOnly ? true : false,
      }
    });
  }

  get readOnly() {
    return false;
  }

  get editOnly() {
    return false;
  }

  get allowStarWhenReadOnly() {
    return false;
  }

  get enableStar() {
    return true;
  }

  get enableMetadata() {
    return true;
  }

  getObjectTitle(): string {
    return this.state.object.getTitle();
  }

  getObjectSubtitle(): string {
    return this.state.object.getSubtitle();
  }

  getMatch() {
    return (this.props as any).match;
  }

  getObjectFromProp(): BaseModel {
    return (this.props as any)[this.objectName];
  }

  getObjectId() {
    if (this.state.object && this.state.object.id) return this.state.object.id;

    let match = this.getMatch();
    if (match) return parseInt(match.params.id);
    return null;
  }

  async getObject() {
    let collection = this.getCollection();
    let match = this.getMatch();

    if (match) {
      return await collection.get(parseInt(match.params.id));
    }

    let object = this.getObjectFromProp();
    if (object) {
      return object;
    }
    return this.newObject();
  }

  get toolbarButton(): any {
    if (this.readOnly) return null;

    return {
      label: 'Edit',
      icon: 'edit',
      onClick: () => {
        this.onEditButton();
      }
    }
  }

  async loadObject() {
    let appState = this.getAppState();

    appState.showLoading();
    try {
      let object = await this.getObject();
      this.setState({
        object: object,
      });
    }
    catch (e) {
      this.addToast('Error fetching data! Please try again.');
      this.setState({notFound: true})
      console.error(e);
    }
    finally {
      appState.hideLoading();
    }
    
    appState.pageTitle = this.state.object.getTitle();
  }

  async componentDidMount() {
    await this.loadObject();
  }

  componentWillReceiveProps(nextProps: any) {
    if ((nextProps as any)[this.objectName] !== this.getObjectFromProp()) {
      window.setTimeout(() => {
        this.setState({
          object: (nextProps as any)[this.objectName],
        });
        let appState = this.getAppState();
        appState.pageTitle = this.state.object.getTitle();
      }, 1000);
    }
  }


  onBackButton = () => {
    let backUrl = this.getBackUrl();
    if (backUrl && (this.props as any).history) {
      this.redirectTo(backUrl);
    }
    else {
      this.redirectTo(this.state.object.collectionViewURL);
    }
  }

  onEditButton = () => {
    this.redirectTo(this.state.object.editURL);
  }

  onToggleMeta = () => {
    this.setState({showMeta: !this.state.showMeta});
  }

  delete = async () => {
    if (this.state.deleting) return;

    let collection = this.getCollection();
    let appState = this.getAppState();

    this.addToast('Deleting...')
    this.setState({deleting: true});
    appState.showLoading();
    let deleted = false;
    try {
      deleted = await collection.remove(this.state.object);
    }

    catch (e) {
      this.addToast('Error deleting data! Please try again.');
      console.error(e);
    }

    appState.hideLoading();
    this.setState({deleting: false})
    if (deleted) {
      this.redirectTo(this.state.object.collectionViewURL);
    }
  }

  onDeleteButton = () => {
    if (window.confirm('Are you sure you want to delete this item? This action is irreversible.')) {
      this.delete();
    }
  }

  onStarButton = () => {
    let object: BaseModel = this.state.object;
    object.star(true);
    this.addToast('Starring object...');
  }

  onUnstarButton = () => {
    let object: BaseModel = this.state.object;
    object.star(false);
    this.addToast('Removing star...');
  }

  render404() {
    let object: BaseModel = this.state.object;
    return (<div className="md-grid">
      <Card className="md-cell--6 md-block-centered">
        <CardTitle
          title="Not Found"
          subtitle={`${this.objectName} not found!`}
          avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
        />
      </Card>
      <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
    </div>);
  }

  renderLoading() {
    let appState = this.getAppState();
    let object: BaseModel = this.state.object;
    return (<div className="md-grid">
      <Card className="md-cell--6 md-block-centered">
        <CardTitle
          title={appState.isLoading() ? 'Loading...' : 'Empty'}
          avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
        />
      </Card>
      <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
    </div>);
  }

  onActionButtonClicked = () => {
    this.setState({isActionMenuOpen: !this.state.isActionMenuOpen});
  }

  renderAddButton() {
    let rels = this.getRelatedModelLinks();
    if (rels.length === 0) return;

    if (this.state.isActionMenuOpen) {
      let items = rels.map((rel: any, i: number) => {
        if (rel.readOnly) return;
        return (
          <Button
            id={`add-btn-${i}`}
            key={`add-btn-${i}`}
            component={Link}
            to={rel.link}
            raised
            primary
            iconChildren={getModelIcon(rel.for_model.toLowerCase())}
          >{`Add ${rel.labels[0]}`}</Button>
        );
      });
      return(
        <div className="floating-buttons-container">
            {items}
            <Button
                floating
                secondary
                onClick={this.onActionButtonClicked}
            >close</Button>
        </div>
      );
    }
    else {
      return (<div className="floating-buttons-container">
          <Button
              floating
              primary
              onClick={this.onActionButtonClicked}
          >add</Button>
      </div>);
    }
  }

  renderRelatedModels() {
    let items = this.getRelatedModelCollections().map((item) => {
      let filter: any = {};
      filter[item.related_field] = this.getObjectId();

      return (
        <MiniCollectionView
          key={`list_${item.related_field}_${item.collection.object_name}`}
          title={item.name}
          collection={item.collection}
          filter={filter}
          hideSeeMore={true}
          hideIfEmpty={true}
          cellSize={12}
          flat={true}
          flatBorder={true}
        />)
    });

    if (items.length) return <div>{items}</div>
  }

  render() {
    let redirect = this.renderRedirect();

    if (this.state.notFound) {
      return this.render404();
    }

    let object: BaseModel = this.state.object;

    if (!object.id) {
      return this.renderLoading();
    }

    let appState = this.getAppState();
    let isCreatedByMe = false;

    if (appState && appState.userProfile && (object as any).created_by == appState.userProfile.id) {
      isCreatedByMe = true;
    }

    let starred = object.starred;
    let starred_ro = false;
    if (object.mine && !object.starred) {
      starred = true;
      starred_ro = true;
    }

    return (
      <div className={this.className}>
        <Card className="object-detail-container md-cell--12 md-block-centered">
          <CardTitle
            title={this.getObjectTitle()}
            subtitle={this.getObjectSubtitle()}
            avatar={<Avatar suffix="white" onClick={this.onBackButton}><FontIcon>arrow_back</FontIcon></Avatar>}
          />
          <TabsContainer slideHeightProp='minHeight'>
            <Tabs tabId="object-detail" overflowMenu>
              
              {[(
                <Tab label="Details">
                  <ObjectViewer object={object} dataShown={'main'} />
                  <hr />
                  {this.state.showMeta &&
                    <ObjectViewer object={object} dataShown={'meta'} />
                  }
                  {this.enableMetadata &&
                    <List>
                      <ListItem
                        key={`metadata-toggle`}
                        primaryText={this.state.showMeta ? "Hide Metadata" : "Show Metadata"}
                        onClick={this.onToggleMeta}
                        leftIcon={<FontIcon>{this.state.showMeta ? "expand_less" : "expand_more"}</FontIcon>}
                      />
                    </List>
                  }
                </Tab>
              )].concat(this.getRelatedModelCollections().map((item) => {
                let filter: any = {};
                filter[item.related_field] = this.getObjectId();

                return (
                  <Tab
                    label={item.name} 
                    key={`list_${item.related_field}_${item.collection.object_name}`}
                    
                  >
                    <MiniCollectionView
                      title={item.name}
                      collection={item.collection}
                      filter={filter}
                      hideSeeMore={true}
                      cellSize={12}
                      flat={true}
                      flatBorder={true}
                    />
                  </Tab>);
              }))}

            </Tabs>
          </TabsContainer>
          

          {!this.readOnly &&
          <CardActions>
            <Grid className="md-cell md-cell--12">
              <Cell size={6} phoneSize={2}>
                <Button raised primary className="md-cell--left" onClick={this.onEditButton} iconChildren="edit">Edit</Button>
              </Cell>
              <Cell size={6} phoneSize={2} style={{textAlign: 'right'}}>
                {!this.editOnly &&
                  <Button raised secondary className="bg-color-danger" onClick={this.onDeleteButton} iconChildren="delete">Delete</Button>
                }
              </Cell>
            </Grid>
          </CardActions>}

          {this.enableStar &&
          <div className="star-container">
          {starred && starred_ro && <Button icon className="md-cell--center star-btn-gold" iconChildren="star" tooltipLabel={isCreatedByMe ? "you created this item": "this item is assigned to you"}>Star</Button>}
          {!object.starred && !starred_ro && <Button icon className="md-cell--center" onClick={this.onStarButton} iconChildren="star_border" tooltipLabel="star this item">Star</Button>}
          {object.starred && !starred_ro && <Button icon className="md-cell--center star-btn-gold" onClick={this.onUnstarButton} iconChildren="star" tooltipLabel="you starred this item">Unstar</Button>}
          </div>
          }
        </Card>

        {this.renderAddButton()}
        <Snackbar
          id="object-snackbar"
          toasts={this.state.toasts}
          autohide={true}
          onDismiss={this.dismissToast}
        />
        {redirect}
      </div>
    );
  }
}

export {
  BaseTabbedDetailPage,
};