import React, { Component } from 'react';
import { toJS } from "mobx";
import { observer, componentByNodeRegistery } from "mobx-react";
import { createViewModel } from 'mobx-utils'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardTitle,
  FontIcon,
  Collapse,
  CircularProgress,
  DialogContainer,
  Toolbar,
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getAppState,
} from '../models';


import ObjectEditor from '../components/ObjectEditor';


interface InlineObjectEditFormProps {
  isNew: boolean;
  object: BaseModel;
  collection: BaseModelCollection;
  objectLabel: string;
  onSaved: (object: BaseModel) => void;
  addToast?: (message: string) => void;
  className?: string;
  helpText?: string;
}


@observer
class InlineObjectEditForm extends Component<InlineObjectEditFormProps> {
  state = {
    objectEdit: createViewModel(this.props.object),
    saving: false,
    notFound: false,
    error: false,
    errorFields: {},
    showEditDialog: false,
    dialogPageX: 0,
    dialogPageY: 0,
  }

  addToast(message: string) {
    if (this.props.addToast) {
      this.props.addToast(message);
    }
  }

  get objectLabel() {
    return this.props.objectLabel;
  }
  
  getObjectTitle(): string {
    return this.props.object.getTitle();
  }

  getObjectSubtitle(): string {
    return this.props.object.getSubtitle();
  }

  showLoading() {
    
  }

  hideLoading() {
    
  }

  async componentDidMount() {

  }


  componentWillMount() {
    
  }

  validate(obj: BaseModel) {
    let replica = toJS(obj);
    let items: any[] = [];
    Object.keys(replica).forEach(key => {
      if ((obj as any).model.isHidden(key)) return;
      let info = (obj as any).model.getFieldInfo(key);
      let value = (replica as any)[key];
      if (info.type === 'combined') {
        info.subFields.forEach((fieldKey: string) => {
          let fieldValue = (replica as any)[fieldKey];
          if (fieldValue) {
            value = fieldValue;
          }
        });
      }
      items.push({
        key: key,
        type: info.type,
        value: value,
        info: info,
      })
    });

    let validated = true;
    let errorFields: any = {};
    items.forEach((item) => {
      if (item.info.required) {
        if (!item.value) {
          validated = false;
          errorFields[item.key] = true;
        }
      }
    });

    this.setState({
      error: !validated,
      errorFields: errorFields,
    });

    return validated;
  }

  async save() {
    if (this.state.saving) return;

    let objectEdit = this.state.objectEdit;
    let object = objectEdit.model;

    if (!this.validate(objectEdit)) {
      this.addToast('Please check your input!');
      return;
    }

    this.showLoading();

    this.addToast('Saving...');
    this.setState({saving: true});
    objectEdit.submit();
    let saved = false;
    try {
      saved = await object.save();
    }
    catch (e) {
      this.addToast('Error saving data! Please try again.');
      saved = false;
      console.error(e);
    }

    this.setState({saving: false});
    this.hideLoading();

    if (saved) {
      this.props.onSaved(object);
      this.onHideEditDialog();
    }
  }

  onChange = (key: string, value: any, event: any) => {
    this.validate(this.state.objectEdit);
  }

  onSaveEditButton = async () => {
    return await this.save();
  }

  onCancelEditButton = () => {
      this.onHideEditDialog();
  }

  onFormSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return await this.save();
  }

  onShowEditDialog = (e: any) => {
    let state: any = {showEditDialog: true};
    if (e && e.changedTouches) {
      state['dialogPageX'] = e.changedTouches[0].pageX;
      state['dialogPageY'] = e.changedTouches[0].pageY;
    }

    if (this.props.isNew) {
      state['objectEdit'] = createViewModel(this.props.object);
    }

    this.setState(state);
  }

  onHideEditDialog = () => {
    this.setState({showEditDialog: false});
  }

  render() {
    let object: BaseModel = this.props.object;
    let className = "InlineObjectEditForm";
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    let button: any;

    if (this.props.isNew) {
      button = (
        <Button icon className="ShowEditDialogButton" onClick={this.onShowEditDialog}>add</Button>
      );
    }
    else {
      button = (
        <Button flat iconBefore={false} iconChildren="edit" className="ShowEditDialogButton" onClick={this.onShowEditDialog}>{this.props.object.getTitle()}</Button>
      );
    }

    return (
      <div className="InlineObjectEditForm">
        {button}
        <DialogContainer
          id={`ObjectEditDialog-${this.props.object.object_type}-${this.props.object.id}`}
          visible={this.state.showEditDialog}
          pageX={this.state.dialogPageX}
          pageY={this.state.dialogPageY}
          fullPage
          onHide={this.onHideEditDialog}
          aria-labelledby={`new ${this.props.object.object_type}`}
          portal={true}
          lastChild={true}
          disableScrollLocking={true}
          renderNode={document.body}
        >
          <Toolbar
            fixed
            colored
            title={this.props.isNew ? `Add new ${this.objectLabel}` : `Editing ${this.objectLabel}`}
            titleId={`new ${this.props.object.object_type}`}
            nav={<Button icon onClick={this.onHideEditDialog} disabled={this.state.saving}>close</Button>}
            actions={<Button flat onClick={this.onFormSubmit} disabled={this.state.saving}>Save</Button>}
          />
          <section className="ObjectEditDialog--Content">
            <Collapse collapsed={!this.state.saving}>
              <CircularProgress id={`ObjectEditDialog-loading-${this.props.object.object_type}-${this.props.object.id}`} />
            </Collapse>

            <form
              onSubmit={this.onFormSubmit}
              id={`ObjectEditDialog-form-${this.props.object.object_type}-${this.props.object.id}`}
            >
              <ObjectEditor
                object={this.state.objectEdit}
                disabled={this.state.saving}
                error={this.state.error}
                errorFields={this.state.errorFields}
                onChange={this.onChange}
              />
              <div>
                <Button raised primary type="submit" className="md-cell--left" iconChildren="check" disabled={this.state.saving}>Save</Button>
                <Button flat secondary className="md-cell--right" onClick={this.onCancelEditButton} iconChildren="clear">Cancel</Button>
              </div>
            </form>
          </section>
        </DialogContainer>
      </div>
    );
  }
}

export default InlineObjectEditForm;