import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";
import moment from 'moment';

import { getApi } from '../api';
import { getAppState } from './AppState';
import { BaseModel, BaseModelCollection } from './Models';


class Client extends BaseModel {
  object_name: string = 'clients';
  object_type: string = 'client';
  object_meta: any = {};
  @observable name: string = '';
  @observable address: string = '';
  @observable city: string = '';
  @observable state: string = '';
  @observable zip: string = '';
  @observable description: string = '';
  @observable status: string = '';
  @observable sales_person?: number;
  @observable phone: string = '';
  @observable cell: string = '';
  @observable fax: string = '';
  @observable do_not_call: boolean = false;
  @observable do_not_email: boolean = false;
  @observable do_not_fax: boolean = false;
  @observable created_by?: number;
  @observable created_at: string = '';
  @observable modified_by?: number;
  @observable modified_at: string = '';
  

  object_fields: any = {
    name: {"type":"text","required":true},
    address: {"type":"text","multiline":true},
    city: {"type":"text","width":4},
    state: {"type":"select","width":4,"options_preset":"us-states"},
    zip: {"type":"text","width":4},
    description: {"type":"text","multiline":true},
    status: {"type":"select","required":true,"options":[{"label":"Active","value":"active"},{"label":"Past","value":"past"},{"label":"Prospect","value":"prospect"}]},
    sales_person: {"type":"model","objectName":"users"},
    phone: {"type":"phone","width":4},
    cell: {"type":"phone","width":4},
    fax: {"type":"phone","width":4},
    do_not_call: {"type":"check","width":4},
    do_not_email: {"type":"check","width":4},
    do_not_fax: {"type":"check","width":4},
    created_by: {"type":"model","objectName":"users","meta":true,"label":"Created by"},
    created_at: {"type":"datetime","meta":true,"label":"Created at"},
    modified_by: {"type":"model","objectName":"users","meta":true,"label":"Modified by"},
    modified_at: {"type":"datetime","meta":true,"label":"Created at"},
    
  }

  

  

  getTitle() {
    
    return `${this.name}`;
    
  }

  getSubtitle() {
    
    return this.status;
    
  }

  getSearchKeywords() {
    let keywords = '';
    
    
    if ((this as any).name) {
      
      
      keywords += (this as any).name;
    }
    
    
    
    if ((this as any).description) {
      
      keywords += ' ';
      
      keywords += (this as any).description;
    }
    
    
    return keywords;
  }
}


class Clients extends BaseModelCollection {
  object_name: string = 'clients';
  object_type: string = 'client';
  baseClass: any = Client;
  object_meta: any = {};
  // @observable models: IObservableArray<Contact> = ([] as IObservableArray<Contact>);
  readonly models = observable<Client>([])

  
  

  async get(id: number): Promise<Client> {
    let model: any = this.baseGet(id);
    return (model as Client);
  }

  
  

}


let clients: Clients;
let getClients = () => {
    if (!clients) {
        clients = new Clients();
    }

    return clients;
}

export {
  Client,
  Clients,
  getClients,
}