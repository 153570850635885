import React, { Component } from 'react';
import { observer } from "mobx-react";
import throttle from "lodash.throttle";
import AsyncSelect from 'react-select/async';

import {
  Autocomplete,
  Avatar,
  FontIcon,
  LinearProgress,
} from 'react-md';

import {
  BaseModelCollection, BaseModel, getAppState,
} from '../models';



interface ObjectPickerProps {
  collection: BaseModelCollection
  label?: string;
  value?: any;
  placeholder?: string;
  block?: boolean;
  id: string;
  key: string;
  onAutocomplete: (id: any, model: any, event: any) => void;
  autofocus?: boolean;
  error?: boolean;
  errorText?: string;
  className?: string;
  minChar?: number;
  style?: any;
}

@observer
class ObjectPicker extends Component<ObjectPickerProps> {
  state = {
    error: false,
    errorMessage: '',
    data: [],
    loading: false,
  }

  selectInput: any;

  constructor(props: ObjectPickerProps) {
    super(props);
    this.selectInput = React.createRef();
  }

  getAppState() {
    return getAppState();
  }

  dataFromCollection(models: BaseModel[]) {
    let map: any = {};
    let data: any[] = [];
    models.forEach(model => {
      if (map[`${model.object_type}-${model.id}`]) return;
      let label = model.getTitle();
      if (model.getSubtitle()) {
        label = `${label} - ${model.getSubtitle()}`;
      }
      map[`${model.object_type}-${model.id}`] = model.id;
      data.push({
        key: `search-result-${model.object_type}-${model.id}`,
        value: model.id,
        label: label,
        model: model,
        leftAvatar: <Avatar suffix="white"><FontIcon>{model.icon}</FontIcon></Avatar>,
      });
    });
    return data;
  }

  handleSearch = async (value: string) => {
    if (!value) {
      this.setState({data: []});
      return;
    }

    let minChar = this.props.minChar ? this.props.minChar : 2;

    if (value && (value.length < minChar)) return;

    this.setState({loading: true});
    let data = await this.props.collection.query({
      sort: {
        sortFn: this.props.collection.sortFn
      }
    }, {search: value});
    this.setState({data: this.dataFromCollection(data), loading: false});
  }

  loadOptions = async (value: string, callback: (options: (any)[]) => void) => {
    this.setState({loading: true});
    let data = await this.props.collection.query({
      sort: {
        sortFn: this.props.collection.sortFn
      }
    }, {search: value});
    let dd = this.dataFromCollection(data);
    callback(dd);
    this.setState({data: dd, loading: false});
    return dd;
  };

  onAutocomplete = (suggestion: any, suggestionIndex: any, matched: any) => {
    let item: any = matched[suggestionIndex];
    this.props.onAutocomplete(suggestion, item.model, undefined);
  }

  componentDidMount(): void {
    if (this.props.autofocus) {
      this.selectInput.current.focus();
      this.selectInput.current.select.onMenuOpen();
    }
  }

  render() {
    let className = 'ObjectPicker';
    let errorText: any = null;
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    if (this.props.error) {
      className = `${className} ObjectPicker--error`;
      
      if (this.props.errorText) {
        errorText = (
          <div className="md-text-field-message-container md-full-width md-text--error">
            <div className="md-text-field-message">{this.props.errorText}</div>
          </div>
        );
      }
    }

    return (
      <div className={className} style={this.props.style}>
        <label style={{
          display: 'block',
          marginTop: 15,
          marginLeft: 20,
          color: 'rgba(0, 0, 0, 0.54)',
          fontSize: 13,
        }}>{this.props.label}</label>
        <div style={{
          padding: 20,
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 50
        }}>
          <AsyncSelect
            cacheOptions
            loadOptions={this.loadOptions}
            defaultOptions
            ref={this.selectInput}
            placeholder={this.props.placeholder}
            onInputChange={(value: string) => {
            }}
            onChange={(value: any) => {
              if (value) this.props.onAutocomplete(value.value, value.model, undefined);
              if (value && this.selectInput.current) {
                setTimeout(() => {
                  this.selectInput.current.select.select.clearValue();
                }, 100);
              }
            }}
            isLoading={this.state.loading}
          />
        </div>
        {errorText}
      </div>
    );
  }
}

export default ObjectPicker;