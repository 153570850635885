import React from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';

import {
  Tab,
  Tabs,
  TabsContainer,
  Paper,
  Chip,
  Avatar,
  FontIcon,
  SelectField,
  DatePicker,
  Button,
  DataTable,
  TableHeader,
  TableRow,
  TableColumn,
  TableBody,
  CircularProgress,
} from 'react-md';

import {
  getActivities, BaseModel, getModelIcon, getAppState, getUsers, BaseModelCollection, getCollectionByName,
} from '../models';

import ActivityHeatmap from '../components/ActivityHeatmap';
import TableCollectionView from '../components/TableCollectionView';
import {BasePage} from './BasePage';
import ObjectMiniItem from '../components/ObjectMiniItem';
import ObjectPicker from '../components/ObjectPicker';
import { Link } from 'react-router-dom';

type ComponentProp = any;
type ComponentState = any;

class ActivityReport extends BasePage<ComponentProp, ComponentState> {
  viewName = 'Activity Report';
  state = {
    models: ([] as BaseModel[]),
    users: ([] as any[]),
    types: [
      {
        label: 'All',
        value: undefined,
      },
      {
        label: 'Contact',
        value: 'contact',
      },
      {
        label: 'Client',
        value: 'client',
      },
      {
        label: 'Task',
        value: 'task',
      },
      {
        label: 'Note',
        value: 'note',
      },
      {
        label: 'File',
        value: 'file',
      },
      {
        label: 'Reminder',
        value: 'reminder',
      },
      {
        label: 'Group',
        value: 'group',
      },
      {
        label: 'User',
        value: 'user',
      },
      
    ],
    redirectTo: '',
    toasts: [],
    start: '',
    end: '',
    filterUser: undefined,
    filterStartDate: this.getDateFromParam('start_date', moment().subtract(1, 'months').format('YYYY-MM-DD')),
    filterEndDate: this.getDateFromParam('end_date', moment().format('YYYY-MM-DD')),
    filterType: undefined,
  }

  activityFields = [
    {
      label: 'User',
      render: (model: BaseModel) => (<ObjectMiniItem objectId={(model as any).created_by} objectName={'users'} style={ {margin: 0} } />),
    },
    {
      label: 'Action',
      render: (model: BaseModel) => {
        let action = (model as any).action;
        let style = {
          padding: '5px 10px',
          color: '#fff',
          background: '#2196f3',
          borderRadius: '5px',
        };
        switch (action) {
          case 'created':
            style.background = '#2196f3';
            break;
          case 'updated':
            style.background = '#689f38';
            break;
          case 'deleted':
            style.background = '#dd2c00';
            break;
        }
        return (<div style={style}>{action}</div>)
      },
    },
    {
      label: 'Target',
      render: (model: BaseModel) => {
        let targetExist = true;

        if (((model as any).target_object_name === 'nonetypes')) {
          targetExist = false;
        }

        if (targetExist) {
          return (
            <Link className="Link" to={`/${(model as any).target_object_name}/${(model as any).target_id}/`}>
              <Chip
                className="ObjectMiniItem"
                avatar={<Avatar><FontIcon>{getModelIcon((model as any).target_object_name)}</FontIcon></Avatar>}
                label={(model as any).target_name}
                style={ {margin: 0} }
              />
            </Link>
          );
        }
        else {
          return (
            <Chip
              className="ObjectMiniItem"
              avatar={<Avatar><FontIcon>delete</FontIcon></Avatar>}
              label={(model as any).target_name}
              style={  {margin: 0} }
              onClick={() => {
                this.addToast('This object has been deleted!')
              }}
            />
          )
        }

      },
    },
    {
      label: 'Time',
      render: (model: BaseModel) => moment((model as any).created_at).format('LLLL'),
    }
  ]

  get collection() {
    return getActivities();
  }

  get collectionFilter() {
    let filter: any = {};

    if (this.state.filterUser) {
      filter['created_by'] = this.state.filterUser;
    }

    if (this.state.filterStartDate) {
      filter['created_at__gte'] = moment(this.state.filterStartDate).format('YYYY-MM-DDT00:00:00');
    }

    if (this.state.filterEndDate) {
      filter['created_at__lt'] = moment(this.state.filterEndDate).format('YYYY-MM-DDT23:59:59');
    }

    if (this.state.filterType) {
      filter['type'] = this.state.filterType;
    }


    return filter;
  }

  async loadData() {
    let appState = getAppState();
    appState.showLoading();
    let models: any = [];
    try {
      if (this.collectionFilter) {
        models = await this.collection.query({
          sort: {
            sortFn: this.collection.sortFn
          }
        }, this.collectionFilter);
      }
      else {
        models = await this.collection.query({
          sort: {
            sortFn: this.collection.sortFn
          }
        }, undefined);
      }

      this.setState({models: models});
      appState.hideLoading();
    }
    catch (e) {
      this.addToast('Error fetching data! Please try again.');
      appState.hideLoading();
      console.error(e);
    }
  }

  async loadUsers() {
    let appState = getAppState();
    appState.showLoading();
    let models: any = [];
    try {
      models = await getUsers().query({
        sort: {
          sortFn: this.collection.sortFn
        }
      }, undefined);

      if (appState.userProfile && appState.userProfile.is_admin) {
        let users: any[] = [{label: 'All Users', value: ''}];
        models.forEach((model: BaseModel) => {
          users.push({label: `${(model as any).first_name} ${(model as any).last_name}`, value: model.id})
        })

        this.setState({users: users});
      }
      else if (appState.userProfile) {
        let users: any[] = [];
        models.forEach((model: BaseModel) => {
          if (appState.userProfile && (model.id === appState.userProfile.id)) {
            users.push({label: `${(model as any).first_name} ${(model as any).last_name}`, value: model.id})
          }
        })

        this.setState({users: users, filterUser: appState.userProfile ? appState.userProfile.id: undefined});
      }
      else {

      }
      
      appState.hideLoading();
    }
    catch (e) {
      this.addToast('Error fetching data! Please try again.');
      appState.hideLoading();
      console.error(e);
    }
  }

  getDateFromParam(name: string, defaultValue: string) {
    let qdata = queryString.parse(window.location.search);
    let dateString = qdata[name];

    if (dateString) {
      let m = moment(dateString);
      if (!m.isValid()) {
        return defaultValue;
      }
      return m.format('YYYY-MM-DD');
    }
    return defaultValue;
  }

  restored = false;
  async componentDidMount() {
    this.restored = this.restoreState();
    await this.loadUsers();
    await this.loadData();
  }

  restoreState() {
    let appState = getAppState();
    let state = appState.getCachedViewStates(this.viewName);
    if (state) {
      this.setState(state);
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    let appState = getAppState();

    appState.setCachedViewStates(this.viewName, this.state);
  }

  setFilterUser = (value: any) => {
    this.setState({filterUser: value});
  }

  setFilterStartDate = (value: any) => {
    this.setState({filterStartDate: value});
  }

  setFilterEndDate = (value: any) => {
    this.setState({filterEndDate: value});
  }

  setFilterType = (value: any) => {
    this.setState({filterType: value});
  }

  onFilterForm = (e: any) => {
    e.preventDefault();
    this.loadData();
  }

  onPastWeek = () => {
    this.setState({
      filterStartDate: moment().subtract(1, 'weeks').format('YYYY-MM-DD'),
      filterEndDate: moment().format('YYYY-MM-DD'),
    });
    window.setTimeout(() => this.loadData(), 100);
  }

  onPastMonth = () => {
    this.setState({
      filterStartDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      filterEndDate: moment().format('YYYY-MM-DD'),
    });
    window.setTimeout(() => this.loadData(), 100);
  }

  onPastYear = () => {
    this.setState({
      filterStartDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
      filterEndDate: moment().format('YYYY-MM-DD'),
    });
    window.setTimeout(() => this.loadData(), 100);
  }


  renderModelField(key: string, label: string, collection: BaseModelCollection, onChange: any, minChar?: number) {
    let extra: any;

    if (!minChar) minChar = 2;

    let value = (this.state as any)[key];
    if (value) {
      let objectId = value;
      extra = (<div style={  {paddingLeft: '20px'} }>
        <ObjectMiniItem
          key={`${key}-${objectId}`}
          objectId={objectId}
          objectName={collection.object_name}
          removable={true}
          onClick={() => onChange(undefined)}
        />
      </div>);
    }

    return (<div id={`object-editor-field-container-${key}`} key={`object-editor-field-container-${key}`} className="md-cell md-cell--3">
    <ObjectPicker
      id={`object-editor-field-${key}`}
      key={`object-editor-field-${key}`}
      label={label}
      placeholder={`type to search ${collection.object_name}...`}
      collection={collection}
      onAutocomplete={onChange}
      minChar={minChar}
    />
    {extra}
    </div>);
  }


  renderFilterForm() {
    let appState = getAppState();

    return (
      <Paper zDepth={1} style={  {margin: '20px', padding: '10px' } }>
        <form
          className="md-grid"
          onSubmit={this.onFilterForm}
        >
          <SelectField
            id="filter-user"
            label="User"
            placeholder="Select a user"
            className="md-cell md-cell--3"
            menuItems={this.state.users}
            itemLabel={'label'}
            itemValue={'value'}
            value={this.state.filterUser}
            onChange={this.setFilterUser}
          />
          <SelectField
            id="filter-object-type"
            label="Type"
            placeholder="Select a type"
            className="md-cell md-cell--3"
            menuItems={this.state.types}
            itemLabel={'label'}
            itemValue={'value'}
            value={this.state.filterType}
            onChange={this.setFilterType}
          />
          <DatePicker
            id="filter-start-date"
            label="Start Date"
            className="md-cell md-cell--3"
            autoOk={true}
            value={this.state.filterStartDate}
            onChange={this.setFilterStartDate}
          />
          <DatePicker
            id="filter-end-date"
            label="End Date"
            className="md-cell md-cell--3"
            autoOk={true}
            value={this.state.filterEndDate}
            onChange={this.setFilterEndDate}
          />

          <div className="md-cell md-cell--12">
            <Button
              raised
              primary
              type="submit"
              disabled={appState.isLoading()}
            >{appState.isLoading() ? 'Loading...' : 'Filter'}</Button>

              <Button
                flat
                onClick={this.onPastWeek}
              >Past Week</Button>
              <Button
                flat
                onClick={this.onPastMonth}
              >Past Month</Button>
              <Button
                flat
                onClick={this.onPastYear}
              >Past Year</Button>
            
          </div>
        </form>
      </Paper>
    );
  }

  getTotalActivity(userId: number, data: BaseModel[]) {
    let appState = getAppState();
    if (appState.isLoading()) {
      return (<CircularProgress id={`loading-total-activity-${userId}`} />)
    }

    let total = 0;
    data.forEach((model: BaseModel) => {
      if ((model as any).created_by === userId) total += 1
    })
    return (<div>{total}</div>)
  }

  generateGraphData(userId: number, data: BaseModel[]) {
    let start = moment(this.state.filterStartDate);
    let end = moment(this.state.filterEndDate);
    let days = end.diff(start, 'days');
    let graphData: number[] = [];
    for (let i = 0; i < days; i++) {
      graphData.push(0);
    }

    data.forEach((model: BaseModel) => {
      if ((model as any).created_by !== userId) return;
      if (!(model as any).created_at) return;
      let createdAt = moment((model as any).created_at);
      let index = days - end.diff(createdAt, 'days');
      
      if (!graphData[index]) graphData[index] = 0;
      graphData[index] += 1;
    })
    return graphData;
  }

  renderActivityGraph(userId: number, data: BaseModel[]) {
    let appState = getAppState();
    if (appState.isLoading()) {
      return (<div></div>)
    }
    return (
      <Sparklines data={this.generateGraphData(userId, data)} height={10} >
        <SparklinesLine style={ { stroke: "#2991c8", fill: "none"} } />
      </Sparklines>
    );
  }

  renderUsersActivityGraph() {
    let userIds = this.state.filterUser ? [this.state.filterUser] : this.state.users.map((user) => user.value).filter((val) => val ? true : false);
    return (
      <DataTable plain baseId="TableUserActivity">
        <TableHeader>
          <TableRow>
            <TableColumn key={`header-user-activity-user`}>User</TableColumn>
            <TableColumn key={`header-user-activity-total-activity`}>Total Activity</TableColumn>
            <TableColumn key={`header-user-activity-graph`} grow={true}>Activity Graph</TableColumn>
          </TableRow>
        </TableHeader>
        <TableBody>
          {userIds.map((userId: number, i) => {
            return (
              <TableRow key={i}>
                <TableColumn key={`col-${i}-user`}><ObjectMiniItem objectId={userId} objectName={'users'} style={ {margin: 0} } /></TableColumn>
                <TableColumn key={`col-${i}-total-activity`}>{this.getTotalActivity(userId, this.state.models)}</TableColumn>
                <TableColumn key={`col-${i}-graph`}>{this.renderActivityGraph(userId, this.state.models)}</TableColumn>
              </TableRow>
            )
          })}
        </TableBody>
      </DataTable>
    );
  }

  _tableRef?: TableCollectionView;
  setTableRef = (ref: TableCollectionView) => {
    this._tableRef = ref;
  }

  render() {
    let redirect = this.renderRedirect();
    return (
      <div style={ {paddingTop: '5px'} }>
        {this.renderFilterForm()}
        <Paper zDepth={1} style={ {margin: '20px'} }>
          <TableCollectionView
            id="ActivityReport"
            title="ActivityReport"
            models={this.state.models}
            fields={this.activityFields}
            ref={this.setTableRef}
          />
        </Paper>
        <Paper zDepth={1} style={ {margin: '20px', padding: '10px'} }>
          <ActivityHeatmap
            models={this.state.models}
            startDate={this.state.filterStartDate ? moment(this.state.filterStartDate).format('YYYY-MM-DD'): undefined}
            endDate={this.state.filterEndDate ? moment(this.state.filterEndDate).format('YYYY-MM-DD'): undefined}
          />
        </Paper>
        <Paper zDepth={1} style={ {margin: '20px'} }>
          {this.renderUsersActivityGraph()}
        </Paper>
        {this.renderToasts()}
        {redirect}
      </div>
    );
  }
}

export default ActivityReport;