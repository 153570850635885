import { observable, computed, action, autorun, toJS, IObservableArray } from "mobx";

import { getApi, BaseApi, BaseMockApi } from '../api';
import { getAppState } from './AppState';
import { any } from "prop-types";

import throttle from "lodash.throttle";


abstract class BaseModel {
  id?: number;
  base_url: string = '/crm/apiv1/';
  object_name: string = 'objects';
  object_type: string = 'object';
  object_meta: any = {};
  object_api: BaseApi = new BaseMockApi(); // overridable for testing
  object_fields: any = {};
  form_layout: (string|string[])[] = [];

  mine: boolean = false;
  @observable starred: boolean = false;

  apply_value(key: string, value: any) {
    (this as any)[key] = value;
  }

  apply(source: object) {
    let replica = toJS(this);
    Object.keys(replica).forEach(key => {
      if ((source as any)[key] !== undefined) {
        (this as any)[key] = (source as any)[key];
      }
    });
  }

  isHidden(key: string) {
    let hidden = ['object_api', 'object_fields', 'form_layout', 'id', 'base_url', 'object_name', 'object_type', 'object_meta', 'mine', 'starred'];
    if (hidden.includes(key)) return true
    return false;
  }

  isMeta(key: string) {
    let isMeta = false;
    if (this.object_fields[key] && this.object_fields[key].meta) {
      isMeta = true;
    }

    return isMeta;
  }

  getLabels() {
    let labels = [this.object_type, this.object_name];
    if (this.object_meta && this.object_meta.labels) labels = this.object_meta.labels;
    return labels;
  }

  abstract getTitle(): string;
  abstract getSubtitle(): string;

  get objTitle() {
    return this.getTitle();
  }

  get objSubtitle() {
    return this.getSubtitle();
  }

  getFullTitle() {
    return this.getTitle();
  }

  getSearchKeywords() {
    return this.getTitle();
  }

  getGenericLabel(key: string) {
    let labels = {
      'first_name': 'First Name',
      'last_name': 'Last Name',
    };

    if ((labels as any)[key]) return (labels as any)[key];

    let label = key.replace(/\_/g, ' ')
    return label.charAt(0).toUpperCase() + label.slice(1);
  }

  getField(key: string) {
    return (this as any)[key];
  }

  getFieldInfo(key: string): any {
    let fieldInfo = this.object_fields[key];
    if (!fieldInfo) {
      return {
        type: 'text',
        label: this.getGenericLabel(key),
      }
    }

    if (!fieldInfo.label) {
      fieldInfo.label = this.getGenericLabel(key);
    }

    if ((fieldInfo.type === 'select') && (fieldInfo.options_preset)) {
      switch (fieldInfo.options_preset) {
        case 'us-states':
          fieldInfo.options = [
            {
              "value": "AL",
              "label": "Alabama",
            },
            {
              "value": "AK",
              "label": "Alaska",
            },
            {
              "value": "AZ",
              "label": "Arizona",
            },
            {
              "value": "AR",
              "label": "Arkansas",
            },
            {
              "value": "CA",
              "label": "California",
            },
            {
              "value": "CO",
              "label": "Colorado",
            },
            {
              "value": "CT",
              "label": "Connecticut",
            },
            {
              "value": "DE",
              "label": "Delaware",
            },
            {
              "value": "DC",
              "label": "District Of Columbia",
            },
            {
              "value": "FL",
              "label": "Florida",
            },
            {
              "value": "GA",
              "label": "Georgia",
            },
            {
              "value": "HI",
              "label": "Hawaii",
            },
            {
              "value": "ID",
              "label": "Idaho",
            },
            {
              "value": "IL",
              "label": "Illinois",
            },
            {
              "value": "IN",
              "label": "Indiana",
            },
            {
              "value": "IA",
              "label": "Iowa",
            },
            {
              "value": "KS",
              "label": "Kansas",
            },
            {
              "value": "KY",
              "label": "Kentucky",
            },
            {
              "value": "LA",
              "label": "Louisiana",
            },
            {
              "value": "ME",
              "label": "Maine",
            },
            {
              "value": "MD",
              "label": "Maryland",
            },
            {
              "value": "MA",
              "label": "Massachusetts",
            },
            {
              "value": "MI",
              "label": "Michigan",
            },
            {
              "value": "MN",
              "label": "Minnesota",
            },
            {
              "value": "MS",
              "label": "Mississippi",
            },
            {
              "value": "MO",
              "label": "Missouri",
            },
            {
              "value": "MT",
              "label": "Montana",
            },
            {
              "value": "NE",
              "label": "Nebraska",
            },
            {
              "value": "NV",
              "label": "Nevada",
            },
            {
              "value": "NH",
              "label": "New Hampshire",
            },
            {
              "value": "NJ",
              "label": "New Jersey",
            },
            {
              "value": "NM",
              "label": "New Mexico",
            },
            {
              "value": "NY",
              "label": "New York",
            },
            {
              "value": "NC",
              "label": "North Carolina",
            },
            {
              "value": "ND",
              "label": "North Dakota",
            },
            {
              "value": "OH",
              "label": "Ohio",
            },
            {
              "value": "OK",
              "label": "Oklahoma",
            },
            {
              "value": "OR",
              "label": "Oregon",
            },
            {
              "value": "PA",
              "label": "Pennsylvania",
            },
            {
              "value": "RI",
              "label": "Rhode Island",
            },
            {
              "value": "SC",
              "label": "South Carolina",
            },
            {
              "value": "SD",
              "label": "South Dakota",
            },
            {
              "value": "TN",
              "label": "Tennessee",
            },
            {
              "value": "TX",
              "label": "Texas",
            },
            {
              "value": "UT",
              "label": "Utah",
            },
            {
              "value": "VT",
              "label": "Vermont",
            },
            {
              "value": "VA",
              "label": "Virginia",
            },
            {
              "value": "WA",
              "label": "Washington",
            },
            {
              "value": "WV",
              "label": "West Virginia",
            },
            {
              "value": "WI",
              "label": "Wisconsin",
            },
            {
              "value": "WY",
              "label": "Wyoming",
            },
            {
              "value": "AS",
              "label": "American Samoa",
            },
            {
              "value": "GU",
              "label": "Guam",
            },
            {
              "value": "MP",
              "label": "Northern Mariana Islands",
            },
            {
              "value": "PR",
              "label": "Puerto Rico",
            },
            {
              "value": "UM",
              "label": "United States Minor Outlying Islands",
            },
            {
              "value": "VI",
              "label": "Virgin Islands",
            }
          ];
          break;
      }
    }

    return fieldInfo;
  }

  // override this in subsclass to provide subclass-specific label
  // e.g. 'description' can be overrided as 'Info' in Contact subsclass
  getSpecificLabel(key: string) {
    let labels = {
    };

    if ((labels as any)[key]) return (labels as any)[key];
    return '';
  }

  getLabel(key: string) {
    let label = this.getSpecificLabel(key);
    if (label !== '') return label;

    return this.getGenericLabel(key);
  }

  getValueType(key: string) {
    return 'string';
  }

  getWidgetType(key: string) {
    return 'default';
  }

  getSaveableObject() {
    let replica: any = toJS(this);
    let object: any = {};
    Object.keys(replica).forEach((key, i) => {
      if (!this.isHidden(key)) {
        object[key] = replica[key];
      }
    });
    object.id = this.id;
    return object;
  }

  getApi(): BaseApi {
    if (this.object_api.constructor === BaseMockApi) {
      let api = getApi();
      this.object_api = (api as any)[this.object_name];
    }

    return this.object_api;
  }

  logout() {
    let appState = getAppState();
    appState.isLoggedIn = false;
    appState.wasLoggedIn = true;
  }

  showErrorMessage(message?: string) {
    let appState = getAppState();
    appState.showErrorMessage = true;
    appState.errorMessage = message;
  }

  handleApiError(error: any) {
    let reportError = true;
    if ((error as any).status === 403) {
      this.logout();
    }
    else if ((error as any).status === 500) {
      this.showErrorMessage('A server error has occured during processing of your request. Please try again later. [500]');
    }
    else if ((error as any).status === 400) {
      this.showErrorMessage(`Please check your data validity. [${(error as any).status}: ${(error as any).message}]`);
      reportError = false;
    }
    else if ((error as any).status === 404) {
      this.showErrorMessage(`Unable to find resource you trying to access. [${(error as any).status}: ${(error as any).message}]`);
      reportError = false;
    }
    else if (navigator && navigator.onLine) {
      this.showErrorMessage(`Data access failed. Please try again later. [${(error as any).status}: ${(error as any).message}]`);
    }
    else {
      this.showErrorMessage(`Data access failed. Please check your internet connection and try again later. [${(error as any).status}: ${(error as any).message}]`);
    }

    if (reportError && (window as any).Raven) {
      let Raven = (window as any).Raven;
      Raven.captureException(error);
    }
  }

  async fetch() {
    if (!this.id) return false;
    console.log('fetching object', this.id);

    let objectApi = this.getApi();
    let data: any;

    try {
      data = await objectApi.get(this.id);
    }
    catch (error) {
      console.error('err', error);
      this.handleApiError(error);
      return false;
    }

    this.apply(data);
    return true;
  }

  async save() {
    let objectApi: BaseApi = this.getApi();

    let object = this.getSaveableObject();
    console.log('saving...', object);
    let data: any;

    try {
      data = await objectApi.save(object);
    }
    catch (error) {
      console.error('err', error);
      this.handleApiError(error);
      return false;
    }

    this.apply(data);
    return true;
  }

  async put(object: any) {
    let objectApi: BaseApi = this.getApi();
    console.log('saving...', object);
    let data: any;
    object.id = this.id;

    try {
      data = await objectApi.put(object);
    }
    catch (error) {
      console.error('err', error);
      this.handleApiError(error);
      return false;
    }

    // only apply affected fields
    Object.keys(object).forEach((key, i) => {
      this.apply_value(key, data[key]);
    });

    if (data.updated_at) {
      this.apply_value('updated_at', data.updated_at);
    }

    if (data.modified_by) {
      this.apply_value('modified_by', data.modified_by);
    }

    return true;
  }

  async star(status: boolean) {
    this.starred = status;
    if (this.starred) {
      this.mine = true;
    }
    else {
      this.mine = false;
    }

    let objectApi: BaseApi = this.getApi();

    console.log('deleting...');
    try {
      await objectApi.star(this.id, status);
    }
    catch (error) {
      console.error('err', error);
      this.handleApiError(error);
      return false;
    }
    return true;
  }

  async delete() {
    let objectApi: BaseApi = this.getApi();

    console.log('deleting...');
    try {
      await objectApi.delete(this.id);
    }
    catch (error) {
      console.error('err', error);
      this.handleApiError(error);
      return false;
    }
    return true;
  }

  get url() {
    if (this.id) {
      return `${this.base_url}${this.object_name}/${this.id}/`;
    }
    return `${this.base_url}${this.object_name}/`;
  }

  get viewURL() {
    if (this.id) {
      return `/${this.object_name}/${this.id}/`;
    }
    return `/${this.object_name}/`;
  }

  get editURL() {
    if (this.id) {
      return `/${this.object_name}/${this.id}/edit/`;
    }
    return `/${this.object_name}/`;
  }

  get collectionURL() {
    return `${this.base_url}${this.object_name}/`;
  }

  get collectionViewURL() {
    return `/${this.object_name}/`;
  }

  get icon() {
    return getModelIcon(this.object_type);
  }
}


abstract class BaseModelCollection {
  baseClass: any = Object;
  base_url: string = '/crm/apiv1/';
  object_name: string = 'objects';
  object_type: string = 'object';
  object_meta: any = {};
  // @observable models: IObservableArray<any> = ([] as IObservableArray<any>);
  cached_models: any = {};
  object_api: BaseApi = new BaseMockApi();
  sorted: boolean = true;
  sort_by: string = 'objTitle';
  sort_dir: string = 'ASC';

  getLabels() {
    let labels = [this.object_type, this.object_name];
    if (this.object_meta && this.object_meta.labels) labels = this.object_meta.labels;
    return labels;
  }

  getApi(): BaseApi {
    if (this.object_api.constructor === BaseMockApi) {
      let api = getApi();
      this.object_api = (api as any)[this.object_name];
    }

    return this.object_api;
  }

  async getObject(id: number): Promise<object> {
    let objectApi = this.getApi();
    let item: object = await objectApi.get(id);
    return item;
  }

  async fetchObjects(params?: any): Promise<object[]> {
    let objectApi = this.getApi();
    let items: object[] = await objectApi.fetch(params);
    return items;
  }

  logout() {
    let appState = getAppState();
    appState.isLoggedIn = false;
    appState.wasLoggedIn = true;
  }

  showErrorMessage(message?: string) {
    let appState = getAppState();
    appState.showErrorMessage = true;
    appState.errorMessage = message;
  }

  sortValueTransformFn = (value: any): any => {
    if (typeof value === 'string') return (value as string).toLowerCase();
    return value;
  }

  sortFn = (a: BaseModel, b: BaseModel) => {
    let valA = this.sortValueTransformFn((a as any)[this.sort_by]);
    let valB = this.sortValueTransformFn((b as any)[this.sort_by]);

    if (valA < valB) {
      return this.sort_dir === 'ASC' ? -1 : 1;
    }
    if (valA > valB) {
      return this.sort_dir === 'ASC' ? 1 : -1;
    }
    return 0;
  }

  async query(queryParams?: any, fetchParams?: any): Promise<BaseModel[]> {
    // console.log('query', queryParams, fetchParams);
    let fetchedObjects: object[];

    try {
      fetchedObjects = await this.fetchObjects(fetchParams);
    } catch (error) {
      console.error('err', error);

      if ((error as any).status === 403) {
        this.logout();
        return [];
      }

      if ((error as any).status === 500) {
        this.showErrorMessage('A server error has occured during processing of your request. Please try again later. [500]');
        return [];
      }

      if ((error as any).status === 404) {
        this.showErrorMessage('Unable to find resource you trying to access. [404]');
        return [];
      }
      
      if (navigator && navigator.onLine) {
        this.showErrorMessage('Data access failed. Please try again later.');  
        return [];
      }

      this.showErrorMessage('Data access failed. Please check your internet connection and try again later.');
      return [];
    }

    let models = fetchedObjects.map((data: any) => {
      let model = this.cached_models[data.id];
      if (!model) {
        model = new this.baseClass();
        this.cached_models[data.id] = model;
      }

      model.apply(data);
      return model;
    });

    if (queryParams && queryParams.sort) {
      models = models.sort(queryParams.sort.sortFn);
    }
    return models;
  }

  async baseGet(id: number): Promise<BaseModel> {
    let model = this.cached_models[id];
    if (model) {
      window.setTimeout(async () => {
        let data = await this.getObject(id);
        model.apply(data);
      }, 0);
      return model;
    }
    
    let data = await this.getObject(id);
    model = new this.baseClass();
    model.apply(data);
    this.cached_models[id] = model;
    return model;
  }

  abstract get(id: number): Promise<BaseModel>;

  async remove(model: BaseModel) {
    let id = model.id;
    let result = await model.delete();
    if (result && id) {
      delete this.cached_models[id];
    }
    return result;
  }

  get url() {
    return `${this.base_url}${this.object_name}/`;
  }

  getViewURL(id?: number) {
    if (id) {
      return `/${this.object_name}/${id}/`;
    }
    return `/${this.object_name}/`;
  }

  getEditURL(id: number) {
    return `/${this.object_name}/${id}/edit/`;
  }

  distinctValues: any = {};
  distinctValuesFetching: any = {};

  getDistinctValues(field: string, defaultValues=[]) {
    if (!this.distinctValuesFetching['field']) {
      this.distinctValuesFetching['field'] = true;
      
      this.getDistinctValuesAsyncThrottled(field)
        .then((values) => {
          this.distinctValuesFetching['field'] = false;
          this.distinctValues['field'] = values;
        })
    }

    return this.distinctValues['field'] ? this.distinctValues['field'] : defaultValues;
  }

  async getDistinctValuesAsync(field: string) {
    let objectApi = this.getApi();
    let items: any[] = await objectApi.getDistinctValues(field);
    return items;
  }
  
  getDistinctValuesAsyncThrottled = throttle(this.getDistinctValuesAsync, 10000, {leading: true})
}

class MergedModelCollection extends BaseModelCollection {
  collections: BaseModelCollection[];

  constructor(collections: BaseModelCollection[]) {
    super();

    this.collections = collections;
  }

  async get(id: number): Promise<BaseModel> {
    let promises = this.collections.map((collection) => {
      return collection.get(id);
    });
    return await Promise.race(promises);
  }

  async query(queryParams?: any, fetchParams?: any): Promise<BaseModel[]> {
    let promises = this.collections.map((collection) => {
      return collection.query(queryParams, fetchParams);
    });
    let results = await Promise.all(promises);

    let models: BaseModel[] = [];
    results.forEach(result => {
      result.forEach(model => models.push(model));
    });

    if (queryParams && queryParams.sort) {
      models = models.sort(queryParams.sort.sortFn);
    }
    return models;
  }

}

let modelTypes = [
  {
    type: 'activity',
    name: 'activities',
    label_singular: 'Activity',
    label_plural: 'Activities',
    icon: 'timeline',
  },
  {
    type: 'notification',
    name: 'notifications',
    label_singular: 'Notification',
    label_plural: 'Notifications',
    icon: 'notifications',
  },
  
  {
    type: 'contact',
    name: 'contacts',
    label_singular: 'Contact',
    label_plural: 'Contacts',
    icon: 'account_box',
  },
  
  {
    type: 'client',
    name: 'clients',
    label_singular: 'Client',
    label_plural: 'Clients',
    icon: 'people',
  },
  
  {
    type: 'task',
    name: 'tasks',
    label_singular: 'Task',
    label_plural: 'Tasks',
    icon: 'playlist_add_check',
  },
  
  {
    type: 'note',
    name: 'notes',
    label_singular: 'Note',
    label_plural: 'Notes',
    icon: 'note',
  },
  
  {
    type: 'file',
    name: 'files',
    label_singular: 'File',
    label_plural: 'Files',
    icon: 'attachment',
  },
  
  {
    type: 'reminder',
    name: 'reminders',
    label_singular: 'Reminder',
    label_plural: 'Reminders',
    icon: 'alarm',
  },
  
  {
    type: 'group',
    name: 'groups',
    label_singular: 'Group',
    label_plural: 'Groups',
    icon: 'label',
  },
  
  {
    type: 'user',
    name: 'users',
    label_singular: 'User',
    label_plural: 'Users',
    icon: 'person',
  },
  
];

let getModelIcon = (type: string | undefined) => {
  let icon = 'info';
  
  modelTypes.forEach((modelType) => {
    if (type === modelType.type) icon = modelType.icon;
    if (type === modelType.name) icon = modelType.icon;
  });

  return icon;
}

let getModelLabelSingular = (type: string | undefined) => {
  let label = type;
  
  modelTypes.forEach((modelType) => {
    if (type === modelType.type) label = modelType.label_singular;
    if (type === modelType.name) label = modelType.label_singular;
  });

  return label;
}

let getModelLabelPlural = (type: string | undefined) => {
  let label = type;
  
  modelTypes.forEach((modelType) => {
    if (type === modelType.type) label = modelType.label_plural;
    if (type === modelType.name) label = modelType.label_plural;
  });

  return label;
}

export {
  modelTypes,
  getModelIcon,
  BaseModel,
  BaseModelCollection,
  MergedModelCollection,
  getModelLabelSingular,
  getModelLabelPlural,
}