import React, { Component } from 'react';
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { createViewModel } from 'mobx-utils'

import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardTitle,
  FontIcon,
  Collapse,
  CircularProgress,
  Grid,
  Cell
} from 'react-md';

import {
  BaseModel,
  BaseModelCollection,
  getAppState,
} from '../models';


import ObjectEditor from '../components/ObjectEditor';


interface ObjectEditFormProps {
  isNew: boolean;
  object: BaseModel;
  collection: BaseModelCollection;
  objectLabel: string;
  onSaved: (object: BaseModel) => void;
  onCancel: any;
  onBackButtonClicked: () => void;
  addToast?: (message: string) => void;
  showLoading?: () => void;
  hideLoading?: () => void;
  className?: string;
  setToolbarButton?: boolean;
  helpText?: string;
}


@observer
class ObjectEditForm extends Component<ObjectEditFormProps> {
  state = {
    objectEdit: createViewModel(this.props.object),
    saving: false,
    notFound: false,
    error: false,
    errorFields: {},
  }

  addToast(message: string) {
    if (this.props.addToast) {
      this.props.addToast(message);
    }
  }

  get objectLabel() {
    return this.props.objectLabel;
  }
  
  getObjectTitle(): string {
    return this.props.object.getTitle();
  }

  getObjectSubtitle(): string {
    return this.props.object.getSubtitle();
  }

  showLoading() {
    if (this.props.showLoading) {
      this.props.showLoading()
    }
  }

  hideLoading() {
    if (this.props.hideLoading) {
      this.props.hideLoading()
    }
  }

  async componentDidMount() {

  }


  get toolbarButton(): any {
    return {
      label: 'Save',
      icon: 'check',
      onClick: () => {
        this.save();
      }
    }
  }

  componentWillMount() {
    if (this.props.setToolbarButton) {
      let appState = getAppState();
      appState.toolbarButton = this.toolbarButton;
    }
  }

  onBackButton = () => {
    this.props.onBackButtonClicked();
  }

  validate(obj: BaseModel) {
    let replica = toJS(obj);
    let items: any[] = [];
    Object.keys(replica).forEach(key => {
      if ((obj as any).model.isHidden(key)) return;
      let info = (obj as any).model.getFieldInfo(key);
      let value = (replica as any)[key];
      if (info.type === 'combined') {
        info.subFields.forEach((fieldKey: string) => {
          let fieldValue = (replica as any)[fieldKey];
          if (fieldValue) {
            value = fieldValue;
          }
        });
      }
      items.push({
        key: key,
        type: info.type,
        value: value,
        info: info,
      })
    });

    let validated = true;
    let errorFields: any = {};
    items.forEach((item) => {
      if (item.info.required) {
        if (!item.value) {
          validated = false;
          errorFields[item.key] = true;
        }
      }
    });

    this.setState({
      error: !validated,
      errorFields: errorFields,
    });

    return validated;
  }

  async save() {
    if (this.state.saving) return;

    let objectEdit = this.state.objectEdit;
    let object = objectEdit.model;

    if (!this.validate(objectEdit)) {
      this.addToast('Please check your input!');
      return;
    }

    this.showLoading();

    this.addToast('Saving...');
    this.setState({saving: true});
    objectEdit.submit();
    let saved = false;
    try {
      saved = await object.save();
    }
    catch (e) {
      this.addToast('Error saving data! Please try again.');
      saved = false;
      console.error(e);
    }

    this.setState({saving: false});
    this.hideLoading();

    if (saved) {
      this.props.onSaved(object);
    }
  }

  onChange = (key: string, value: any, event: any) => {
    this.validate(this.state.objectEdit);
  }

  onSaveEditButton = async () => {
    return await this.save();
  }

  onCancelEditButton = () => {
    this.props.onCancel();
  }

  onFormSubmit = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    return await this.save();
  }

  render() {
    let object: BaseModel = this.props.object;
    let className = "ObjectEditForm";
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    let saveLabelNew = object.object_meta.save_labels && object.object_meta.save_labels.new ? object.object_meta.save_labels.new : 'Save';
    let saveLabelEdit = object.object_meta.save_labels && object.object_meta.save_labels.edit ? object.object_meta.save_labels.edit : 'Save';

    return (
      <Card className={className}>
        <CardTitle
          title={this.props.isNew ? `Add new ${this.objectLabel}` : `Editing ${this.objectLabel}`}
          avatar={<Avatar suffix="white" onClick={this.onCancelEditButton}><FontIcon>clear</FontIcon></Avatar>}
        />
        <form
          id={`ObjectEdit-Form-${this.props.object.object_type}-${this.props.object.id}`}
          onSubmit={this.onFormSubmit}
        >
        <ObjectEditor
          object={this.state.objectEdit}
          disabled={this.state.saving}
          error={this.state.error}
          errorFields={this.state.errorFields}
          onChange={this.onChange}
        />

        {this.props.helpText &&
          <div className="helpText">{this.props.helpText}</div>
        }

        <Collapse collapsed={!this.state.saving}>
          <div><CircularProgress id="app-saving-object" /></div>
        </Collapse>

        <CardActions>
          <Grid className="md-cell md-cell--12">
            <Cell size={6} phoneSize={2}>
              <Button raised primary type="submit" className="md-cell--left" iconChildren="check" disabled={this.state.saving}>{this.props.isNew ? saveLabelNew : saveLabelEdit}</Button>
            </Cell>
            <Cell size={6} phoneSize={2} style={{textAlign: 'right'}}>
              <Button flat secondary onClick={this.onCancelEditButton} iconChildren="clear">Cancel</Button>
            </Cell>
          </Grid>
        </CardActions>

        </form>
      </Card>
    );
  }
}

export default ObjectEditForm;