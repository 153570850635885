
import { observer } from "mobx-react";

import {
  Task,
  Tasks,
  AppState,
  getTasks,
  getCollectionByName,
  getAppState,
} from '../models';

import {
  BaseDetailPage,
} from './BaseDetailPage';
import {
  BaseTabbedDetailPage,
} from './BaseTabbedDetailPage';


interface TaskDetailProps {
  history?: any;
  match?: any;
  location?: any;
  task?: Task;
}

type ComponentState = any;



@observer
class TaskDetail extends BaseDetailPage<TaskDetailProps, ComponentState> {

  viewName = 'Task Detail';
  objectName = 'task';

  

  


  

  

  newObject() {
    return new Task();
  }

  getCollection(): Tasks {
    return getTasks();
  }

  getAppState(): AppState {
    return getAppState();
  }

  getRelatedModelCollections() {
    let appState = this.getAppState();
    return [
      
      
      
      
      
        {
          name: 'Notes - Task',
          related_field: 'attached_to_task',
          for_model: 'Note',
          
          collection: getCollectionByName('note')
        },
      
      
        {
          name: 'Files - Task',
          related_field: 'attached_to_task',
          for_model: 'File',
          
          collection: getCollectionByName('file')
        },
      
      
        {
          name: 'Reminders - Task',
          related_field: 'attached_to_task',
          for_model: 'Reminder',
          
          collection: getCollectionByName('reminder')
        },
      
      
      
    ];
  }

}

export default TaskDetail;