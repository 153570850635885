
import { BaseApi, BaseMockApi } from './BaseApi';

class Activities extends BaseApi {
    objectName = 'activities'
}


class MockActivities extends BaseMockApi {
    objectName = 'activities'
    incrementalId = 4;
    mockData = [
    {
      "id": 1,
      "action": "update",
      "target_object_name": "bids",
      "target_object_type": "bid",
      "target_id": 1,
      "creator": 1,
      "created_date": "2018-02-28 12:01:02",
      "modified_by": 2,
      "modified_date": "2018-02-28 12:03:02",
      "mine": true,
    },{
      "id": 2,
      "action": "update",
      "target_object_name": "bids",
      "target_object_type": "bid",
      "target_id": 1,
      "creator": 1,
      "created_date": "2018-02-28 12:01:02",
      "modified_by": 2,
      "modified_date": "2018-02-28 12:03:02",
    },{
      "id": 3,
      "action": "update",
      "target_object_name": "bids",
      "target_object_type": "bid",
      "target_id": 1,
      "creator": 1,
      "created_date": "2018-02-28 12:01:02",
      "modified_by": 2,
      "modified_date": "2018-02-28 12:03:02",
      "mine": true,
    },
  ];

}


export {
  Activities,
  MockActivities,
};
