
import { observer } from "mobx-react";

import {
  Note,
  Notes,
  AppState,
  getNotes,
  getAppState,
} from '../models';

import {
  BaseEditPage,
} from './BaseEditPage';


interface NoteEditProps {
  history?: any;
  match?: any;
  location?: any;
  note?: Note;
}

type ComponentState = any;


@observer
class NoteEdit extends BaseEditPage<NoteEditProps, ComponentState> {
  viewName = 'Note Edit';
  objectName = 'note';

  

  get objectLabel() {
    return "Note";
  }

  newObject() {
    const obj = new Note();

  

    return obj;
  }

  getCollection(): Notes {
    return getNotes();
  }

  getAppState(): AppState {
    return getAppState();
  }

}

export default NoteEdit;